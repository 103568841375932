
import { ADD_TO_CART,REMOVE_ITEM,SUB_QUANTITY,ADD_QUANTITY,RESET_APP,CLEAR_PROMO,SET_PROMO,AUTHENTICATE_THE_USER,SET_DELIVERY,SET_LOCATION} from './action-types/cart-actions'

//add cart action
export const addToCart= (id,sizeId,additionals,sortedExtras)=>{
    return{
        type: ADD_TO_CART,
        id,
        sizeId,
        additionals,
        sortedExtras,
    }
}
//remove item action
export const removeItem=(id,sizeId,additionals,sortedExtras)=>{
    return{
        type: REMOVE_ITEM,
        id,
        sizeId,
        additionals,
        sortedExtras,
    }
}
//subtract qt action
export const subtractQuantity=(id,sizeId,additionals,sortedExtras)=>{
    return{
        type: SUB_QUANTITY,
        id,
        sizeId,
        additionals,
        sortedExtras,
    }
}
//add qt action
export const addQuantity=(id,sizeId,additionals,sortedExtras)=>{
    return{
        type: ADD_QUANTITY,
        id,
        sizeId,
        additionals,
        sortedExtras,

    }
}
//reset basket

export const RESETAPP=()=>{
    return{
        type: RESET_APP,
    }
}
// clear promo
export const CLEARPROMO=()=>{
    return{
        type: CLEAR_PROMO,
    }
}
// set promo
export const SETPROMO=(dicPrice,code)=>{
    return{
        type: SET_PROMO,
        dicPrice,
        code
    }
}
// set auth
export const authounticateUser=()=>{
    return{
        type: AUTHENTICATE_THE_USER,

    }
}
// set DELIVERY
export const deliveryPrice=(ordinary,fast,delivery)=>{
    return{
        type: SET_DELIVERY,
        ordinary,
        fast,
        delivery

    }
}

// set LOCATION
export const setLocation=(lat,lng)=>{
    return{
        type: SET_LOCATION,
        lat,
        lng,



    }
}



