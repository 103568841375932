import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux'
import {removeItem, addQuantity, subtractQuantity, CLEARPROMO, SETPROMO} from './actions/cartActions'
import Translate from "react-translate-component";
import deleteIcon from "./component/assest/img/deleteIcon.png";
import pluseImg from "./component/assest/img/pluse.png";
import minasImg from "./component/assest/img/minas.png";
import basket from "./component/assest/img/basketDefult.png";
import axios from "axios";
import './component/assest/css/ar.css'
import {Redirect} from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Modal, ModalBody} from "reactstrap";
import {BallBeat} from "react-pure-loaders";

let translate = require('counterpart');

class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            fastDelivery: 0,
            ordinaryDelivery: 0,
            deliveryPrice: 0,
            promo: '',
            notes: '',
            selectedOptiontime: 'true',
            redirect: false,
            datePickerIsOpen: false,
            startDate: new Date(),
            now: '',
            date: '',
            time: '',
            disabled_butt: true,
            descountPrice: 0,
            minTime: new Date().setHours(new Date().getHours() + 2),
            orderDetails: false,
            arName: "",
            enName: "",
            ProQuantity: "",
            ProPrice: "",
            ProSizeAr: "",
            ProSizeEn: "",
            calories: "",
            addArName: "",
            addEnName: "",
            extrasArName: [],
            extrasEnName: [],
            promoLoading:false,
            wrongPromo:false,
            addFastCurrency:false,
            addOrdCurrency:false,


        };

    }

    async componentDidMount() {
        await this.showDeliveryType();
        localStorage.setItem("now", "true");
        localStorage.setItem("date", "");
        localStorage.setItem("time", "");

    }

    detailsModel = () => {
        this.setState(prevState => ({
            orderDetails: !prevState.orderDetails,

        }));


    };
    showDetails = (arName, enName, id, sizeId, quantity, price, sizeAr, sizeEn, calories, addArName, addEnName, extrasArName, extrasEnName) => {
        this.setState({
            arName: arName,
            enName: enName,
            ProQuantity: quantity,
            ProPrice: price,
            ProSizeAr: sizeAr,
            ProSizeEn: sizeEn,
            calories: calories,
            addArName: addArName,
            addEnName: addEnName,
            extrasArName: extrasArName,
            extrasEnName: extrasEnName,
        })


    };

    showDeliveryType = () => {
        if (parseInt(localStorage.getItem("typeId")) === 2) {

            document.getElementById("dType").style.display = "block";

            let params = {
                latitude: localStorage.getItem("addressLat"),
                longitude: localStorage.getItem("addressLong"),
                branchId: parseInt(localStorage.getItem("branchId"))
            };
            let headers = {
                'Accept': 'application/json'
            };
            axios.get(window.$urlName + 'infos/delivery-price', {
                params,
                headers
            }).then((response) => {
                if(response.data.data.fastDelivery === null ){
                    this.setState({
                        fastDelivery: localStorage.getItem("lang") === 'en' ? "Not available" :"غير متاح" ,
                        ordinaryDelivery: (response.data.data.ordinaryDelivery).toFixed(2),
                        deliveryPrice: parseFloat(response.data.data.ordinaryDelivery),
                        addFastCurrency:false,
                        addOrdCurrency:true
                    })
                    document.getElementById("fast").style.border = "1px solid";
                    document.getElementById("ordinary").style.border = "2px solid";
                    document.getElementById("fast").style.pointerEvents = "none";
                    localStorage.setItem("deliveryType", "ordinary");
                    localStorage.setItem("deliveryPrice", JSON.stringify(response.data.data.ordinaryDelivery));
                }else if (response.data.data.ordinaryDelivery === null){
                    this.setState({
                        fastDelivery: response.data.data.fastDelivery,
                        ordinaryDelivery: localStorage.getItem("lang") === 'en' ? "Not available" :"غير متاح" ,
                        deliveryPrice: parseFloat(response.data.data.fastDelivery),
                        addFastCurrency:true,
                        addOrdCurrency:false
                    })
                    document.getElementById("ordinary").style.border = "1px solid";
                    document.getElementById("fast").style.border = "2px solid";
                    document.getElementById("ordinary").style.pointerEvents = "none";
                    localStorage.setItem("deliveryType", "fast");
                    localStorage.setItem("deliveryPrice", JSON.stringify(response.data.data.fastDelivery));
                }
                else {

                    if (localStorage.getItem("deliveryPrice")) {
                        if (localStorage.getItem("deliveryType") === "ordinary") {
                            document.getElementById("fast").style.border = "1px solid";
                            document.getElementById("ordinary").style.border = "2px solid";
                            localStorage.setItem("deliveryType", "ordinary");
                            localStorage.setItem("deliveryPrice", JSON.stringify(response.data.data.ordinaryDelivery));


                        } else {
                            document.getElementById("fast").style.border = "2px solid";
                            document.getElementById("ordinary").style.border = "1px solid";
                            localStorage.setItem("deliveryType", "fast");
                            localStorage.setItem("deliveryPrice", JSON.stringify(response.data.data.fastDelivery));


                        }

                        this.setState({
                            fastDelivery: response.data.data.fastDelivery,
                            ordinaryDelivery: response.data.data.ordinaryDelivery,
                            deliveryPrice: parseFloat(JSON.parse(localStorage.getItem("deliveryPrice"))),
                            addFastCurrency:true,
                            addOrdCurrency:true
                        })

                    } else {
                        document.getElementById("fast").style.border = "1px solid";
                        document.getElementById("ordinary").style.border = "2px solid";
                        this.setState({
                            fastDelivery: response.data.data.fastDelivery,
                            ordinaryDelivery: response.data.data.ordinaryDelivery,
                            deliveryPrice: parseFloat(response.data.data.ordinaryDelivery),
                            addFastCurrency:true,
                            addOrdCurrency:true
                        })
                        localStorage.setItem("deliveryType", "ordinary");
                        localStorage.setItem("deliveryPrice", JSON.stringify(response.data.data.ordinaryDelivery));

                    }

                }







            });
        } else {
            localStorage.setItem("deliveryType", "ordinary");

        }

    };

    handelFastDeliver = () => {
        this.props.CLEARPROMO();
        this.setState({
            deliveryPrice: parseInt(this.state.fastDelivery),
            promo: '',


        });
        localStorage.setItem("deliveryType", "fast");
        localStorage.setItem("deliveryPrice", JSON.stringify(this.state.fastDelivery));
        document.getElementById("fast").style.border = "2px solid";
        document.getElementById("ordinary").style.border = "1px solid";
        this.props.CLEARPROMO();

    };
    handelOrdinaryDeliver = () => {
        this.props.CLEARPROMO();
        this.setState({
            deliveryPrice: parseInt(this.state.ordinaryDelivery),
            promo: '',

        });
        localStorage.setItem("deliveryType", "ordinary");
        localStorage.setItem("deliveryPrice", JSON.stringify(this.state.ordinaryDelivery));
        document.getElementById("fast").style.border = "1px solid";
        document.getElementById("ordinary").style.border = "2px solid";


    };
    handelChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });

    };
    checkPromo = () => {
        this.setState({
            promoLoading:true,
            wrongPromo:false

        })
        let params = {
            code: this.state.promo,
            price: parseFloat(localStorage.getItem("total")),
            branchId: parseInt(localStorage.getItem("branchId"))
        };
        let headers = {
            'Authorization': 'Bearer ' + this.state.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
        axios.get(window.$urlName + 'promoCodes/check-code-valid', {
            params,
            headers
        }).then((response) => {
            if (response.data.data.valid === true) {
                this.setState({
                    descountPrice: response.data.data.price,
                    promoLoading:false,
                    wrongPromo:false

                });

                this.props.SETPROMO(response.data.data.price, this.state.promo);
            } else {
                this.setState({
                    promoLoading:false,
                    wrongPromo:true
                })

            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                this.setState({
                    promoLoading:false
                })
                // localStorage.getItem("lang") === 'ar' ? alert("من فضلك سجل دخول اولا") : alert('Please login first');


                window.location.pathname = "/sign-in";
            } else {
                this.setState({
                    promoLoading:false,
                    wrongPromo:true
                })

            }
        }.bind(this));

    };
    handelOrderInfo = () => {
        this.setState({
            redirect: true
        });
        let arr = JSON.parse(localStorage.getItem("reducer") || "[]");
        localStorage.setItem("finalProductArr", JSON.stringify(arr));
        if(this.state.notes === ''){
            localStorage.setItem("notes", '');

        }else {
            localStorage.setItem("notes", this.state.notes);
        }
    };
    handleRemove = (id, sizeId, additionals, sortedExtras) => {
        this.props.removeItem(id, sizeId, additionals, sortedExtras);
    };
    handleAddQuantity = (id, sizeId, additionals, sortedExtras) => {
        this.props.addQuantity(id, sizeId, additionals, sortedExtras);
    };
    handleSubtractQuantity = (id, sizeId, additionals, sortedExtras) => {
        this.props.subtractQuantity(id, sizeId, additionals, sortedExtras);
    };
    clearPromo = () => {
        this.props.CLEARPROMO();
        window.location.reload()

    };


    handleOptionChangeTime = (e) => {
        this.setState({
            selectedOptiontime: e.target.value,
        });
        if (e.target.value === "false") {
            this.setState({
                datePickerIsOpen: !this.state.datePickerIsOpen,
            });
            let d = this.state.startDate.getDate();
            let m = this.state.startDate.getMonth() + 1;
            let y = this.state.startDate.getFullYear();
            let selectedDate = y + '-' + m + '-' + d;
            localStorage.setItem("now", "false");
            localStorage.setItem("date", selectedDate);
            localStorage.setItem("time", this.state.startDate.toLocaleTimeString());

        } else {
            localStorage.setItem("now", "true");
            localStorage.setItem("date", "");
            localStorage.setItem("time", "");
        }
    };


    handleChangeTime = (date) => {
        if (date.toLocaleDateString() === new Date().toLocaleDateString()) {
            this.setState({
                startDate: date,
                minTime: new Date().setHours(new Date().getHours() + 2)
            });

        } else {
            this.setState({
                startDate: date,
                minTime: new Date(new Date().setHours(10, 30)),

            });
        }
        let d = date.getDate();
        let m = date.getMonth() + 1;
        let y = date.getFullYear();
        let selectedDate = y + '-' + m + '-' + d;
        localStorage.setItem("date", selectedDate);
        localStorage.setItem("time", date.toLocaleTimeString());
    };
    openDatePicker = () => {
        this.setState({
            datePickerIsOpen: !this.state.datePickerIsOpen,
        });
    };


    render() {

        const {redirect} = this.state;

        if (redirect) {
            if(localStorage.getItem('carOption') ===  'true'){
                return <Redirect to={{
                    pathname: '/car-details',
                }}
                />
            }else{
                return <Redirect to={{
                    pathname: '/payment',
                }}
                />
            }
        }
        // const {deliveryPrice,ordinaryDelivery,fastDelivery } = this.props;


        let addedItems = JSON.parse(localStorage.getItem("reducer") || "[]").length ?
            (
                JSON.parse(localStorage.getItem("reducer") || "[]").map((item, index) => {
                    return (
                        <li className="whiteBasketRow" key={index}>
                            <div className="row">
                                <div className="col-lg-7 col-md-12">
                                    <button className="removeButton" onClick={() => {
                                        this.handleRemove(item.id, item.sizeId, item.additionals, item.sortedExtras)
                                    }}><img src={deleteIcon}
                                            alt="icon"/>
                                    </button>
                                    {localStorage.getItem("lang") === 'ar' ? <p>{(item.arName).substring(0,12) + " .."}</p> :
                                        <p>{item.enName.substring(0,12) + " .."}</p>}
                                </div>

                                <div className="col-lg-5 col-md-12">
                                    <div className="quantitySec sideQ">
                                        <div className="add-remove">
                                            <button type="button" onClick={() => {
                                                this.handleAddQuantity(item.id, item.sizeId, item.additionals, item.sortedExtras)
                                            }} className="plus"><img
                                                src={pluseImg} alt="plus"/>
                                            </button>
                                            <b>{item.quantity}</b>

                                            <button type="button" onClick={() => {
                                                this.handleSubtractQuantity(item.id, item.sizeId, item.additionals, item.sortedExtras)
                                            }} className="min"><img
                                                src={minasImg} alt="min"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row priceItemRow">
                                <div className="col-lg-6 col-md-6">
                                    <Translate content="price" className="itemPrice"/><span
                                    className="itemPriceNum">{item.price}</span>

                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <Translate content="total" className="itemPrice"/><span
                                    className="itemPriceNum">{item.itemTotalPrice}</span>

                                </div>
                            </div>
                            <div className="details">
                                <i className="fa fa-info"/>
                                <Translate content="details" onClick={() => {
                                    this.showDetails(item.arName, item.enName, item.id, item.sizeId, item.quantity, item.itemTotalPrice, item.sizeAr, item.sizeEn, item.calories, item.addArName, item.addEnName, item.extrasArName, item.extrasEnName);
                                    this.detailsModel();
                                }}/>
                            </div>

                        </li>

                    )

                })
            ) :

            (
                <p className="nonOrders">
                    <img src={basket} alt="basket"/>
                    <Translate content="nothing"/>
                </p>
            );
        return (
            <Fragment>
                <div className="cart">

                    <h5><Translate content="you_have_ordered"/></h5>

                    <ul className="collection">

                        {addedItems}
                    </ul>
                    <div className="notesInput">
                        <Translate component="textarea" id="notes" className="form-control" value={this.state.notes}
                                   onChange={this.handelChange} attributes={{placeholder: 'enter_your_notes'}}/>

                    </div>

                    <Translate component="p" content="delivery_choice" className="deliverChoice"/>

                    <div className="timeDelivery">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="radioTime">
                                    <input type="radio" value="true" id="now" name="time"
                                           checked={this.state.selectedOptiontime === "true"}
                                           onChange={(e) => this.handleOptionChangeTime(e)}/>
                                    <label htmlFor="now"> <Translate content="now"/> </label>


                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="radioTime">
                                    <input type="radio" value="false" id="later" name="time"
                                           checked={this.state.selectedOptiontime === "false"}
                                           onChange={(e) => this.handleOptionChangeTime(e)}/>
                                    <label htmlFor="later"> <Translate content="later"/> </label>

                                </div>
                                <DatePicker
                                    dateFormat=" h:mm ,yyyy/MM/dd"
                                    selected={this.state.startDate}
                                    onChange={this.handleChangeTime}
                                    onClickOutside={this.openDatePicker}
                                    open={this.state.datePickerIsOpen}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    minTime={this.state.minTime}
                                    maxTime={new Date(new Date().setHours(23, 59))}
                                    timeIntervals={15}
                                    timeCaption="time"
                                    minDate={new Date()}

                                    showDisabledMonthNavigation
                                />
                            </div>
                        </div>
                    </div>


                    <div className="deliverType" id="dType" style={{display: "none"}}>
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <button type="button" onClick={this.handelFastDeliver} id="fast"><Translate
                                    content="fastDelivery"/>
                                    <p>{this.state.fastDelivery} {this.state.addFastCurrency ? localStorage.getItem("lang") === 'en' ?
                                        <span>{localStorage.getItem("enCurrency")}</span> :
                                        <span>{localStorage.getItem("arCurrency")}</span> :null}</p></button>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <button type="button" onClick={this.handelOrdinaryDeliver} id="ordinary"
                                ><Translate
                                    content="ordinary_Delivery"/><p>{this.state.ordinaryDelivery} {this.state.addOrdCurrency ? localStorage.getItem("lang") === 'en' ?
                                    <span>{localStorage.getItem("enCurrency")}</span> :
                                    <span>{localStorage.getItem("arCurrency")}</span> :null}</p></button>
                            </div>
                        </div>
                    </div>

                    <div className="promoInput">
                        <Translate component="input" id="promo" value={this.state.promo}
                                   onChange={this.handelChange} attributes={{placeholder: 'enter_promo'}}/>

                        <button type="button" onClick={this.checkPromo}
                                disabled={!JSON.parse(localStorage.getItem("reducer") || "[]").length || !this.state.promo}><Translate
                            content="confirm"/></button>

                    </div>
                    <div className="loadingSec">

                        <BallBeat
                            color={'#2C2C2C'}
                            loading={this.state.promoLoading}
                        />
                    </div>
                    {/*{(localStorage.getItem("code")) ?*/}
                        {/*<p className="deletePromoButt"><i onClick={this.clearPromo} className="fa fa-close"/>{this.props.code}*/}
                        {/*</p> : null}*/}
                    {(localStorage.getItem("code")) ?
                        localStorage.getItem("lang") === 'ar' ? <p className="rightPromo">بالعافية عليك كود خصم صحيح</p> :
                            <p className="rightPromo">Bon Apetit, code is valid</p> : null}
                    {this.state.wrongPromo ? translate.getLocale() === 'ar' ? <p className="wrongPromo">الكود غير صحيح</p> :
                        <p className="wrongPromo">wrong code</p> : null}

                    <p className="collection-item" style={{background: "#555555"}}>
                        <Translate content="total"/>: <b
                        id="old">{localStorage.getItem("total") ? (parseFloat(localStorage.getItem("total")) + this.state.deliveryPrice).toFixed(1) : 0} {localStorage.getItem("lang") === 'en' ?
                        <span>{localStorage.getItem("enCurrency")}</span> :
                        <span>{localStorage.getItem("arCurrency")}</span>}</b>
                    </p>
                    <p className="collection-item" style={{background: "#aa2b31"}}>
                        <Translate
                            content="disc_mount"/>: <b>{(localStorage.getItem("descountPrice")) ? (parseFloat(localStorage.getItem("total"))  - parseFloat(localStorage.getItem("descountPrice"))).toFixed(1) : 0} {localStorage.getItem("lang") === 'en' ?
                        <span>{localStorage.getItem("enCurrency")}</span> :
                        <span>{localStorage.getItem("arCurrency")}</span>}</b>
                    </p>
                    <p className="collection-item" style={{background: "#aa2b31"}}>
                        <Translate
                            content="total_price"/>: <b>{(localStorage.getItem("descountPrice")) ? (parseFloat(localStorage.getItem("descountPrice")) + this.state.deliveryPrice).toFixed(1) : (parseFloat(localStorage.getItem("total")) + this.state.deliveryPrice).toFixed(1) } {localStorage.getItem("lang") === 'en' ?
                        <span>{localStorage.getItem("enCurrency")}</span> :
                        <span>{localStorage.getItem("arCurrency")}</span>}</b>
                    </p>


                    <div className="checkout" style={{marginTop: "10px"}}>
                        <button className="orangeButton" onClick={this.handelOrderInfo}
                                disabled={!JSON.parse(localStorage.getItem("reducer") || "[]").length}>
                            <Translate content="continue"/></button>
                    </div>
                </div>
                <Modal isOpen={this.state.orderDetails} toggle={this.detailsModel} className="detailsBody">
                    <ModalBody>
                        <div className="orderDetails">
                            {translate.getLocale() === 'ar' ? <h2>{this.state.arName}</h2> :
                                <h2>{this.state.enName}</h2>}
                            <table className="table table-sm">
                                <tbody>
                                <tr>
                                    <th scope="row"><Translate content="quantity"/></th>
                                    <td>{this.state.ProQuantity}</td>

                                </tr>
                                <tr>
                                    <th scope="row"><Translate content="price"/></th>
                                    <td>{this.state.ProPrice / this.state.ProQuantity} {localStorage.getItem("lang") === 'en' ?
                                        <span>{localStorage.getItem("enCurrency")}</span> :
                                        <span>{localStorage.getItem("arCurrency")}</span>}</td>

                                </tr>
                                <tr>
                                    <th scope="row"><Translate content="size"/></th>
                                    {translate.getLocale() === 'ar' ? <td>{this.state.ProSizeAr}</td> :
                                        <td>{this.state.ProSizeEn}</td>}

                                </tr>
                                <tr>
                                    <th scope="row"><Translate content="calorie"/></th>
                                    <td>{this.state.calories}</td>
                                </tr>
                                <tr>
                                    <th scope="row"><Translate content="additions"/></th>
                                    {translate.getLocale() === 'ar' ? <td>{this.state.addArName}</td> :
                                        <td>{this.state.addEnName}</td>}
                                </tr>
                                <tr>
                                    <th scope="row"><Translate content="extras"/></th>
                                    {translate.getLocale() === 'ar' ?
                                        <td>{this.state.extrasArName.length !== 0 ? this.state.extrasArName : "-"}</td> :
                                        <td>{this.state.extrasEnName.length !== 0 ? this.state.extrasEnName : "-"}</td>}
                                </tr>
                                <tr>
                                    <th scope="row"><Translate content="total_price"/></th>
                                    <td>{this.state.ProPrice} {localStorage.getItem("lang") === 'en' ?
                                        <span>{localStorage.getItem("enCurrency")}</span> :
                                        <span>{localStorage.getItem("arCurrency")}</span>}</td>

                                </tr>
                                </tbody>
                            </table>

                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>


        )

    }


}


const mapStateToProps = (state) => {
    return {
        items: state.addedItems,
        total: state.total,
        code: state.code
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        removeItem: (id, sizeId, additionals, sortedExtras) => {
            dispatch(removeItem(id, sizeId, additionals, sortedExtras))
        },
        addQuantity: (id, sizeId, additionals, sortedExtras) => {
            dispatch(addQuantity(id, sizeId, additionals, sortedExtras))
        },
        CLEARPROMO: () => {
            dispatch(CLEARPROMO())
        },
        SETPROMO: (dicPrice, code) => {
            dispatch(SETPROMO(dicPrice, code))
        },
        subtractQuantity: (id, sizeId, additionals, sortedExtras) => {
            dispatch(subtractQuantity(id, sizeId, additionals, sortedExtras))
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Cart)
