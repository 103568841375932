import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import {Redirect} from 'react-router-dom';
import {BallBeat} from "react-pure-loaders";
import {Modal, ModalBody} from "reactstrap";
let translate = require('counterpart');

class carDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            loading: false,
            redirect: false,
            carModel: (localStorage.getItem('carModel')? localStorage.getItem('carModel') :""),
            carColor: (localStorage.getItem('carColor')? localStorage.getItem('carColor') :""),
            carNumber: (localStorage.getItem('carNumber')? localStorage.getItem('carNumber') :""),
            carNotes: (localStorage.getItem('carNotes')? localStorage.getItem('carNotes') :""),
            msgAr:"",
            msgEn:"",
            errorMsgModel: false,
        };
    }

    errorTypeModel = () => {
        this.setState(prevState => ({
            errorMsgModel: !prevState.errorMsgModel,
        }));
    };

    handelChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    handelCharge = (e) => {
        e.preventDefault();

        if(this.state.carModel === '' || this.state.carColor === '' || this.state.carNumber === ''){
            this.setState({
                loading: false,
                msgAr:'برجاء ملئ كافة تفاصيل السيارة',
                msgEn:"Please fill all car details",
                errorMsgModel:true
            });
        }else{
            localStorage.setItem('carModel',this.state.carModel);
            localStorage.setItem('carColor',this.state.carColor);
            localStorage.setItem('carNumber',this.state.carNumber);
            localStorage.setItem('carNotes',this.state.carNotes);
            this.setState({
                redirect:true
            });
        }
    };

    render() {
        const {redirect} = this.state;
        if (redirect) {
            return <Redirect to={{
                pathname: '/payment',
            }}
            />
        }
        return (
            <Fragment>
                <div className="container">
                    <form onSubmit={this.handelCharge}>
                        <div className="signForm chargeForm carForm">
                            <div id="confirmCode">
                                <div className="form-group">
                                    <label htmlFor="balance"><Translate content="carDetails"/></label>
                                    <div className="loadingSec">
                                        <BallBeat
                                            color={'#2C2C2C'}
                                            loading={this.state.loading}
                                        />
                                    </div>

                                    <Translate component="input" type="text" id="carModel" name="carModel"
                                               className="form-control" value={this.state.carModel}
                                               onChange={this.handelChange} attributes={{placeholder: 'carModel'}}
                                               required/>

                                    <Translate component="input" type="text" id="carColor" name="carColor"
                                               className="form-control" value={this.state.carColor}
                                               onChange={this.handelChange} attributes={{placeholder: 'carColor'}}
                                               required/>

                                    <Translate component="input" type="text" id="carNumber" name="carNumber"
                                               className="form-control" value={this.state.carNumber}
                                               onChange={this.handelChange} attributes={{placeholder: 'carNumber'}}
                                               required/>

                                    <Translate component="textarea" id="carNotes" name="carNotes"
                                               className="form-control" value={this.state.carNotes}
                                               onChange={this.handelChange} attributes={{placeholder: 'carNotes'}}
                                    />

                                </div>
                                <button type="submit" id="sign-in-button" className="orangeButton">
                                    <Translate content="confirm"/></button>
                            </div>
                        </div>

                    </form>

                </div>

                <Modal isOpen={this.state.errorMsgModel} toggle={this.errorTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="sorry" component="p"/>

                            {translate.getLocale() === 'ar' ? <p> {this.state.msgAr}</p> :
                                <p>{this.state.msgEn}</p>}
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}


export default carDetails



