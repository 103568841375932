import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import {Link} from "react-router-dom";
import success from "./component/assest/img/success.svg";
import failed from "./component/assest/img/faild.svg";
import axios from "axios";
import {BallBeat} from "react-pure-loaders";
import {RESETAPP} from "./actions/cartActions";
import connect from "react-redux/es/connect/connect";

// const queryString = require('query-string');
// const parsed = queryString.parse(window.location.search);


class paymentSuccess extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            walletOption: false,
            orderOption: false
        }
    }

    async componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let tapId = params.get('tap_id');

        if(tapId !== ""){
            await axios.get(window.$urlName + 'orders/tap/payment-status/'+tapId ,{
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }
            ).then((response) => {
                document.getElementById("paySuccessBody").style.display = "block";
                this.setState({
                    loading: false
                })
                this.props.RESETAPP()
        
            }).catch(function (error) {
                document.getElementById("payNotSuccessBody").style.display = "block";
                this.setState({
                    loading: false
                })
                // console.log(error.response)
            });
        } else {
            document.getElementById("payNotSuccessBody").style.display = "block";
            this.setState({
                loading: false
            })
        }


        // const ResponseCode = params.get('ResponseCode');
        // if (ResponseCode === "000") {
        //     let values = {
        //         transactionId: params.get('TranId')
        //     };
        //     if(orderId.includes("wallet")){
        //         this.setState({
        //             walletOption: true
        //         })
        //         await axios.put(window.$urlName + 'wallets/accept-payment', values, {
        //                 headers: {
        //                     'Authorization': 'Bearer ' + localStorage.getItem("token"),
        //                     'Accept': 'application/json',
        //                     'Content-Type': 'application/json',
        //                 }
        //             }
        //         ).then((response) => {
        //             document.getElementById("paySuccessBody").style.display = "block";
        //             this.setState({
        //                 loading: false
        //             })
        //             this.props.RESETAPP()

        //         }).catch(function (error) {
        //             // console.log(error.response)
        //         });
        //     }else{
        //         this.setState({
        //             orderOption: true
        //         })
        //         orderId = parseInt(params.get('TrackId'));
        //         await axios.put(window.$urlName + 'orders/' + orderId + '/make-accepted', values, {
        //                 headers: {
        //                     'Authorization': 'Bearer ' + localStorage.getItem("token"),
        //                     'Accept': 'application/json',
        //                     'Content-Type': 'application/json',
        //                 }
        //             }
        //         ).then((response) => {
        //             document.getElementById("paySuccessBody").style.display = "block";
        //             this.setState({
        //                 loading: false
        //             })
        //             this.props.RESETAPP()

        //         }).catch(function (error) {
        //             // console.log(error.response)
        //         });
        //     }
        // } else {
        //     document.getElementById("payNotSuccessBody").style.display = "block";
        //     this.setState({
        //         loading: false
        //     })
        // }
    }

    payAgain = (orderId, tranId) => {
        let values = {
            transactionId: tranId
        };
        axios.get(window.$urlName + 'orders/' + orderId + '/payment-url', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }, body: {values}
            }
        ).then((response) => {
            window.location.href = response.data.data.url
            this.props.RESETAPP()


        }).catch(function (error) {
            // console.log(error.response)

        });
    }

    render() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const orderId = parseInt(params.get('TrackId'));
        const TranId = params.get('TranId');
        return (
            <Fragment>
                <div style={{height: "400px"}}>
                    <div className="parent">
                        <div className="child">
                            <div className="loadingSec">

                                <BallBeat
                                    color={'white'}
                                    loading={this.state.loading}
                                />
                            </div>
                            <div className="paySuccessBody" id="paySuccessBody" style={{display: "none"}}>
                                <img src={success} alt="success"/>
                                <Translate content="success_payment" component="h2"/>
                                {this.state.orderOption ?
                                    <Link to={{pathname: `/order/${orderId}`, state: {TranId: TranId}}}>
                                        <Translate content="track_order" component="p"/>
                                    </Link>
                                    :""}
                            </div>
                            <div className="payNotSuccessBody" id="payNotSuccessBody" style={{display: "none"}}>
                                <img src={failed} alt="failed"/>
                                <Translate content="notSuccess_payment" component="h2"/>
                                {this.state.orderOption ?
                                <span onClick={() => this.payAgain(orderId, TranId)}><Translate
                                    content="pay_again"/></span>
                                    :""}
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}


const mapDispatchToProps = (dispatch) => {

    return {
        RESETAPP: () => {
            dispatch(RESETAPP())
        }

    }
};
export default connect(null, mapDispatchToProps)(paymentSuccess)
