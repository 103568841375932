import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";

class pageError extends Component{
    render(){
        return(
            <Fragment>
                <div className="pageError">
                    <Translate content="page_not_found" component="h1"/>
                </div>
            </Fragment>
        )
    }

}

export default pageError