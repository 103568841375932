import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {Redirect} from 'react-router-dom';
import {BallBeat} from "react-pure-loaders";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {authounticateUser} from "./actions/cartActions";
import connect from "react-redux/es/connect/connect";
import eye from "./component/assest/img/eye.svg";

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        let loggedIn = false;
        this.state = {
            token: localStorage.getItem("token"),
            loading: false,
            password: '',
            hidden: true,

            loggedIn
        };

    }
 
    handelChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };


    toggleShow = () => {
        this.setState({hidden: !this.state.hidden});
    }


    render() {
        if (this.state.loggedIn) {
            return <Redirect to={{
                pathname: '/',
                state: {token: this.state.token}
            }}
            />
        }

        return (
            <Fragment>
                <div className="container">
                    <div className="signForm">
                        <Formik
                            initialValues={{
                                password: '',
                                confirmPassword: ''
                            }}
                            validationSchema={Yup.object().shape({
                                password: Yup.string()
                                    .min(6, <Translate content="at_least"/>)
                                    .required(<Translate content="password_required"/>),
                                confirmPassword: Yup.string()
                                    .oneOf([Yup.ref('password'), null], <Translate content="password_must_match"/>)
                                    .required(<Translate content="confirm_required"/>)

                            })}
                            onSubmit={values => {
                                this.setState({
                                    loading: true,
                                    password: values.password,

                                });
                                axios.put(window.$urlName + 'customers', values, {
                                    headers: {
                                        'Authorization': 'Bearer ' + this.state.token,
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json'
                                    }
                                }).then((response) => {
                                    axios.get(window.$urlName + 'customers/account-info', {
                                        headers: {
                                            'Authorization': 'Bearer ' + this.state.token,
                                            'Accept': 'application/json'
                                        }
                                    }).then((response) => {
                                        localStorage.setItem("userName",response.data.data.name)
                                        this.props.authounticateUser();
                                        this.setState({
                                            loggedIn: true,
                                            loading: false
                                        })

                                    })

                                }).catch(function (error) {
                                  console.log(error.response)
                                });





                            }}

                            render={({errors, status, touched}) => (
                                <Form>
                                        <Translate content="change_password" component="h1"/>
                                        <div className="loadingSec">

                                            <BallBeat
                                                color={'#2C2C2C'}
                                                loading={this.state.loading}
                                            />
                                        </div>
                                    <div className="form-group">
                                        <label htmlFor="password"><Translate content="password"/></label>
                                        <div style={{position: "relative"}}>
                                            <Field name="password" type={this.state.hidden ? "password" : "text"}
                                                   className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}/>
                                            <span className="showButt" onClick={this.toggleShow}><img src={eye}
                                                                                                      alt="eye"/></span>
                                            <ErrorMessage name="password" component="div" className="invalid-feedback"/>

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="confirmPassword"><Translate content="confirm_password"/></label>
                                        <div style={{position: "relative"}}>
                                            <Field name="confirmPassword" type={this.state.hidden ? "password" : "text"}
                                                   className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')}/>
                                            <span className="showButt" onClick={this.toggleShow}><img src={eye}
                                                                                                      alt="eye"/></span>
                                            <ErrorMessage name="confirmPassword" component="div"
                                                          className="invalid-feedback"/>

                                        </div>


                                    </div>
                                        
                                        <button type="submit" id="sign-in-button">
                                            <Translate content="confirm"/></button>


                                </Form>
                            )}
                        />
                        


                    </div>

                </div>
             
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authounticateUser: () => {
            dispatch(authounticateUser())
        },


    }
};
export default connect(null, mapDispatchToProps)(ChangePassword)
