import React, {Component, Fragment} from "react"
import Translate from "react-translate-component";
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import Swiper from "react-id-swiper";
import './component/assest/css/swiper.min.css';
import axios from "axios";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Redirect} from 'react-router-dom';
import {BallBeat} from "react-pure-loaders";
import connect from "react-redux/es/connect/connect";
import {RESETAPP} from "./actions/cartActions";

let translate = require('counterpart');

class NearbyBranches extends Component {

    constructor(props) {
        const token = localStorage.getItem("token");

        let loggedIn = true;

        if (token == null) {
            loggedIn = false
        }
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            branches: JSON.parse(localStorage.getItem("nearbyBranches") || "[]"),
            // change: this.props.location.state.change,
            CountryEn: localStorage.getItem("CountryEn"),
            CountryAr: localStorage.getItem("CountryAr"),
            CityEn: localStorage.getItem("CityEn"),
            CityAr: localStorage.getItem("CityAr"),
            arAddress: localStorage.getItem("arAddress"),
            enAddress: localStorage.getItem("enAddress"),
            categories: [],
            products: [],
            recommendedProducts: [],
            categoryMessageShow: false,
            categoryMessageEn: "",
            categoryMessageAr: "",
            categoryId: null,
            branchId: null,
            categoryModal: false,
            redirect: false,
            loading: true,
            branchAr: '',
            branchEn: '',
            total: 0,
            zoom: 12,
            key: '1',
            countries: [],
            cities: [],
            countryModal: false,
            addressModal: false,
            cityModal: false,
            nobranchesModal: false,
            busyModal: false,
            selectedOption: "",
            branchesCardShow: true,
            changeCountry: false,
            changeAddress: false,
            setAddress: false,
            oldAddress: [],
            allowOpen: null,
            lat: 23.8859,
            lng: 45.0792,
            loggedIn
        };
    }

    componentDidMount() {
        if (this.state.branches.length !== 0) {
            this.setState({
                lat: this.state.branches[0].latitude,
                long: this.state.branches[0].longitude,
                branchesCardShow: true,
            })

        } else {
            let params = {
                type: 'all'
            };
            let headers = {
                'Accept': 'application/json',
            };
            axios.get(window.$urlName + 'branches', {
                params,
                headers
            }).then((response) => {
                this.setState({
                    branches: response.data.data,
                    lat: response.data.data[0].latitude,
                    long: response.data.data[0].longitude,
                    zoom: 7,
                    branchesCardShow: false,
                })
                this.nobranchesTypeModel();

            }).catch(function (error) {
                // console.log(error.response)
            });
        }

        if (localStorage.getItem("typeId") === '2') {
            this.setState({
                changeCountry: false,
                changeAddress: true
            })
        } else {
            this.setState({
                changeCountry: true,
                changeAddress: false
            })
        }
    }

    redirectMap = () => {
        this.setState({
            setAddress: true
        })
    };

    deleteAddress = (id) => {
        axios.delete(window.$urlName + 'addresses/' + id, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token,
                'Accept': 'application/json'
            }
        }).then((response) => {
            window.location.reload();
        });
    };

    getLocationFun = (id, lat, long) => {
        this.setState({
            key: id.toString(),
            zoom: 15,
            lat: lat,
            long: long,
        });
    };

    addressTypeModel = () => {
        if (this.state.loggedIn === false) {
            this.setState({
                login: true
            })
        } else {
            axios.get(window.$urlName + 'addresses', {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token,
                    'Accept': 'application/json'
                }
            })
                .then((response) => {
                    this.setState({
                        oldAddress: response.data.data,
                        loading: false,
                    });
                });
            this.setState(prevState => ({
                addressModal: !prevState.addressModal,
            }));
        }
    };

    countryTypeModel = () => {
        axios.get(window.$urlName + 'countries').then((response) => {
            this.setState({
                countries: response.data.data,
                loading: false,
                loggedIn: true

            });

        });
        this.setState(prevState => ({
            countryModal: !prevState.countryModal,
        }));
        this.props.RESETAPP()
    };

    cityTypeModel = () => {
        this.setState(prevState => ({
            cityModal: !prevState.cityModal,
            countryModal: !prevState.countryModal,
        }));
    };

    nobranchesTypeModel = () => {
        this.setState(prevState => ({
            nobranchesModal: !prevState.nobranchesModal,
        }));
    };

    busyTypeModel = () => {
        this.setState(prevState => ({
            busyModal: !prevState.busyModal,
        }));
    };

    handleOptionCityChange = (id, e, CityEn, CityAr) => {
        this.setState({
            cityId: id,
            CityEn: CityEn,
            CityAr: CityAr,
        })
    };

    setAddress = (id, lat, long, arAddress, enAddress) => {
        localStorage.setItem("addressId", id);
        localStorage.setItem("addressLat", lat);
        localStorage.setItem("addressLong", long);
        axios.get(window.$urlName + 'branches', {
            params: {
                type: 'nearby',
                class: 'delivery',

                // setValue
                latitude: lat,
                longitude: long,
            }
        }).then((response) => {
            if (response.data.data.length === 0) {
                let params = {
                    type: 'all'
                };
                let headers = {
                    'Accept': 'application/json',
                };
                axios.get(window.$urlName + 'branches', {
                    params,
                    headers
                }).then((response) => {
                    this.setState({
                        branches: response.data.data,
                        lat: response.data.data[0].latitude,
                        long: response.data.data[0].longitude,
                        zoom: 7,
                        branchesCardShow: false,
                        arAddress: arAddress,
                        enAddress: enAddress,

                    })
                    this.setState(prevState => ({
                        addressModal: !prevState.addressModal,

                    }));
                    this.nobranchesTypeModel();

                }).catch(function (error) {
                    // console.log(error.response)
                });

            } else {
                this.setState({
                    branches: response.data.data,
                    branchesCardShow: true,
                    arAddress: arAddress,
                    enAddress: enAddress,
                });
                this.setState(prevState => ({
                    addressModal: !prevState.addressModal,
                }));
            }
        });
    };

    handelCitySubmit = (e) => {
        e.preventDefault();
        axios.get(window.$urlName + 'branches', {
            params: {
                type: 'city',
                class: 'branch',
                cityId: this.state.cityId,
            }
        }).then((response) => {
            if (response.data.data.length === 0) {
                let params = {
                    type: 'all'
                };
                let headers = {
                    'Accept': 'application/json',
                };
                axios.get(window.$urlName + 'branches', {
                    params,
                    headers
                }).then((response) => {
                    this.setState({
                        branches: response.data.data,
                        lat: response.data.data[0].latitude,
                        long: response.data.data[0].longitude,
                        zoom: 7,
                        branchesCardShow: false,

                    })
                    this.setState(prevState => ({
                        cityModal: !prevState.cityModal,

                    }));
                    this.nobranchesTypeModel();

                }).catch(function (error) {
                    // console.log(error.response)
                });

            } else {
                this.setState({
                    branches: response.data.data,
                    branchesCardShow: true,

                });
                this.setState(prevState => ({
                    cityModal: !prevState.cityModal,

                }));
            }
        });
    };

    deliveryTypeModel = () => {
        this.setState(prevState => ({
            deliveryModal: !prevState.deliveryModal
        }));
    };

    handleOptionChange = (cities, changeEvent, CountryEn, CountryAr) => {
        this.setState({
            cities: cities,
            selectedOption: changeEvent.target.value,
            CountryEn: CountryEn,
            CountryAr: CountryAr,
        });
        this.cityTypeModel()
    };

    // handleToggleOpen = (markerId,arName,enName) => {
    //     console.log(markerId,arName,enName)
    //
    // }

    displayMarkers = () => {
        return this.state.branches.map((branch, index) => {
            return <Marker key={index} id={index} icon={{
                url: require('./component/assest/img/sign.svg'),
                scaledSize: new this.props.google.maps.Size(50, 50)
            }} position={{
                lat: branch.latitude,
                lng: branch.longitude
            }}
                // onClick={() => this.handleToggleOpen(index,branch.arName,branch.enName)}
            />
        })
    };

    handelBranchSubmit = (event, id, branchAr, branchEn, arCurrency, enCurrency, busy, carOption) => {

        if (parseInt(localStorage.getItem("branchId")) !== id) {
            this.props.RESETAPP()
        }

        if (busy === true) {
            this.busyTypeModel()
        }

        this.setState({
            branchId: id,
            branchAr: branchAr,
            branchEn: branchEn,
        });

        localStorage.setItem("branchId", id);

        if (carOption === true) {
            localStorage.setItem("carOption", "true");
        } else {
            localStorage.setItem("carOption", "false");
        }

        axios.get(window.$urlName + 'categories').then((response) => {
            this.setState({
                categories: response.data.data,
            });
            localStorage.setItem("categories", JSON.stringify(response.data.data));
            localStorage.setItem("catArName", response.data.data[0].arName);
            localStorage.setItem("catEnName", response.data.data[0].enName);
            this.getPro(id, branchAr, branchEn, arCurrency, enCurrency, response.data.data[0].id)
        });
    };

    getPro = (id, branchAr, branchEn, arCurrency, enCurrency, catId) => {
        let typeId = parseInt(localStorage.getItem("typeId"));
        if (typeId === 2) {
            axios.get(window.$urlName + 'products', {
                params: {
                    categoryId: catId,
                    branchId: parseInt(id),
                    type: "delivery",
                    categoryMessage: 'true',
                    categoryRecommended: 'true'
                }
            }).then((response) => {
                localStorage.setItem("products", JSON.stringify(response.data.data.products));
                localStorage.setItem("categoryMessageShow", response.data.data.popupShow);
                localStorage.setItem("categoryMessageEn", response.data.data.popupMessageEn);
                localStorage.setItem("categoryMessageAr", response.data.data.popupMessageAr);
                localStorage.setItem("recommendedProducts", JSON.stringify(response.data.data.recommended));
                localStorage.setItem("branchId", id);
                localStorage.setItem("branchAr", branchAr);
                localStorage.setItem("branchEn", branchEn);
                localStorage.setItem("branches", JSON.stringify(this.state.branches));
                localStorage.setItem("arCurrency", arCurrency);
                localStorage.setItem("enCurrency", enCurrency);

                this.setState({
                    products: response.data.data.products,
                    categoryMessageShow: response.data.data.popupShow,
                    categoryMessageEn: response.data.data.popupMessageEn,
                    categoryMessageAr: response.data.data.popupMessageAr,
                    recommendedProducts: response.data.data.recommended,
                    redirect: true
                })
            });
        } else {
            axios.get(window.$urlName + 'products', {
                params: {
                    categoryId: catId,
                    branchId: parseInt(id),
                    categoryMessage: 'true',
                    categoryRecommended: 'true'
                }
            }).then((response) => {
                localStorage.setItem("products", JSON.stringify(response.data.data.products));
                localStorage.setItem("categoryMessageShow", response.data.data.popupShow);
                localStorage.setItem("categoryMessageEn", response.data.data.popupMessageEn);
                localStorage.setItem("categoryMessageAr", response.data.data.popupMessageAr);
                localStorage.setItem("recommendedProducts", JSON.stringify(response.data.data.recommended));
                localStorage.setItem("branchId", id);
                localStorage.setItem("branchAr", branchAr);
                localStorage.setItem("branchEn", branchEn);
                localStorage.setItem("branches", JSON.stringify(this.state.branches));
                localStorage.setItem("arCurrency", arCurrency);
                localStorage.setItem("enCurrency", enCurrency);

                this.setState({
                    products: response.data.data.products,
                    categoryMessageShow: response.data.data.popupShow,
                    categoryMessageEn: response.data.data.popupMessageEn,
                    categoryMessageAr: response.data.data.popupMessageAr,
                    recommendedProducts: response.data.data.recommended,
                    redirect: true
                })
            });
        }
    };

    // setAllow = (open,allowLater) => {
    //     if (open === true){
    //         this.setState({
    //             allowOpen:true,
    //         })
    //         if(allowLater === true){
    //             this.setState({
    //                 allowOpen:false,
    //             })
    //         }else {
    //             this.setState({
    //                 allowOpen:true,
    //             })
    //         }
    //     }
    //     return this.state.allowOpen
    //
    // }

    render() {
        const {setAddress} = this.state;
        if (setAddress) {
            return <Redirect to={{
                pathname: '/set-address',
            }}
            />
        }

        const {login} = this.state;

        if (login) {
            const location =
                {pathname: '/sign-in', state: {from: 'nearby-branches'}};
            this.props.history.push(location);
        }

        const {redirect} = this.state;

        if (redirect) {
            return <Redirect to={{
                pathname: '/food-list',
                // state: {categories: this.state.categories},
            }}
            />
        }

        let oldAddress = this.state.oldAddress.map((address) => {
            return (
                <div className="col-lg-12 col-md-12" key={address.id}>
                    <div className="addressCard">
                        <div className="parent">
                            <div className="child">
                                <h3>{address.title}</h3>
                                {translate.getLocale() === 'ar' ? <p>{address.arAddress}</p> :
                                    <p>{address.enAddress}</p>}
                                <div className="addressButton">
                                    <button type="button"
                                            onClick={() => this.setAddress(address.id, address.latitude, address.longitude, address.arAddress, address.enAddress)}>
                                        <Translate content="choose"/></button>
                                    <button type="button" onClick={() => this.deleteAddress(address.id)}
                                            style={{background: "#DB095B"}}><Translate content="delete"/></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });

        let categories = this.state.categories.map((category) => {
            return (
                <div className="countrySelect" key={category.id}>
                    <input type="radio" name="category" value={category.id} id={category.id}
                           onChange={this.handleOptionChange} required/>

                    {translate.getLocale() === 'ar' ? <label htmlFor={category.id}>{category.arName}</label> :
                        <label htmlFor={category.id}>{category.enName}</label>}
                </div>
            )
        });

        let branchCard = this.state.branches.map((branch) => {

            return (
                <div key={branch.id}>
                    <div id={branch.id} className='branchCard'>
                        <div className="brnachInfo" onClick={() => {
                            this.getLocationFun(branch.id, branch.latitude, branch.longitude)
                        }}>
                            <div className="branchTitle">
                                <img src={branch.image} alt="icon"/>
                                {translate.getLocale() === 'ar' ? <h1>{branch.arName}</h1> : <h1>{branch.enName}</h1>}
                            </div>
                            <div className="workHours">
                                {branch.open === true ?
                                    <span style={{color: 'green'}}><Translate content="open_now"/></span> : <span
                                        style={{color: 'red'}}><Translate content="closed"/></span>}

                                <span> {branch.workingFrom} - {branch.workingTo}</span>
                            </div>
                            {translate.getLocale() === 'ar' ? <p>{branch.arAddress}</p> :
                                <p>{branch.enAddress}</p>}
                        </div>

                        <button type="button"
                                disabled={branch.open === true ? false : branch.allowForLater === true ? false : true}
                                onClick={(event) => {
                                    this.handelBranchSubmit(event, branch.id, branch.arName, branch.enName, branch.arCurrency, branch.enCurrency, branch.busy, false);
                                }}><Translate
                            content="received_order"/> {branch.carOption === true}</button>

                        {parseInt(localStorage.getItem("typeId")) === 1 && branch.carOption === true ?
                            <button type="button"
                                    disabled={branch.open === true ? false : branch.allowForLater === true ? false : true}
                                    onClick={(event) => {
                                        this.handelBranchSubmit(event, branch.id, branch.arName, branch.enName, branch.arCurrency, branch.enCurrency, branch.busy, true);
                                    }}><Translate
                                content="car_order"/></button> : null}
                    </div>
                </div>
            )
        });

        const params = {
            shouldSwiperUpdate: true,
            // rebuildOnUpdate: true,
            centeredSlides: true,
            slidesPerView: 4,
            spaceBetween: 10,
            // activeSlideKey: this.state.key,

            // autoplay: {
            //     delay: 2500,
            // },
            breakpoints: {
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 10
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                }
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },

        };

        let cities = this.state.cities.map((city) => {
            return (
                <div className="countrySelect" key={city.id}>
                    <input type="radio" name="country" value={city.id} id={city.enName}
                           onChange={(e) => this.handleOptionCityChange(city.id, e, city.enName, city.arName)}/>
                    {translate.getLocale() === 'ar' ? <label htmlFor={city.enName}>{city.arName}</label> :
                        <label htmlFor={city.enName}>{city.enName}</label>}
                </div>
            )
        });

        let countries = this.state.countries.map((country) => {
            return (
                <div className="countrySelect" key={country.id}>
                    <input type="radio" name="country" value={country.id} id={country.enName}
                           onChange={(e) => {
                               this.handleOptionChange(country.cities, e, country.enName, country.arName)
                           }}/>

                    {translate.getLocale() === 'ar' ? <label htmlFor={country.enName}>{country.arName}</label> :
                        <label htmlFor={country.enName}>{country.enName}</label>}
                </div>
            )
        });

        return (
            <Fragment>

                <div className="container">
                    {this.state.changeCountry ?
                        <div className="changeCountryRow">
                            <Translate content="country"/><span>>></span>
                            {translate.getLocale() === 'ar' ?
                                <span>{this.state.CountryAr ? this.state.CountryAr : "البلد الحالية"}</span> :
                                <span>{this.state.CountryEn ? this.state.CountryEn : "Current country"}</span>}
                            <span>/</span>
                            <Translate content="city"/><span>>></span>
                            {translate.getLocale() === 'ar' ?
                                <span>{this.state.CityAr ? this.state.CityAr : "المدينة الحالية"}</span> :
                                <span>{this.state.CityEn ? this.state.CityEn : "Current city"}</span>}
                            <Translate content="change_country" component="button" onClick={this.countryTypeModel}/>
                        </div> : null}
                    {this.state.changeAddress ?
                        <div className="changeCountryRow">
                            <Translate content="address"/><span>>></span>
                            {translate.getLocale() === 'ar' ?
                                <span>{this.state.arAddress ? this.state.arAddress.substring(0, 50) + " ..." : "العنوان الحالية"}</span> :
                                <span>{this.state.enAddress ? this.state.enAddress.substring(0, 50) + " ..." : "Current address"}</span>}
                            <Translate content="change_address" component="button" onClick={this.addressTypeModel}/>

                        </div> : null}
                </div>

                <div className="mapDiv" style={{position: "relative"}}>
                    <Map
                        google={this.props.google}
                        zoom={this.state.zoom}
                        center={{lat: this.state.lat, lng: this.state.long}}
                        initialCenter={{lat: this.state.lat, lng: this.state.long}}
                    >
                        {this.displayMarkers()}
                    </Map>

                </div>

                <div className="branchSwiper">
                    {this.state.branchesCardShow ? <Swiper {...params} activeSlideKey={this.state.key}>
                        {branchCard}
                    </Swiper> : null}
                </div>


                <Modal isOpen={this.state.categoryModal} toggle={this.categoryTypeModel} className="countryModal">
                    <ModalHeader toggle={this.categoryTypeModel}><Translate content="choose_category"/></ModalHeader>
                    <ModalBody>
                        <div className="container">
                            <BallBeat
                                color={'#2C2C2C'}
                                loading={this.state.loading}
                            />
                            {categories}
                            <button type="button" className="typeButton" onClick={this.handelBranchSubmit}><Translate
                                content="confirm"/></button>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.countryModal} toggle={this.countryTypeModel} className="countryModal">
                    <ModalHeader toggle={this.countryTypeModel}><Translate content="choose_country"/></ModalHeader>
                    <ModalBody>
                        <div className="container">
                            <div className="loadingSec">

                                <BallBeat
                                    color={'white'}
                                    loading={this.state.loading}
                                />
                            </div>
                            {countries}
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.cityModal} toggle={this.cityTypeModel} className="countryModal">
                    <ModalHeader toggle={this.cityTypeModel}><Translate content="choose_city"/></ModalHeader>
                    <ModalBody>
                        <form onSubmit={this.handelCitySubmit}>

                            <div className="container">
                                <div className="loadingSec">

                                    <BallBeat
                                        color={'white'}
                                        loading={this.state.loading}
                                    />
                                </div>
                                {cities}
                                <button type="submit" className="typeButton"><Translate
                                    content="confirm"/></button>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.nobranchesModal} toggle={this.nobranchesTypeModel} className="countryModal">
                    <ModalHeader toggle={this.nobranchesTypeModel}><Translate content="no_branches"/></ModalHeader>
                    <ModalBody>
                        <div className="noBranch">
                            {translate.getLocale() === 'ar' ?
                                <p> نعتذر لعدم امكانية توصيل طلبك . عنوانك خارج التغطية <a href="/"><Translate
                                    content="back"/></a></p> :
                                <p>There Are No Branches <a href="/"><Translate content="back"/></a></p>}
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.busyModal} toggle={this.busyTypeModel} className="countryModal">
                    <ModalHeader toggle={this.busyTypeModel}><Translate content="busy_branch"/></ModalHeader>
                    <ModalBody>
                        <div className="noBranch">
                            {translate.getLocale() === 'ar' ?
                                <p>الفرع مشغول، تقدر تطلب بعد الزحام.</p> :
                                <p>This branch is currently busy. You can order for later when branch is not busy.</p>}
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.addressModal} toggle={this.addressTypeModel} className="locationModel">
                    <ModalHeader toggle={this.addressTypeModel}><Translate content="choose_location"/></ModalHeader>
                    <ModalBody>
                        <div className="container">
                            <div className="row addressSec">
                                {oldAddress}
                            </div>
                            <button className='orangeButton' id="newAddress" onClick={this.redirectMap}><Translate
                                content="new_address"/></button>
                        </div>
                    </ModalBody>
                </Modal>


            </Fragment>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        items: state.addedItems,
        total: state.total
    }
};
const mapDispatchToProps = (dispatch) => {

    return {
        RESETAPP: () => {
            dispatch(RESETAPP())
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({apiKey: 'AIzaSyAh_LlRIucbMyXXMwIrTxw1uKLvmgAnJLk'})(NearbyBranches))
