import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {Link} from "react-router-dom";
import Sticky from "react-sticky-el";
import MudiafBanner from "./mudiafBanner";
import insta from "./component/assest/img/instagram.svg";
import twitter from "./component/assest/img/twitter.svg";

import {BallBeat} from "react-pure-loaders";

class MudiafContact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading:false,
            mdiafPhone1: "",
            mdiafPhone2: "",
            mdiafPhone3: "",
            mdiafPhone4: "",
        }
    }

    componentDidMount() {
        this.setState({
            loading:true
        })
        let headers = {
            'Accept': 'application/json',
        };
        axios.get(window.$urlName + 'infos/mdiaf_phones', {
            headers
        }).then((response) => {
            this.setState({
                mdiafPhone1: response.data.data.mdiafPhone1,
                mdiafPhone2: response.data.data.mdiafPhone2,
                mdiafPhone3: response.data.data.mdiafPhone3,
                mdiafPhone4: response.data.data.mdiafPhone4,
                loading:false
            })

        }).catch(function (error) {
             //console.log(error.response)
        });
    }
    
    render() {
        return (
            <Fragment>
                <MudiafBanner></MudiafBanner>

                <Sticky stickyClassName="stickyFilter">
                    <div className="mudiaf-bar">
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf`}}>
                                <Translate content="mudiaf_menus"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-services`}}>
                                <Translate content="mudiaf_services"/>
                            </Link>
                        </div>                        
                        <div className="mudiaf-items mudiaf-items">
                            <Link to={{pathname: `/medhyaf-inquires`}}>
                                <Translate content="mudiaf_inquires"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-images`}}>
                                <Translate content="mudiaf_images"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items mudiaf-items-active">
                            <Link to={{pathname: `/medhyaf-contact`}}>
                                <Translate content="mudiaf_contact"/>
                            </Link>
                        </div>
                    </div>
                </Sticky>

                <div className="container-fluid branchesSec">
                    <div className="loadingSec">
                        <BallBeat
                            color={'#2C2C2C'}
                            loading={this.state.loading}
                        />
                    </div>

                    <div className="container mdiaf-contact">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Translate component="p" className="contactP" content="contact_us"/>
                            </div>
                        </div>

                        <div className="row">
                            {(this.state.mdiafPhone1 !== "")?
                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                    <a href={"tel:"+this.state.mdiafPhone1}>{this.state.mdiafPhone1}</a>
                                </div>
                            :""}

                            {(this.state.mdiafPhone2 !== "")?
                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                    <a href={"tel:"+this.state.mdiafPhone2}>{this.state.mdiafPhone2}</a>
                                </div>
                            :""}

                            {(this.state.mdiafPhone3 !== "")?
                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                    <a href={"tel:"+this.state.mdiafPhone3}>{this.state.mdiafPhone3}</a>
                                </div>
                            :""}

                            {(this.state.mdiafPhone4 !== "")?
                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                    <a href={"tel:"+this.state.mdiafPhone4}>{this.state.mdiafPhone4}</a>
                                </div>
                            :""}
                        </div>         

                        <div className="socialNavbar">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <Translate component="p" className="contactP" content="followUs"/>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <a href="https://www.instagram.com/medhyafsa/" target="_blank" rel="noopener noreferrer" className="socialIcon"><img src={insta} alt="instgram"/>
                                    </a>
                                    <a href="https://twitter.com/medhyafsa" target="_blank" rel="noopener noreferrer" className="socialIcon"><img src={twitter} alt="twitter"/>
                                    </a>       
                                </div>
                            </div>
                          
                        </div>                  
                    </div>
               </div>
            </Fragment>
        )
    }
}

export default MudiafContact