import React, {Component} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {BallBeat} from "react-pure-loaders";
import {Link} from "react-router-dom";

class MudiafOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            done: [],
            inProgress: [],
            waitingPayment: [],
            status: '',
            defaultEnAddress: 'Branch Pickup',
            defaultArAddress: 'تجينا الفرع',
            loading: true
        };
        axios.get(window.$urlName + 'mdiaf-orders', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token,
                'Accept': 'application/json'
            }
        }).then((response) => {
                this.setState({
                    done: response.data.data.done,
                    inProgress: response.data.data.inProgress,
                    waitingPayment: response.data.data.waitingPayment,
                    loading: false
                });
        document.getElementById("ordersBody").style.display="block";

        }).catch(function (error) {
            // console.error(error);
        });
    }


    render() {


        let doneOrders = this.state.done.length ? (
            this.state.done.map((item) => {
            return (
                <div className="col-lg-6 col-md-6" key={item.id}>
                    <Link to={{pathname: `/medhyaf-orders/${item.id}`}}>
                        <div className="orderDiv">
                            <div className="orderHead">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <p className="arialFont">{item.date}</p>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="arialFont">{item.time}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="orderBody">
                                <h3>{item.address}</h3>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <h2><Translate content="num_persons"/></h2>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p>{item.personsNumber}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <h2><Translate content="order_state"/></h2>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p>{item.statusId === 7 ? <Translate content="confirmed"/> : item.statusId === 8 ? <Translate content="inProgress"/> : item.statusId === 4 ? <Translate content="done"/> : <Translate content="waitingPayment"/>}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            )
        })
            ) :

            (
                <Translate content="no_orders" component="p" className="no_item"/>

            );




        let progressOrders = this.state.inProgress.length ? (
            this.state.inProgress.map((item) => {
            return (
                <div className="col-lg-6 col-md-6" key={item.id}>
                    <Link to={{pathname: `/medhyaf-orders/${item.id}`}}>
                        <div className="orderDiv">
                            <div className="orderHead">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <p className="arialFont">{item.date}</p>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="arialFont">{item.time}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="orderBody">
                                <h3>{item.address}</h3>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <h2><Translate content="num_persons"/></h2>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p>{item.personsNumber}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <h2><Translate content="order_state"/></h2>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p>{item.statusId === 7 ? <Translate content="confirmed"/> : item.statusId === 8 ? <Translate content="inProgress"/> : item.statusId === 4 ? <Translate content="done"/> : <Translate content="waitingPayment"/>}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Link>
                </div>
            )
        })
            ) :

            (
                <Translate content="no_orders" component="p" className="no_item"/>

            );


        let waitingOrder = this.state.waitingPayment.length ? (

            this.state.waitingPayment.map((item) => {
            return (
                <div className="col-lg-6 col-md-6" key={item.id}>
                    <Link to={{pathname: `/medhyaf-orders/${item.id}`}}>
                        <div className="orderDiv">
                            <div className="orderHead">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <p className="arialFont">{item.date}</p>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="arialFont">{item.time}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="orderBody">
                                <h3>{item.address}</h3>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <h2><Translate content="num_persons"/></h2>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p>{item.personsNumber}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <h2><Translate content="order_state"/></h2>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p>{item.statusId === 7 ? <Translate content="confirmed"/> : item.statusId === 8 ? <Translate content="inProgress"/> : item.statusId === 4 ? <Translate content="done"/> : <Translate content="waitingPayment"/>}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            )
        })
            ) :

            (
                <Translate content="no_orders" component="p" className="no_item"/>

            );


        return (
            <div className="panelBody">
                <h1><Translate content="my_orders"/></h1>
                <div className="loadingSec">
                    <BallBeat
                        color={'#2C2C2C'}
                        loading={this.state.loading}
                    />
                </div>

                <div id="ordersBody" style={{display: "none"}}>
                    <Translate content="wait_orders" component="h2" className="orderTitle"/>
                    <div className="row">
                        {waitingOrder}
                    </div>
                    <hr/>

                    <Translate content="next_orders" component="h2" className="orderTitle"/>
                    <div className="row">
                        {progressOrders}
                    </div>
                    <hr/>
                    <Translate content="pre_orders" component="h2" className="orderTitle"/>
                    <div className="row">
                        {doneOrders}
                    </div>

                </div>

            </div>
        )
    }

}

export default MudiafOrders


/*
address: "ali address"
date: "2020-04-03"
description: "ali"
id: 1
mdiafMenuId: 1
paymentMethod: "cash"
personsNumber: 2
statusId: 7
time: "03:30:00"
totalPrice: 200
*/