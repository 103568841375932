export default {
    'lang' : 'العربية',
    'er' : 'English',
    'signUp_signIn' : 'Register SignUp / SignIn',
    'home' : 'Home',
    'about_place' : 'About Place',
    'media_center' : 'Media Center',
    'branches' : 'Branches',
    'menu' : 'Menu',
    'contact_us' : 'Contact Us',
    'followUs' : 'Follow us at social media',
    'awafi' : 'Awafi',
    'food_menu' : 'Food Menu',
    'topCustomers' : 'Top Customers',
    'hospitable' : 'Medhyaf',
    'country' : 'Country',
    'bahrain' : 'Bahrain',
    'saudi' : 'Saudi',
    'footer_prg' : 'We provided you with an app to have the most delicious food' ,
    'signUp' : 'Sign Up' ,
    'name' : 'Name' ,
    'phone' : 'Phone' ,
    'tel' : 'Phone' ,
    'password' : 'Password' ,
    'confirm_password' : 'Confirm Password' ,
    'have_account' : 'Have an account?' ,
    'signIn' : 'Sign In' ,
    'dont_have_account' : 'Don\'t have an account?' ,
    'forget_password' : 'Forget Password?' ,
    'signIn_register' : 'Sign In' ,
    'enter_code' : 'Enter Code' ,
    'confirm' : 'Confirm' ,
    'resend' : 'Resend Message' ,
    'didnt_Arrive' : 'Didn\'t arrive?' ,
    'change_phone' : 'Change phone number' ,
    'enter_phone' : 'Enter phone number' ,
    'myPage' : 'My Page' ,
    'basket' : 'Basket' ,
    'price' : 'Price' ,
    'SAR' : 'SAR' ,
    'total' : 'Total' ,
    'continue' : 'Continue' ,
    'my_profile' : 'Your Profile' ,
    'my_wallet' : 'Your Wallet' ,
    'chargeWallet' : 'Charge Wallet' ,
    'enterBalance' : 'Enter Balance' ,
    'my_orders' : 'Your Orders' ,
    'points_credit' : 'Points Credit' ,
    'save' : 'Save' ,
    'points' : 'Points' ,
    'replace_points' : 'Replace Points' ,
    'birth_date' : 'Birth Date' ,
    'choose_city' : 'Choose Nationality' ,
    'male' : 'Male' ,
    'female' : 'Female' ,
    'gender' : 'Gender' ,
    'approved_conditions' : 'Do You Agree On Terms And Conditions' ,
    'about_hospitable' : 'It is a leading hospitality and catering company for the government and private sectors and has extensive experience in preparing the best foods within the framework of global food safety requirements. The services are to provide third parties of all kinds at the customer\'s location from the breakfast buffet, snacks at meetings, workshops and entertainment to private and government sites and pilgrims for Hajj and Umrah and ending with a buffet for lunch and dinner, and products in open buffet menus between western and eastern cuisine of appetizers and main dishes and desserts. We look forward to being a leader in hospitality with outstanding service, professional staff and a wide range of quality products and galleries for the best value and look forward to being the best food company in the world. Catering and events in the Arabian Gulf.',
    'received_order' :'Receive from branch',
    'car_order' :'Receive at car',
    'carDetails' :'Car Details',
    "carModel":"Car Model",
    "carColor":"Car Color",
    "carNumber":"Car Number",
    "carNotes":"Car Notes",
    'you_choose' :'You Choose',
    'change_branch' :'Change Branch',
    'meet' :'Meet',
    'fastDelivery' :'Fast Delivery',
    'ordinary_Delivery' :'Ordinary Delivery',
    'enter_promo' :'Enter Promo Code',
    'total_price' :'Total Price',
    'additionals' :'Choose Additions',
    'add_basket' :'Add To Basket',
    'payment' :'Payment',
    'wallet_payment' :'Wallet Payment',
    'arrivalPayment' :'Cash On Delivery',
    'creditCardPayment' :'Credit Card Payment',
    'back_to_list' :'Back To List',
    'estimate_time' :'Estimate Time',
    'delivery' :'Delivery',
    'total_cost' :'Total Cost',
    'order_received' :'Order Date',
    'order_time' :'Order Time',
    'order_state' :'Order State',
    'order_accepted' :'Order Accepted',
    'order_InProgress' :'Order In Progress',
    'done_order' :'Order Done',
    'cancel_request' :'Cancel Request',
    'order_with_driver' :'Order With Driver',
    'delivered' :'Has Been Delivered',
    'call_derive' :'Call Driver',
    'delivery_type' :'Choose Delivery Type',
    'share_them' :'Share Them Campaign',
    'from_branch' :'Branch Pickup',
    'from_home' :'Home Delivery',
    'change_address' :'Change Address',
    'address' :'Address',
    'closet_branch' :'Closet Branch',
    'extraa' :'Extra',
    'copy_write_parg' :'All copyright reserved for Shawees',
    'bestTry' :'How to request your order',
    'foodList' :'Our Menu',
    'smokes' :'Smokes',
    'see_list' :'Show Menu',
    'desserts' :'Desserts',
    'meats' :'Meat',
    'chicken' :'Chickens',
    'soup' :'Side dishes',
    'drinks' :'Drinks',
    'choose_location' :'Choose Your Address',
    'update' :'Update',
    'hi' :'Hi',
    'choose_country' :'Choose Country',
    'choose_type' :'Order Type',
    'choose_category' :'Choose Category',
    'meal_quantity' :'Meal Quantity',
    'choose' :'Choose',
    'new_address' :'New Address',
    'you_have_ordered' :'Your Basket:',
    'nothing' :'There are no items.',
    'delete' :'Delete',
    'sec' :'Sec',
    'branch_name' :'Branch name',
    'pre_orders' :'Done Orders',
    'next_orders' :'Current Orders',
    'choose_branch' :'Choose Branch',
    'title' :'Address Title',
    'extra' :'extras',
    'visit_branch' :'To change point please visit nearest branch',
    'close_branch' :'nearest branch',

    'enter_your_notes' : 'Enter Your Notes',
    'delivery_choice' : 'Order Type',
    'now' : 'Now',
    'later' : 'Later',
    'delete_modal' : 'Are you sure you want to delete your order ?',
    'cancel' : 'Cancel',
    'quantity' : 'Quantity',
    'add_more_item' : 'Add More Items',
    'email' : 'Email',
    'send' : 'Send',
    'upload' : 'Upload',
    'message' : 'Your Message',
    'city' : 'City',
    'change_country' : 'Change Country',
    'name_required' : 'Name is required',
    'phone_required' : 'Phone is required',
    'password_required' : 'Password is required',
    'phone_not_valid' : 'Phone number is not valid',
    'at_least' : 'Password must be at least 6 characters',
    'invalid_email' : 'Invalid email',
    'email_required' : 'Email is required',
    'message_required' : 'Message is required',
    'country_required' : 'Country is required',
    'confirm_required' : 'Confirm password is required',
    'password_must_match' : 'Password must match',
    'calories' : 'Calories : ',
    'terms' : 'Terms and Conditions',
    'logout' : 'logout',
    'logout_modal' : 'Are you sure you want to logout ?',
    'order_type' : 'Order type',
    'clear_basket' : 'If you change order type your basket will be cleared !',
    'change_delivery' : 'If you change order type your basket will be cleared !',
    'disc_mount' : 'Discount',
    'rate_order' : 'Rate your order',
    'back' : 'Back',
    'wait_orders' : 'Waiting Payment Orders',
    'details' : 'Details',
    'size' : 'Size',
    'success_payment' : 'You payment process has been succeeded',
    'notSuccess_payment' : 'Something Wrong in your payment process',
    'track_order' : 'Track your order',
    'pay_again' : 'Pay Again',
    'calorie' : 'Calories',
    'id' : 'ID',
    'additions' : 'Additionals',
    'code' : 'code',
    'extras' : 'Extras',
    'call_branch' : 'Call Branch',
    'call_driver' : 'Call Driver',
    'no_products' : 'There are no products available',
    'no_orders' : 'There are no orders',
    'total_price_after_disc' : 'Price After Offer',
    'discount' : 'Discount',
    'delivery_price' : 'Delivery Price',
    'page_not_found' : 'Page Not Found',
    'min' : 'min',
    'salad' : 'Appetizers',
    'spanish' : 'New Shawees',
    'log_out' : 'Logout',
    'nothing_basket' : 'There are no items in your basket',
    'product_must_be_faild' : 'you must add items in your basket',
    'loyalty' : 'Awafi',
    'no_branches' : 'No branches are available',
    'our_branches' : 'Our Branches',
    'order_id' : 'Order Id',
    'sorry' : 'Attention',
    'start_shawees' : 'Sign In',
    'choose_Nationality' : 'Choose Nationality',
    'change_Delivery' : 'Change delivery type',
    'open_now' : 'open now',
    'closed' : 'close now',
    'without_delivery' : ' without delivery fees',
    'your_rate' : 'Your Rate',
    'change_password' : 'Change Password',
    'notes' : 'Notes',
    'meals' : 'Meals',
    'paymentMethod' : 'Payment method',
    'order_details' : 'Order details',
    'choose_address' : 'Choose address',
    'no_address' : 'There are no address',
    'terms_must_checked' : 'Must Accept Terms and Conditions',
    'agree_terms' : 'Agreed On',
    'terms_conditions' : ' Terms And Conditions',
    'tax' : ' TAX',
    'order_scan' : ' Order scan',
    'address_name' : 'Address Name',
    'you_win' : 'You Win',
    'busy_branch' : 'Branch is busy',
    'cancelReason1' : 'The delivery time is not appropriate',
    'cancelReason2' : 'I would like to change the order',
    'cancelReason3' : 'I cannot receive the order',
    'changePaymentMethod' : 'Change Payment Method',
    'recommendedMeals' : 'Recommended Meals',

    //Medhyaf Translations
    'welcome_mudiaf' : 'Welcome To Medhyaf',
    'mudiaf_orders' : 'Medhyaf Orders',
    'mudiaf_menus' : 'Main Menus',
    'mudiaf_services' : 'Additonal Services',
    'mudiaf_inquires' : 'Party Inquires',
    'mudiaf_images' : 'Customers Shares',
    'mudiaf_contact' : 'Contact Us',
    'min_persons' : 'Min Persons',
    'num_persons' : 'No. Persons',
    'person' : 'Person',
    'book_menu' : 'Book Menu',
    'waitingPayment' : 'Waiting Payment',
    'inProgress' : 'In-Progress',
    'confirmed' : 'Confirmed',
    'done' : 'Done',
    'youChoose' : 'You Choose',
    'openMenu' : 'Check Menu',
    'enterAddress' : 'Enter Your Address',
    'categories' : 'Categories',
    'personPrice' : 'Menu Price',
    'number' : 'Number',
    'chooseDate' : 'Choose Date', 
    'anotherMenus' : 'Other Menus',
    'forMore' : 'For more information, Call us',
    'noResults' : 'No results are available',
    'sharePhotos' : 'Share Photos',
    'sharePhotosaragraph' : 'Share your experience with Medhyaf Shawees',
    'inquireParagraph' : 'Send us your request details and create your party now',
    'inquireParagraph2' : 'Choose the most delicious meals and create your party now',
    'inquireSuccess' : 'We have received your request, you will be contacted through our representatives',
    'imageSuccess' : 'Thank you, your post is under review',
    'selectPhoto' : 'Select Photo',
    'backToMenu' : 'Back To Menu',
    'by' : 'By',
    'goToBasket' : 'Go To Basket',
    'product' : 'Porducts',

};