import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {BallBeat} from "react-pure-loaders";

let translate = require('counterpart');

class Branches extends Component {
    constructor(props) {
        super(props)
        this.state = {
            branches: [],
            loading:false
        }
    }

    componentDidMount() {
        this.setState({
            loading:true
        })
        let params = {
            type: 'all'
        };
        let headers = {
            'Accept': 'application/json',
        };
        axios.get(window.$urlName + 'branches', {
            params,
            headers
        }).then((response) => {
            this.setState({
                branches: response.data.data,
                loading:false

            })
        }).catch(function (error) {
            // console.log(error.response)
        });
    }

    render() {
        let branches = this.state.branches.map((branch) => {
            return (

                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" key={branch.id}>
                    <div className="branchSingleCard">

                        <div className="branchImg" style={{backgroundImage: `url(${branch.image})`}}>
                        </div>
                        {translate.getLocale() === 'ar' ? <h3>{branch.arName}</h3> :
                            <h3>{branch.enName}</h3>}
                        {translate.getLocale() === 'ar' ? <p>{branch.arAddress.substring(0,100)}</p> :
                            <p>{branch.enAddress.substring(0,100)}</p>}
                    </div>

                </div>
            )
        });
        return (
            <Fragment>
                <div className="container-fluid hospitableCover">
                </div>
                <div className="container-fluid branchesSec">
                    <Translate content="our_branches" component="h1"/>
                    <div className="loadingSec">

                    <BallBeat
                        color={'#2C2C2C'}
                        loading={this.state.loading}
                    />
                    </div>
                    <div className="container">
                        <div className="row">

                            {branches}

                        </div>

                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Branches