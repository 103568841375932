import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import video from "./component/assest/video/topCustomers.mp4";
import poster from "./component/assest/img/poster.png";
import {Link} from "react-router-dom";
import Sticky from "react-sticky-el";
import TopCustomersBanner from "./topCustomersBanner";

class TopCustomers extends Component {
    render() {
        return (
            <Fragment>
                <TopCustomersBanner></TopCustomersBanner>
                <Sticky stickyClassName="stickyFilter">
                    <div className="mudiaf-bar top-bar">
                        <div className="mudiaf-items mudiaf-items-active">
                            <Link to={{pathname: `/top-customers`}}>
                                <Translate content="topCustomers"/>
                            </Link>
                        </div>                       
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/top-customers-terms`}}>
                                <Translate content="terms"/>
                            </Link>
                        </div>
                    </div>
                </Sticky>

                <div className="container-fluid branchesSec">
                    <div className="container">
                        <div className="row topCustomers">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <p> يحصل العميل على أحد هذه المزايا مع مجموعة من الخدمات الخاصة والمتنوعة والحصرية وهي: </p>
                                <ul>
                                    <li>هدية ذبيحة خاصة من مطاعم الرومانسية. </li>
                                    <li>بوفيه مفتوح.</li>
                                    <li>خدمة التوصيل السريع. </li>
                                    <li>رقم خاص لاستقبال الطلبات.</li>
                                    <li>تقدم غرف الضيافة في الفروع مجاناً. </li>
                                    <li>تحويلة خاصة في الرقم الموحد لخدمة كبار العملاء.</li>
                                    <li>بطاقة رقمية في التطبيق لتسهيل الخدمة في الفروع. </li>
                                    <li>وغيرها الكثير من المزايا.</li>
                                </ul>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <video width="100%" controls poster={poster} >
                                    <source src={video} type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default TopCustomers