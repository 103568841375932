import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import ReactStars from 'react-rating-stars-component'
import axios from "axios";
import {Modal, ModalBody} from "reactstrap";
let translate = require('counterpart');

class Rate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rateValue: 0,
            msgAr:"",
            msgEn:"",
            errorMsgModel: false,
            disabled:true,
        }
    }
    errorTypeModel = () => {
        this.setState(prevState => ({
            errorMsgModel: !prevState.errorMsgModel,

        }));

    };
    ratingChanged = (newRating) => {
        this.setState({rateValue: newRating});


    }
    handelRate = () => {
        let type = {
            type: "rate",
            rate: this.state.rateValue
        };
        axios.put(window.$urlName + 'orders/' + this.props.orderId, type, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
                'Accept': 'application/json',
                'Content-Type': 'application/json',

            }
        }).then((response) => {
            this.setState({
                msgAr:"شكرا لتقييمك",
                msgEn:"Thank you for rating",
                errorMsgModel:true,
                disabled:false,
            });
            window.location.reload()

        });
    }

    render() {
        return (
            <Fragment>
                <div className="rateBody">
                    <Translate content="rate_order" component="p"/>
                    <ReactStars
                        value={this.state.rateValue}
                        count={5}
                        onChange={this.ratingChanged}
                        size={25}
                        half={false}
                        color2={'#ffd700'}
                        className={"rateDiv"}/>
                    <button type="button" onClick={this.handelRate} disabled={!this.state.disabled}><Translate content="send"/></button>

                </div>
                <Modal isOpen={this.state.errorMsgModel} toggle={this.errorTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            {translate.getLocale() === 'ar' ? <p> {this.state.msgAr}</p> :
                                <p>{this.state.msgEn}</p>}
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

export default Rate
