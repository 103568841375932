import {
    ADD_TO_CART,
    REMOVE_ITEM,
    SUB_QUANTITY,
    ADD_QUANTITY,
    RESET_APP,
    CLEAR_PROMO,
    SET_PROMO,
    AUTHENTICATE_THE_USER,
    SET_DELIVERY,
    SET_LOCATION,
} from '../actions/action-types/cart-actions'


const initState = {
    // items: [],
    addedItems: [],
    finalProductArr: [],
    total: 0,
    deliveryPrice: 0,
    descountPrice: 0,
    code: '',
    authorized:false,
    ordinary:"",
    fast:"",
    lat:"",
    lng:"",



};

const cartReducer = (state = initState, action) => {

    //INSIDE HOME COMPONENT
    if (action.type === ADD_TO_CART) {
        // let addedItem = state.items.find(item => item.id === action.id);
        // let items = JSON.parse(localStorage.getItem("item"));
        let addedItem = JSON.parse(localStorage.getItem("item"));
        let arr = JSON.parse(localStorage.getItem("reducer") || "[]");
        let existedLocalStorage = arr.find(item => action.id === item.id);
        if (existedLocalStorage) {
            let itemSize = arr.find(item => addedItem.sizeId === item.sizeId);
            if (itemSize) {
                let itemAddition = arr.find(item => addedItem.id === item.id &&  addedItem.additionals[0] === item.additionals[0]  && addedItem.sizeId === item.sizeId);
                if (itemAddition) {
                    let itemExtras = arr.find(item => addedItem.id === item.id && addedItem.sortedExtras === item.sortedExtras && addedItem.sizeId === item.sizeId && addedItem.additionals[0] === item.additionals[0])
                    if (itemExtras) {
                        itemExtras.quantity += 1;
                        itemExtras.itemTotalPrice = parseFloat(itemExtras.price * itemExtras.quantity);
                        localStorage.setItem("reducer", JSON.stringify(arr));
                        let total = parseFloat(localStorage.getItem("total"));
                        total += parseFloat(itemExtras.price);
                        localStorage.setItem("total", JSON.stringify(total));
                        console.log(itemExtras)

                        return {
                            ...state,
                            addedItems: JSON.parse(localStorage.getItem("reducer") || "[]"),
                            total: localStorage.getItem("total"),
                            descountPrice: localStorage.setItem("descountPrice", ""),
                            code: localStorage.setItem("code", "")
                        }

                    } else {
                        addedItem.quantity = 1;
                        addedItem.itemTotalPrice = addedItem.price * addedItem.quantity;
                        arr.push(addedItem);
                        // localStorage.setItem("items", JSON.stringify(addedItem));
                        localStorage.setItem("reducer", JSON.stringify(arr));
                        let total = (localStorage.getItem("total")) ? parseFloat(localStorage.getItem("total")) : 0;
                        total += addedItem.itemTotalPrice;
                        localStorage.setItem("total", JSON.stringify(total));

                        return {
                            ...state,
                            // items : JSON.parse(localStorage.getItem("items") || "[]"),
                            addedItems: JSON.parse(localStorage.getItem("reducer") || "[]"),
                            total: localStorage.getItem("total"),
                            descountPrice: localStorage.setItem("descountPrice", ""),
                            code: localStorage.setItem("code", "")
                        }
                    }

                } else {
                    addedItem.quantity = 1;
                    addedItem.itemTotalPrice = addedItem.price * addedItem.quantity;
                    arr.push(addedItem);
                    // localStorage.setItem("items", JSON.stringify(addedItem));
                    localStorage.setItem("reducer", JSON.stringify(arr));
                    let total = (localStorage.getItem("total")) ? parseFloat(localStorage.getItem("total")) : 0;
                    total += addedItem.itemTotalPrice;
                    localStorage.setItem("total", JSON.stringify(total));

                    return {
                        ...state,
                        // items : JSON.parse(localStorage.getItem("items") || "[]"),
                        addedItems: JSON.parse(localStorage.getItem("reducer") || "[]"),
                        total: localStorage.getItem("total"),
                        descountPrice: localStorage.setItem("descountPrice", ""),
                        code: localStorage.setItem("code", "")
                    }
                }
            } else {
                addedItem.quantity = 1;
                addedItem.itemTotalPrice = addedItem.price * addedItem.quantity;
                arr.push(addedItem);
                // localStorage.setItem("items", JSON.stringify(addedItem));
                localStorage.setItem("reducer", JSON.stringify(arr));
                let total = (localStorage.getItem("total")) ? parseFloat(localStorage.getItem("total")) : 0;
                total += addedItem.itemTotalPrice;
                localStorage.setItem("total", JSON.stringify(total));

                return {
                    ...state,
                    // items : JSON.parse(localStorage.getItem("items") || "[]"),
                    addedItems: JSON.parse(localStorage.getItem("reducer") || "[]"),
                    total: localStorage.getItem("total"),
                    descountPrice: localStorage.setItem("descountPrice", ""),
                    code: localStorage.setItem("code", "")
                }

            }


        }


        else {
            addedItem.quantity = 1;
            addedItem.itemTotalPrice = addedItem.price * addedItem.quantity;
            arr.push(addedItem);
            localStorage.setItem("reducer", JSON.stringify(arr));
            // localStorage.setItem("items", JSON.stringify(addedItem));
            let total = (localStorage.getItem("total")) ? parseFloat(localStorage.getItem("total")) : 0;
            total += addedItem.itemTotalPrice;
            localStorage.setItem("total", JSON.stringify(total));

            return {
                ...state,
                // items : JSON.parse(localStorage.getItem("items") || "[]"),
                addedItems: JSON.parse(localStorage.getItem("reducer") || "[]"),
                total: localStorage.getItem("total"),
                descountPrice: localStorage.setItem("descountPrice", ""),
                code: localStorage.setItem("code", "")
            }

        }


    }
    if (action.type === REMOVE_ITEM) {
        if (JSON.parse(localStorage.getItem("reducer") || "[]").length === 1) {
            let arr = JSON.parse(localStorage.getItem("reducer") || "[]");
            let existedLocalStorage = arr.findIndex(item => action.id === item.id && action.sizeId === item.sizeId && action.additionals[0] === item.additionals[0] && action.sortedExtras === item.sortedExtras);
            arr.splice(existedLocalStorage, 1);
            localStorage.setItem("total", JSON.stringify(0));
            localStorage.setItem("reducer", JSON.stringify(arr));
        } else {
            let arr = JSON.parse(localStorage.getItem("reducer") || "[]");
            // let existedLocalStorage = arr.find(item => action.id === item.id);
            let existedLocalStorage = arr.findIndex(item => action.id === item.id && action.sizeId === item.sizeId && action.additionals[0] === item.additionals[0] && action.sortedExtras === item.sortedExtras);
            let total = parseFloat(localStorage.getItem("total"));
            total -= arr[existedLocalStorage].itemTotalPrice;
            localStorage.setItem("total", JSON.stringify(total));
            arr.splice(existedLocalStorage, 1);
            localStorage.setItem("reducer", JSON.stringify(arr));
        }


        return {

            ...state,
            addedItems: JSON.parse(localStorage.getItem("reducer") || "[]"),
            total: localStorage.getItem("total"),
            descountPrice: localStorage.setItem("descountPrice", ""),
            code: localStorage.setItem("code", "")
        }
    }


    //INSIDE CART COMPONENT
    if (action.type === ADD_QUANTITY) {
        let arr = JSON.parse(localStorage.getItem("reducer") || "[]");

        let existedLocalStorage = arr.findIndex(item => action.id === item.id &&  action.sizeId === item.sizeId && action.additionals[0] === item.additionals[0] && action.sortedExtras === item.sortedExtras);
        arr[existedLocalStorage].quantity += 1;
        arr[existedLocalStorage].itemTotalPrice = (arr[existedLocalStorage].price * arr[existedLocalStorage].quantity).toFixed(1);
        localStorage.setItem("reducer", JSON.stringify(arr));
        let total = (localStorage.getItem("total")) ? parseFloat(localStorage.getItem("total")) : 0;
        total += arr[existedLocalStorage].price;
        localStorage.setItem("total", JSON.stringify(total));


        return {
            ...state,
            addedItems: JSON.parse(localStorage.getItem("reducer") || "[]"),
            total: localStorage.getItem("total"),
            descountPrice: localStorage.setItem("descountPrice", ""),
            code: localStorage.setItem("code", "")
        }
    }


    if (action.type === SUB_QUANTITY) {
        let arr = JSON.parse(localStorage.getItem("reducer") || "[]");
        let existedLocalStorage = arr.findIndex(item => action.id === item.id && action.sizeId === item.sizeId && action.additionals[0] === item.additionals[0] && action.sortedExtras === item.sortedExtras);
        // let existedLocalStorage = arr.find(item => action.id === item.id);
        // let newExisted = existedLocalStorage;
        if (arr[existedLocalStorage].quantity === 1) {
        } else {
            arr[existedLocalStorage].quantity -= 1;
            arr[existedLocalStorage].itemTotalPrice = (arr[existedLocalStorage].price * arr[existedLocalStorage].quantity).toFixed(1);
            // let x = arr.findIndex(item => action.id === item.id)
            // arr.splice(x, 1);
            // arr.push(newExisted);
            localStorage.setItem("reducer", JSON.stringify(arr));
            let total = (localStorage.getItem("total")) ? parseFloat(localStorage.getItem("total")) : 0;
            total -= arr[existedLocalStorage].price
            localStorage.setItem("total", JSON.stringify(total))
            return {
                ...state,
                addedItems: JSON.parse(localStorage.getItem("reducer") || "[]"),
                total: localStorage.getItem("total"),
                descountPrice: localStorage.setItem("descountPrice", ""),
                code: localStorage.setItem("code", "")
            }
        }


    }


    //RESET CART COMPONENT
    if (action.type === RESET_APP) {
        localStorage.setItem("reducer", JSON.stringify([]));
        localStorage.setItem("finalProductArr", JSON.stringify([]));
        localStorage.setItem("total", JSON.stringify(0));
        localStorage.setItem("deliveryPrice", JSON.stringify(0));
        localStorage.setItem("carOption", "false");


        return {
            ...state,
            total: localStorage.getItem("total"),
            deliveryPrice: localStorage.getItem("deliveryPrice"),
            addedItems: JSON.parse(localStorage.getItem("reducer") || "[]"),
            finalProductArr: JSON.parse(localStorage.getItem("finalProductArr") || "[]"),
            descountPrice: localStorage.setItem("descountPrice", ""),
            code: localStorage.setItem("code", ""),
            carOption: localStorage.setItem("carOption", "false")
        }
    }


    //CLEAR PROMO COMPONENT
    if (action.type === CLEAR_PROMO) {
        localStorage.setItem("descountPrice", "");
        localStorage.setItem("code", "");
        return {
            ...state,
            total: localStorage.getItem("total"),
            descountPrice: localStorage.getItem("descountPrice"),
            code: localStorage.getItem("code")
        }
    }

    //SET PROMO COMPONENT
    if (action.type === SET_PROMO) {
        localStorage.setItem("descountPrice", action.dicPrice);
        localStorage.setItem("code", action.code);
        return {
            ...state,
            total: localStorage.getItem("total"),
            descountPrice: localStorage.getItem("descountPrice"),
            code: localStorage.getItem("code")
        }
    }
    //SET AUTHENTICATE USER
    if (action.type === AUTHENTICATE_THE_USER) {
        return {
            ...state,
            authorized: true,
        }
    }

    //SET DELIVERY PRICE
    if (action.type === SET_DELIVERY) {
        localStorage.setItem("ordinary", action.ordinary);
        localStorage.setItem("fast", action.fast);
        localStorage.setItem("deliveryPrice", JSON.stringify(action.delivery));
        return {
            ...state,
            ordinary: localStorage.getItem("ordinary"),
            fast: localStorage.getItem("fast"),
            deliveryPrice: parseFloat(JSON.parse(localStorage.getItem("deliveryPrice"))),

        }
    }
    //SET Location
    if (action.type === SET_LOCATION) {
        localStorage.setItem("defaultLat", action.lat);
        localStorage.setItem("defaultLng", action.lng);

        return {
            ...state,
            lat: action.lat,
            lng: action.lng,


        }
    }


    else {
        return state
    }

}

export default cartReducer
