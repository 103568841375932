import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {Link} from 'react-router-dom';
import {Redirect} from 'react-router-dom';
import {BallBeat} from "react-pure-loaders";
import connect from "react-redux/es/connect/connect";
import {authounticateUser} from "./actions/cartActions";
import {Modal, ModalBody} from "reactstrap";
let translate = require('counterpart');

class userCode extends Component {
    constructor(props) {
        super(props);
        let loggedIn = false;

        this.state = {
            token: localStorage.getItem("token"),
            loading: false,
            code: '',
            msgAr:"",
            msgEn:"",
            errorMsgModel: false,
            loggedIn
        };
    }
    errorTypeModel = () => {
        this.setState(prevState => ({
            errorMsgModel: !prevState.errorMsgModel,

        }));

    };
    handelChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };


    handelCode = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
        });


        let params = {
            type: "OTP",
        };
        let headers = {
            'Content-Type': 'application/json',
        };
        let data = {
            phone: localStorage.getItem("userPhone"),
            OTP: this.state.code
        };

        axios.post(window.$urlName + 'customers/get-token', data, {
            params,
            headers
        }).then((response) => {
            if (response.data.message === "success") {
                localStorage.setItem("token", response.data.data.access_token)
                axios.get(window.$urlName + 'customers/account-info', {
                    headers: {
                        'Authorization': 'Bearer ' + response.data.data.access_token,
                        'Accept': 'application/json'
                    }
                }).then((response) => {
                    localStorage.setItem("userName", response.data.data.name)
                    this.setState({
                        loggedIn: true,
                        loading: false
                    })
                })
            } else {
                this.setState({
                    loading: false,
                    msgAr:"الكود غير صحيح",
                    msgEn:"Invalid code",
                    errorMsgModel:true
                });
            }

        }).catch(function (error) {
            this.setState({
                loading: false,
                msgAr:"الكود غير صحيح",
                msgEn:"Invalid code",
                errorMsgModel:true
            });
        }.bind(this))
    };


    resend = () => {
        let params = {
            phone: localStorage.getItem("userPhone"),
        };
        let headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        axios.get(window.$urlName + 'customers/OTP-SMS-send', {params, headers}).then((response) => {
            this.setState({
                msgAr:"تم إرسال الكود",
                msgEn:"Your code has been send",
                errorMsgModel:true
            });
        }).catch(function (error) {
            // console.error(error);
        });
    };



    render() {
        if (this.state.loggedIn) {
            this.props.authounticateUser();
            return <Redirect to="/"/>
        }

        return (
            <Fragment>
                <div className="container">
                    <div className="signForm">

                        <div id="confirmCode">
                            <form onSubmit={this.handelCode}>
                                <div className="form-group">
                                    <label htmlFor="code"><Translate content="enter_code"/></label>
                                    <div className="loadingSec">
                                        <BallBeat
                                            color={'#2C2C2C'}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                    <Translate component="input" type="text" id="code" name="code"
                                               className="form-control" value={this.state.code}
                                               onChange={this.handelChange} attributes={{placeholder: 'code'}}
                                               required/>
                                </div>

                                <div className="resend"><Translate component="p" content="didnt_Arrive"/> <Translate
                                    content="resend" onClick={this.resend}/></div>
                                <button type="submit" id="sign-in-button">
                                    <Translate content="confirm"/></button>
                            </form>
                        </div>


                        <Link to="sign-up" className="verBackLink"><Translate content="back"/></Link>

                    </div>


                </div>
                <Modal isOpen={this.state.errorMsgModel} toggle={this.errorTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="sorry" component="p"/>

                            {translate.getLocale() === 'ar' ? <p> {this.state.msgAr}</p> :
                                <p>{this.state.msgEn}</p>}
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authounticateUser: () => {
            dispatch(authounticateUser())
        },


    }
};
export default connect(null, mapDispatchToProps)(userCode)



