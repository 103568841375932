import React, {Component} from 'react';
import {BrowserRouter, Switch,Route} from 'react-router-dom';
import SignUp from "./signUp";
import SignIn from "./signIn";
import ForgetPassword from "./forgetPassword";
import ChangePassword from "./changePassword";
import ChangePhone from "./changePhone";
import MyPage from "./myPage";
import FoodList from "./foodList";
import Payment from "./payment";
import OrderProcess from "./orderProcess";
import MudiafContact from "./mudiafContact";
import MudiafBasket from "./mudiafBasket";
import MudiafInquires from "./mudiafInquires";
import MudiafImages from "./mudiafImages";
import MudiafShare from "./mudiafShare";
import MudiafServices from "./mudiafServices";
import MudiafMenus from "./mudiafMenus";
import MudiafSingleMenu from "./mudiafSingleMenu";
import MudiafSingleOrder from "./mudiafSingleOrder";
import mudiafBooking from "./mudiafBooking";
import Home from "./home";
import Loyalty from "./loyalty";
import NearbyBranches from "./nearbyBranches";
import Cart from "./cart";
import VerificationCode from "./verificationCode";
import NetworkDetector from './networkDetector'
import Header from "./header";
import Footer from "./footer";
import counterpart from "counterpart";
import ar from "./component/lang/ar/ar";
import en from "./component/lang/en/en";
import Basket from "./basket";
import Terms from "./terms";
import paymentSuccess from "./PaymnetSuccess";
import pageError from "./404";
import Branches from "./branches";
import userCode from "./userCode";
import chargeWallet from "./chargeWallet";
import GeoLocated from "./geolocated";
import SetAddress from "./setAddress";
import updatePhoneCode from "./updatePhoneCode";
import TagManager from 'react-gtm-module'
import carDetails from "./carDetails";
import TopCustomers from "./topCustomers";
import TopCustomersTerms from "./topCustomersTerms";

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('ar', ar);
counterpart.setLocale('ar');

const tagManagerArgs = {
    gtmId: 'GTM-TNBRP7T',
    // auth: 'JS9ycZPNWt7s8lB-u_Vc0w',
    // preview: 'env-1',
    dataLayerName: 'PageDataLayer',
}

TagManager.initialize(tagManagerArgs)

class App extends Component {
     componentDidMount() {
         let lang = localStorage.getItem("lang");
        if (!lang) {
            localStorage.setItem("lang", 'ar');
            import(`${'./component/assest/css/ar.css'}`);
            counterpart.registerTranslations('ar', ar);
            counterpart.setLocale('ar');
        } else if (lang === 'en') {
            localStorage.setItem("lang", 'en');
            import(`${'./component/assest/css/en.css'}`);
            counterpart.registerTranslations('en', en);
            counterpart.setLocale('en');
        } else {
            localStorage.setItem("lang", 'ar');
            import(`${'./component/assest/css/ar.css'}`);

            counterpart.registerTranslations('ar', ar);
            counterpart.setLocale('ar');
        }

    }
    render() {
        return (

            <BrowserRouter>
                <GeoLocated/>
                <Header/>
                <div className="App">
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <Route path="/sign-up" component={SignUp}/>
                        <Route path="/sign-in" component={SignIn}/>
                        <Route path="/forget-password" component={ForgetPassword}/>
                        <Route path="/change-password" component={ChangePassword}/>
                        <Route path="/change-phone" component={ChangePhone}/>
                        <Route path="/profile" component={MyPage}/>
                        <Route path="/nearby-branches" component={NearbyBranches}/>
                        <Route path="/food-list" component={FoodList}/>
                        <Route path="/cart" component={FoodList}/>
                        <Route path="/verification-code" component={VerificationCode}/>
                        <Route path="/user-code" component={userCode}/>
                        <Route path="/medhyaf" component={MudiafMenus}/>
                        <Route path="/medhyaf-contact" component={MudiafContact}/>
                        <Route path="/medhyaf-menu" component={MudiafSingleMenu}/>
                        <Route path='/medhyaf-orders' component={MudiafSingleOrder}/>
                        <Route path='/medhyaf-booking' component={mudiafBooking}/>
                        <Route path='/medhyaf-images' component={MudiafImages}/>
                        <Route path='/medhyaf-share' component={MudiafShare}/>
                        <Route path='/medhyaf-services' component={MudiafServices}/>
                        <Route path='/medhyaf-inquires' component={MudiafInquires}/>
                        <Route path='/medhyaf-basket' component={MudiafBasket}/>
                        <Route path='/top-customers' component={TopCustomers}/>
                        <Route path='/top-customers-terms' component={TopCustomersTerms}/>
                        <Route path='/medhyaf-basket' component={MudiafBasket}/>
                        <Route path="/payment" component={Payment}/>
                        <Route path="/awafi" component={Loyalty}/>
                        <Route path="/cart" component={Cart}/>
                        <Route path='/order' component={OrderProcess}/>
                        <Route path='/basket' component={Basket}/>
                        <Route path='/terms' component={Terms}/>
                        <Route path='/payment-success' component={paymentSuccess}/>
                        <Route path='/404' component={pageError}/>
                        <Route path='/branches' component={Branches}/>
                        <Route path='/set-address' component={SetAddress}/>
                        <Route path='/update-phone' component={updatePhoneCode}/>
                        <Route path='/charge-wallet' component={chargeWallet}/>
                        <Route path='/car-details' component={carDetails}/>
                        <Route path="" component={pageError} />
                    </Switch>

                </div>
                <Footer/>
            </BrowserRouter>

        );
    }

}


export default NetworkDetector(App);
