import React, {Component} from 'react';
import LocationPickerMap from "./map";
import Translate from "react-translate-component";

class SetAddress extends Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="mapBody">
                <Translate content="choose_location" component="h1"/>
                <div className="addressMap">
                    <LocationPickerMap/>
                </div>
                </div>
            </div>
        )
    }
}

export default SetAddress