import React, {Component} from 'react';
import LocationPicker from 'react-location-picker';
import Translate from "react-translate-component";
import axios from "axios";
import {Redirect} from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';
import {Modal, ModalBody} from "reactstrap";
import connect from "react-redux/es/connect/connect";

let translate = require('counterpart');


class LocationPickerMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            address: "Saudi Arabia",
            position: {
                lat: 0,
                lng: 0
            },
            arAddress: "",
            title: "",
            lat: 23.8859,
            lng: 45.0792,

            disabled_Butt: true,
            msgAr: "",
            msgEn: "",
            errorMsgModel: false,
            zoom: 5,

        };

        // Bind
        this.handleLocationChange = this.handleLocationChange.bind(this);
    }

    componentDidMount() {
        if (this.props.lat && this.props.lng) {
            this.setState({
                lat: this.props.lat,
                lng: this.props.lng
            })
        } else {
            this.setState({
                lat: 23.8859,
                lng: 45.0792,
            })
        }
    }

    errorTypeModel = () => {
        this.setState(prevState => ({
            errorMsgModel: !prevState.errorMsgModel,
        }));

    };

    handleLocationChange({position, address, places}) {
        this.setState({position});

        let latValue = position.lat;
        let lngValue = position.lng;


        axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latValue + ',' + lngValue + '&language=ar&region=SA&key=AIzaSyAh_LlRIucbMyXXMwIrTxw1uKLvmgAnJLk')
            .then((response) => {
                // console.log(response.data)
                this.setState({
                    arAddress: response.data.results[0].formatted_address
                });
                console.log('ar:::'+response.data.results[0].formatted_address);

            });

        axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latValue + ',' + lngValue + '&language=en&region=SA&key=AIzaSyAh_LlRIucbMyXXMwIrTxw1uKLvmgAnJLk')
            .then((response) => {
                this.setState({
                    address: response.data.results[0].formatted_address
                });
                console.log('en:::'+response.data.results[0].formatted_address);
            });

    }

    onChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };


    handelAddressSubmit = (e) => {
        e.preventDefault();
        if (this.state.title === '') {
            this.setState({
                msgAr: "من فضلك سجل اسم عنوانك",
                msgEn: "Please write your address title",
                errorMsgModel: true
            });

        } else if (this.state.arAddress === '') {
            this.setState({
                msgAr: "من فضلك اختر موقعك",
                msgEn: "Please choose your location",
                errorMsgModel: true
            });

        } else {
            this.setState({
                disabled_Butt: false,
            });
            let values = {
                arAddress: this.state.arAddress,
                enAddress: this.state.address,
                latitude: this.state.position.lat,
                longitude: this.state.position.lng,
                title: this.state.title,
            };


            axios.post(window.$urlName + 'addresses', values, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.state.token,
                    'Content-Type': 'application/json',

                }
            }).then((response) => {
                localStorage.setItem("addressId", response.data.data.id);
                localStorage.setItem("addressLat", response.data.data.latitude);
                localStorage.setItem("addressLong", response.data.data.longitude);
                this.setState({
                    arAddress: response.data.data.arAddress,
                    address: response.data.data.enAddress
                })
                axios.get(window.$urlName + 'branches', {
                    params: {
                        type: 'nearby',
                        latitude: response.data.data.latitude,
                        longitude: response.data.data.longitude,
                    }
                }).then((response) => {
                    this.setState({
                        nearbyBranches: response.data.data,
                        redirect: true
                    });

                });


            }).catch(function (error) {
                // console.error(error);
            })
        }

    };

    render() {

        const {redirect} = this.state;
        if (redirect) {
            localStorage.setItem("nearbyBranches", JSON.stringify(this.state.nearbyBranches));
            localStorage.setItem("arAddress", this.state.arAddress);
            localStorage.setItem("enAddress", this.state.enAddress);
            return <Redirect to={{
                pathname: '/nearby-branches',
                // state: {branches: this.state.nearbyBranches,arAddress:this.state.arAddress,enAddress: this.state.address}
            }}
            />


        }
        /* Default position */
        const defaultPosition = {
            lat: this.state.lat, lng: this.state.lng
        };
        return (
            <div>
                <div className="form-group">
                    <label htmlFor=""><Translate content="title"/></label>
                    <input type="text" name="title" className="form-control" onChange={this.onChange}/>
                </div>
                <div className="form-group" id="addressMap">
                    <label htmlFor=""><Translate content="address"/></label>
                    <input type="text" name="address" className="form-control" onChange={this.onChange}
                           readOnly="readOnly" value={this.state.address}/>
                </div>
                <div>
                    <Autocomplete
                        style={{width: '100%', marginBottom: '20px'}}
                        onPlaceSelected={(place) => {
                            this.setState({
                                lat: place.geometry.location.lat(),
                                lng: place.geometry.location.lng(),
                                zoom: 13,
                            })
                        }}
                        types={['geocode']}
                        componentRestrictions={{country: ["sa", "bh"]}}
                        placeholder={localStorage.getItem("lang") === 'ar' ? "بحث" : "search"}
                        className="form-control"

                    />
                </div>
                <div>
                    <LocationPicker
                        containerElement={<div style={{height: '100%'}}/>}
                        mapElement={<div style={{height: '400px'}}/>}
                        defaultPosition={defaultPosition}
                        onChange={this.handleLocationChange}
                        zoom={this.state.zoom}
                    />
                </div>
                <button className="orangeButton" type="button" onClick={this.handelAddressSubmit}
                        disabled={!this.state.disabled_Butt}><Translate
                    content="confirm"/></button>
                <Modal isOpen={this.state.errorMsgModel} toggle={this.errorTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="sorry" component="p"/>

                            {translate.getLocale() === 'ar' ? <p> {this.state.msgAr}</p> :
                                <p>{this.state.msgEn}</p>}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lat: state.lat,
        lng: state.lng,
    }
};

export default connect(mapStateToProps, null)(LocationPickerMap)
