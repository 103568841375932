import React, {Component, Fragment} from "react"
import Translate from "react-translate-component";
import dateImg from "./component/assest/img/dateIcon.svg";
import timeImg from "./component/assest/img/timeIcon.svg";
import acceptOrder from "./component/assest/img/acceptOrder.svg";
import acceptG from "./component/assest/img/acceptG.svg";
import orderInProgress from "./component/assest/img/paymentIcon.svg";
import progreesG from "./component/assest/img/paymentIconG.svg";
import orderDriver from "./component/assest/img/orderInkitchen.svg";
import deliveryG from "./component/assest/img/progreesG.svg";
import deliverd from "./component/assest/img/orderDeliverd.svg";
import deliverdG from "./component/assest/img/deliverdG.svg";
import axios from "axios";
import {Redirect} from "react-router-dom"
import {BallBeat} from "react-pure-loaders";
import {Modal, ModalBody} from "reactstrap";
import TagManager from 'react-gtm-module'

let translate = require('counterpart');

const tagManagerArgs = {
    dataLayer: {
        transactionId: localStorage.getItem("orderId"),
        transactionTotal: parseFloat(localStorage.getItem("transactionTotal")),
    },
    dataLayerName: 'PageDataLayer'
}

class MudiafSingleOrder extends Component {
    constructor(props) {
        super(props);
        const token = localStorage.getItem("token");
        let loggedIn = true;

        if (token == null) {
            loggedIn = false
        }
        this.state = {
            token: localStorage.getItem("token"),
            orderId: window.location.href.split('/')[4],
            totalPrice: 0,
            redirect: false,
            cancel: true,
            dateImg: dateImg,
            timeImg: timeImg,
            img1: acceptOrder,
            img2: orderInProgress,
            img3: orderDriver,
            img4: deliverd,
            loading: true,
            deleteModel: false,
            originalPrice: 0,
            discount: 0,
            deliveryPrice: 0,
            now: false,
            later: false,
            date: "",
            time: "",
            paymentMethod: "",
            withDriver: true,
            inProgressState: true,
            showPaymentStatus: false,
            description: "",
            personsNumber: 0,
            address: "",
            mdiafMenuEnName: "",
            mdiafMenuArName: "",
            mdiafMenuImage: "",
            currAr: "ريال",
            currEn: "SAR",
            loggedIn,
        };


    }

    componentDidMount = () => {
        axios.get(window.$urlName + 'mdiaf-orders/' + this.state.orderId, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token,
                'Accept': 'application/json'
            }
        }).then((response) => {
            this.setState({
                date: response.data.data.date,
                time: response.data.data.time,
                totalPrice: response.data.data.totalPrice,
                description: response.data.data.description,
                paymentMethod: response.data.data.paymentMethod,
                personsNumber: response.data.data.personsNumber,
                address: response.data.data.address,
                mdiafMenuEnName: response.data.data.mdiafMenuEnName,
                mdiafMenuArName: response.data.data.mdiafMenuArName,
                mdiafMenuImage: response.data.data.mdiafMenuArName,
                loading: false,
            });

            if(this.state.paymentMethod === "tap"){
                this.setState({
                    showPaymentStatus: true,
                })
            }else{
                this.setState({
                    showPaymentStatus: false,
                })
            }
            if (response.data.data.statusId === 7) {
                document.getElementById("accept").style.color = "#39B54E";
                if(this.state.showPaymentStatus){
                    document.getElementById("kitchen").style.color = "#39B54E";
                }
                document.getElementById("delivery").style.color = "#707070";
                document.getElementById("done").style.color = "#707070";
                this.setState({
                    img1: acceptG,
                    img2: progreesG,
                    img3: orderDriver,
                    img4: deliverd,
                    cancel: true,
                })
            }
            else if (response.data.data.statusId === 6) {
                document.getElementById("accept").style.color = "#707070";
                if(this.state.showPaymentStatus){
                    document.getElementById("kitchen").style.color = "#39B54E";
                }
                document.getElementById("delivery").style.color = "#707070";
                document.getElementById("done").style.color = "#707070";
                document.getElementById("payAgain").style.display = "block";
                this.setState({
                    img1: acceptOrder,
                    img2: progreesG,
                    img3: orderDriver,
                    img4: deliverd,
                    cancel: true,
                })
            }
            else if (response.data.data.statusId === 8) {
                document.getElementById("accept").style.color = "#39B54E";
                if(this.state.showPaymentStatus){
                    document.getElementById("kitchen").style.color = "#39B54E";
                }
                document.getElementById("delivery").style.color = "#39B54E";
                document.getElementById("done").style.color = "#707070";
                this.setState({
                    img1: acceptG,
                    img2: progreesG,
                    img3: deliveryG,
                    img4: deliverd,
                    cancel: false,
                })
            }
            else if (response.data.data.statusId === 4) {
                document.getElementById("accept").style.color = "#39B54E";
                if(this.state.showPaymentStatus){
                    document.getElementById("kitchen").style.color = "#39B54E";
                }
                document.getElementById("delivery").style.color = "#39B54E";
                document.getElementById("done").style.color = "#39B54E";
                this.setState({
                    img1: acceptG,
                    img2: progreesG,
                    img3: deliveryG,
                    img4: deliverdG,
                    cancel: false,
                })
            }
            else {
                document.getElementById("accept").style.color = "#707070";
                if(this.state.showPaymentStatus){
                    document.getElementById("kitchen").style.color = "#707070";
                }
                document.getElementById("delivery").style.color = "#707070";
                document.getElementById("done").style.color = "#707070";
                document.getElementById("payAgain").style.display = "block";
                this.setState({
                    img1: acceptOrder,
                    img2: orderInProgress,
                    img3: orderDriver,
                    img4: deliverd,
                    cancel: true,
                })
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                window.location.pathname = "/sign-in"
            } else {
                window.location.pathname = "/404"
            }
        })


    };

    cancelOrder = () => {
        let type = {
            type: "canceled"
        };
        axios.put(window.$urlName + 'mdiaf-orders/' + this.state.orderId + '/cancel', type, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            this.setState({
                redirect: true
            });
        });

    };
    deleteTypeModel = () => {
        this.setState(prevState => ({
            deleteModel: !prevState.deleteModel,

        }));

    };
    payAgain = () => {
        axios.get(window.$urlName + 'mdiaf-orders/' + this.state.orderId + '/payment-url', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',

                }
            }
        ).then((response) => {
            window.location.href = response.data.data.url
            this.props.RESETAPP()


        }).catch(function (error) {
            // console.log(error.response)
        });

    };

    render() {
        TagManager.dataLayer(tagManagerArgs)

        if (this.state.loggedIn === false) {
            return <Redirect to="/sign-in"/>
        }
        const {redirect} = this.state;

        if (redirect) {
            return <Redirect to="/profile"/>
        }

        // let countDownDate = new Date(this.state.timeRemind * 1000).toISOString().substr(11, 8);
        return (
            <Fragment>

                <div className="container-fluid finalOrder">

                    <div className="row">
                        <div className="col-lg-8 col-md-8 processRightSide" style={{padding: "0px"}}>
                            <div className="loadingSec">

                                <BallBeat
                                    color={'#2C2C2C'}
                                    loading={this.state.loading}
                                />
                            </div>

                            <div className="totalPriceRow"
                                 style={{background: "#555555", margin: "0px", padding: "20px"}}>
                                <div className="row">
                                    <div className="col-lg-3 col-md-3">
                                        <Translate content="youChoose" component="p"/>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <p>{translate.getLocale() === 'ar' ?
                                            <span>{this.state.mdiafMenuArName}</span> :
                                            <span>{this.state.mdiafMenuEnName}</span>}</p>
                                    </div>

                                    <div className="col-lg-3 col-md-3">
                                        <Translate content="num_persons" component="p"/>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <p><span>{this.state.personsNumber}</span></p>
                                    </div>

                                </div>
                            </div>

                            <div className="rowStyle">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3">
                                        <img src={this.state.dateImg} alt="dateImg"/>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <span>{this.state.date}</span>
                                    </div>

                                    <div className="col-lg-3 col-md-3">
                                        <img src={this.state.timeImg} alt="timeImg"/>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <span>{this.state.time}</span>
                                    </div>

                                </div>
                            </div>

                            <hr/>

                            <div className="rowStyle rowStyle2">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <Translate content="order_id"/>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="arialFont"><span>{this.state.orderId}</span></p>
                                    </div>
                                </div>
                            </div>

                            <hr/>

                            <div className="rowStyle rowStyle2">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <Translate content="address"/>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p><span>{this.state.address}</span></p>
                                    </div>
                                </div>
                            </div>

                            <hr/>

                            <div className="rowStyle rowStyle2">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <Translate content="notes"/>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p><span>{this.state.description}</span></p>
                                    </div>
                                </div>
                            </div>

                            <hr/>

                            <div className="totalPriceRow" style={{margin: "0px", padding: "20px"}}>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <Translate content="total_cost" component="p"/>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p><span
                                            className="arialFont">{parseFloat(this.state.totalPrice).toFixed(1)}</span>{translate.getLocale() === 'ar' ?
                                            <span>{this.state.currAr}</span> :
                                            <span>{this.state.currEn}</span>}</p>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="col-lg-4 col-md-4 processLeftSide">
                            <div className="orderState">
                                <Translate content="order_state" component="h2"/>
                                <ul className="orderListState">
                                    {this.state.showPaymentStatus ?
                                        <li><img src={this.state.img2} alt="inProgress"/>
                                        <div className="squereList"></div>
                                        <Translate content="waitingPayment" id="kitchen"/></li> : null}

                                    <li><img src={this.state.img1} alt="accepted"/>
                                        <div className="squereList"></div>
                                        <Translate content="order_accepted" id="accept"/>
                                    </li>
                                    {this.state.withDriver ? <li><img src={this.state.img3} alt="inProgress"/>
                                        <div className="squereList"></div>
                                        <Translate content="order_InProgress" id="delivery"/>
                                    </li> : null}
                                    <li><img src={this.state.img4} alt="delivered"/>
                                        <div className="squereList"></div>
                                        <Translate content="delivered" id="done"/></li>
                                </ul>
                                <div className="row">
                                    {this.state.paymentMethod === "tap" ?
                                    <div className="col-lg-6 col-md-6">
                                        <button id="payAgain" className="payAgainButt" onClick={this.payAgain}
                                                style={{display: "none"}}><Translate content="pay_again"/></button>
                                    </div>: null }
                                    <div className="col-lg-6 col-md-6">
                                        {this.state.cancel ?
                                            <button type="button" className="cancelRequest" id="cancelRequest"
                                                    disabled={!this.state.cancel}
                                                    onClick={() => this.deleteTypeModel()}>
                                                <Translate content="cancel_request"/></button> : null}
                                    </div>
                                </div>

                                <button id="payAgain" className="payAgainButt" onClick={this.payAgain}
                                        style={{display: "none"}}><Translate content="pay_again"/></button>
                            </div>


                        </div>
                    </div>


                </div>
                <Modal isOpen={this.state.deleteModel} toggle={this.deleteTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="delete_modal" component="p"/>
                            <button type="button" className="confirmDelete" onClick={this.cancelOrder}><Translate
                                content="confirm"/></button>
                            <button type="button" className="cancelDelete" onClick={this.deleteTypeModel}><Translate
                                content="cancel"/></button>
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

export default MudiafSingleOrder