import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {Link} from 'react-router-dom';
import {Redirect} from 'react-router-dom';
import {BallBeat} from "react-pure-loaders";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import connect from "react-redux/es/connect/connect";
import {authounticateUser} from "./actions/cartActions";
import {Modal, ModalBody} from "reactstrap";
let translate = require('counterpart');

class ChangePhone extends Component {
    constructor(props) {
        super(props);
        let updated = false;

        this.state = {
            token: localStorage.getItem("token"),
            phone: "",
            loading: false,
            code: '',
            msgAr:"",
            msgEn:"",
            errorMsgModel: false,
            codeSend: false,
            updated
        };
    }
    errorTypeModel = () => {
        this.setState(prevState => ({
            errorMsgModel: !prevState.errorMsgModel,

        }));

    };
    handelChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };
    handelCode = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
        });

        let headers = {
            'Authorization': 'Bearer ' + this.state.token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        let data = {
            phoneVerificationCode:this.state.code,
            phone: this.state.phone,
        };

        axios.put(window.$urlName + 'customers/edit-phone', data, {headers
        }).then((response) => {
            if (response.data.message === "success") {
                axios.get(window.$urlName + 'customers/account-info', {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token,
                        'Accept': 'application/json'
                    }
                }).then((response) => {
                    localStorage.setItem("userName", response.data.data.name);
                    localStorage.setItem("userPhone", data.phone);
                    this.props.authounticateUser();
                    this.setState({
                        loading:false,
                        updated:true
                    })
                })

            } else {
                this.setState({
                    loading: false,
                    msgAr:"الكود غير صحيح",
                    msgEn:"Invalid code",
                    errorMsgModel:true
                });

            }
        }).catch(function (error) {
            this.setState({
                loading: false,
                msgAr:"الكود غير صحيح",
                msgEn:"Invalid code",
                errorMsgModel:true
            });
        }.bind(this))
    };




    render() {
        if (this.state.updated) {
            this.props.authounticateUser();
            return <Redirect to="/profile"/>
        }
        let phoneRegEx = /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;

        return (
            <Fragment>
                <div className="container">
                    <div className="signForm">
                        {this.state.codeSend ?  <div id="confirmCode">
                            <form onSubmit={this.handelCode}>
                                <div className="form-group">
                                    <label htmlFor="code"><Translate content="enter_code"/></label>
                                    <div className="loadingSec">
                                        <BallBeat
                                            color={'#2C2C2C'}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                    <Translate component="input" type="text" id="code" name="code"
                                               className="form-control" value={this.state.code}
                                               onChange={this.handelChange} attributes={{placeholder: 'code'}}
                                               required/>
                                </div>
                                <button type="submit" id="sign-in-button">
                                    <Translate content="confirm"/></button>
                            </form>
                        </div>
                            :null}
                        {!this.state.codeSend ?
                            <Formik
                            initialValues={{
                                phone: '',
                            }}
                            validationSchema={Yup.object().shape({
                                phone: Yup.string()
                                    .matches(phoneRegEx, <Translate content="phone_not_valid"/>)
                                    .required(<Translate content="phone_required"/>),

                            })}
                            onSubmit={values => {
                                this.setState({
                                    loading:true
                                })
                                axios.put(window.$urlName + 'customers', values, {
                                    headers: {
                                        'Authorization': 'Bearer ' + this.state.token,
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json'
                                    }
                                }).then((response) => {
                                    this.setState({
                                        phone: values.phone,
                                        loading:false,
                                        codeSend:true,
                                    })


                                }).catch(function (error) {
                                    if (error.response.status === 422) {
                                        this.setState({
                                            msgAr:"هذا الرقم مسجل من قبل",
                                            msgEn:"This phone is exist",
                                            errorMsgModel:true
                                        });

                                    } else {
                                        // console.log(error)
                                    }
                                }.bind(this));

                            }}

                            render={({errors, status, touched}) => (
                                  <Form>
                                    <Translate content="change_phone" component="h1"/>
                                    <div className="form-group">
                                        <label htmlFor="phone"><Translate content="enter_phone"/></label>
                                        <div className="loadingSec">
                                            <BallBeat
                                                color={'#2C2C2C'}
                                                loading={this.state.loading}
                                            />
                                        </div>
                                        <Field name="phone" id="phone" type="tel"
                                               className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')} />
                                        <ErrorMessage name="phone" component="div" className="invalid-feedback"/>
                                    </div>
                                    <button type="submit"><Translate content="confirm"/></button>
                                </Form>
                            )}
                        />
                            :null}
                        <Link to="sign-up" className="verBackLink"><Translate content="back"/></Link>

                    </div>


                </div>
                <Modal isOpen={this.state.errorMsgModel} toggle={this.errorTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="sorry" component="p"/>

                            {translate.getLocale() === 'ar' ? <p> {this.state.msgAr}</p> :
                                <p>{this.state.msgEn}</p>}
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authounticateUser: () => {
            dispatch(authounticateUser())
        },


    }
};
export default connect(null, mapDispatchToProps)(ChangePhone)



