import React, {Component, Fragment} from "react"
import Translate from "react-translate-component";
import acceptOrder from "./component/assest/img/acceptOrder.svg";
import acceptG from "./component/assest/img/acceptG.svg";
import orderInProgress from "./component/assest/img/orderInkitchen.svg";
import progreesG from "./component/assest/img/progreesG.svg";
import orderDriver from "./component/assest/img/orderWithDriver.svg";
import deliveryG from "./component/assest/img/deliveryG.svg";
import deliverd from "./component/assest/img/orderDeliverd.svg";
import deliverdG from "./component/assest/img/deliverdG.svg";
import axios from "axios";
import {Redirect} from "react-router-dom"
import {BallBeat} from "react-pure-loaders";
import {Modal, ModalBody} from "reactstrap";
import Rate from "./rating";
import ReactStars from "react-rating-stars-component";
import TagManager from 'react-gtm-module'
import masterCard from "./component/assest/img/master.png";
import visa from "./component/assest/img/visa.png";
import car from "./component/assest/img/car.png";

let translate = require('counterpart');
var Barcode = require('react-barcode');

const tagManagerArgs = {
    dataLayer: {
        transactionId: localStorage.getItem("orderId"),
        transactionTotal:parseFloat(localStorage.getItem("transactionTotal")),
    },
    dataLayerName: 'PageDataLayer'
}

class OrderProcess extends Component {
    constructor(props) {
        super(props);
        const token = localStorage.getItem("token");
        let loggedIn = true;

        if (token == null) {
            loggedIn = false
        }
        this.state = {
            token: localStorage.getItem("token"),
            orderId: window.location.href.split('/')[4],
            statusId: '',
            branchAr: '',
            branchEn: '',
            branchId: '',
            totalPrice: 0,
            currAr: '',
            currEn: '',
            createdAt: '',
            products: [],
            redirect: false,
            cancel: true,
            cancelReason: '',
            img1: acceptOrder,
            img2: orderInProgress,
            img3: orderDriver,
            img4: deliverd,
            loading: true,
            deleteModel: false,
            orderErrorMsgModel: false,
            carModel: false,
            changePayModel: false,
            allowChangePayment: true,
            walletPay: false,
            cashPay: false,
            cardPay: false,
            selectedPaymentOption: "",
            disabledPaymentSubmit: false,
            orderTypeAr: "",
            orderTypeEn: "",
            rate: false,
            branchPhone: "",
            driverPhone: "",
            originalPrice: 0,
            discount: 0,
            deliveryPrice: 0,
            tax: 0,
            taxValue: 0,
            timeRemind: 0,
            now: false,
            later: false,
            date: "",
            time: "",
            notes: "",
            carOption: "",
            carModelMsgAr: "",
            carModelMsgEn: "",
            carModelBtn: true,
            paymentMethod: "",
            withDriver: false,
            inProgressState: false,
            rateValue: 0,
            rateValueSec: false,
            latitude: 0,
            longitude: 0,
            loggedIn,
        };
    }

    async componentDidMount(){
        navigator.geolocation.getCurrentPosition((position) => {
            this.setState({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            })
        });

        await axios.get(window.$urlName + 'orders/' + this.state.orderId, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token,
                'Accept': 'application/json'
            }
        }).then((response) => {
            if (response.data.data.now === true) {
                if (response.data.data.remainingTimeInSeconds === 0 || response.data.data.remainingTimeInSeconds === null) {
                    this.setState({
                        now: false,
                    })
                } else {
                    this.setState({
                        now: true,
                        later: false
                    })
                    this.forceUpdate()
                    let d = new Date();
                    let remindMin = Math.floor(response.data.data.remainingTimeInSeconds / 60);
                    let remindSec = response.data.data.remainingTimeInSeconds - (remindMin * 60);
                    let sec = d.getSeconds() + remindSec;
                    let min = d.getMinutes() + remindMin;
                    d.setSeconds(sec);
                    d.setMinutes(min);
                    let countDownDate = d.getTime();
                    let x = setInterval(function () {
                        let now = new Date().getTime();
                        let distance = countDownDate - now;
                        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
                        if (document.getElementById("counter") !== null) {
                            document.getElementById("counter").innerHTML =
                                hours + " : " + minutes + " : " + seconds;
                            if (distance < 0) {
                                clearInterval(x);
                                document.getElementById("counter").innerHTML = "0"
                            }
                        } else {

                        }

                    }, 2000);

                }


            } else {
                this.setState({
                    now: false,
                    later: true,
                })
            }

            this.setState({
                timeRemind: response.data.data.remainingTimeInSeconds,
                date: response.data.data.date,
                time: response.data.data.time,
                branchAr: response.data.data.branchArName,
                branchEn: response.data.data.branchEnName,
                branchId: response.data.data.branchId,
                totalPrice: response.data.data.totalPrice,
                currAr: response.data.data.currencyAr,
                currEn: response.data.data.currencyEn,
                createdAt: response.data.data.createdAt,
                products: response.data.data.products,
                branchPhone: "tel:" + response.data.data.branchPhone,
                driverPhone: (response.data.data.driverPhone) ? "tel:" + response.data.data.driverPhone : "",
                originalPrice: response.data.data.originalPrice,
                discount: response.data.data.discount,
                deliveryPrice: response.data.data.deliveryPrice,
                tax: response.data.data.tax,
                taxValue: response.data.data.taxValue,
                typeId: response.data.data.typeId,
                notes: response.data.data.notes,
                paymentMethod: response.data.data.paymentMethod,
                statusId: response.data.data.statusId,
                carOption: response.data.data.carOption,

                loading: false,
            });

            if (response.data.data.typeId === 1) {
                this.setState({
                    orderTypeAr: "تستلم من الفرع",
                    orderTypeEn: "Branch Pickup",
                    withDriver: false,
                    inProgressState: true,
                })

                if (response.data.data.statusId === 1) {
                    document.getElementById("accept").style.color = "#39B54E";
                    document.getElementById("kitchen").style.color = "#707070";
                    document.getElementById("done").style.color = "#707070";
                    this.setState({
                        img1: acceptG,
                        img2: orderInProgress,
                        img4: deliverd,
                        cancel: true,
                    })

                } else if (response.data.data.statusId === 2) {
                    document.getElementById("accept").style.color = "#39B54E";
                    document.getElementById("kitchen").style.color = "#39B54E";
                    document.getElementById("done").style.color = "#707070";
                    this.setState({
                        img1: acceptG,
                        img2: progreesG,
                        img4: deliverd,
                        cancel: true,
                    })
                } else if (response.data.data.statusId === 3) {
                    document.getElementById("accept").style.color = "#39B54E";
                    document.getElementById("kitchen").style.color = "#39B54E";
                    document.getElementById("done").style.color = "#707070";
                    this.setState({
                        img1: acceptG,
                        img2: progreesG,
                        img4: deliverd,
                        cancel: true,
                    })
                } else if (response.data.data.statusId === 4) {
                    if (response.data.data.isRate === true) {
                        this.setState({
                            rate: false,
                            rateValue: response.data.data.rate,
                            rateValueSec: true,
                        })
                    } else {
                        this.setState({
                            rate: true,
                        })

                    }
                    document.getElementById("accept").style.color = "#39B54E";
                    document.getElementById("kitchen").style.color = "#39B54E";
                    document.getElementById("done").style.color = "#39B54E";
                    this.setState({
                        img1: acceptG,
                        img2: progreesG,
                        img4: deliverdG,
                        cancel: false,

                    })
                } else {
                    document.getElementById("accept").style.color = "#707070";
                    document.getElementById("kitchen").style.color = "#707070";
                    document.getElementById("done").style.color = "#707070";
                    document.getElementById("payAgain").style.display = "block";

                    this.setState({
                        img1: acceptOrder,
                        img2: orderInProgress,
                        img4: deliverd,
                        cancel: true,
                    })
                }
            }
            else if (response.data.data.typeId === 3) {
                this.setState({
                    orderTypeAr: "حملة شاركهم",
                    orderTypeEn: "Share them campaign",
                    withDriver: false,
                    inProgressState: false,
                })
                if (response.data.data.statusId === 1) {
                    document.getElementById("accept").style.color = "#39B54E";
                    document.getElementById("done").style.color = "#707070";
                    this.setState({
                        img1: acceptG,
                        img4: deliverd,
                        cancel: true,
                    })

                } else if (response.data.data.statusId === 2) {
                    document.getElementById("accept").style.color = "#707070";
                    document.getElementById("done").style.color = "#707070";
                    this.setState({
                        img1: acceptOrder,
                        img4: deliverd,
                        cancel: true,
                    })
                } else if (response.data.data.statusId === 3) {
                    document.getElementById("accept").style.color = "#707070";
                    document.getElementById("done").style.color = "#707070";
                    this.setState({
                        img1: acceptOrder,
                        img4: deliverd,
                        cancel: true,
                    })
                } else if (response.data.data.statusId === 4) {
                    if (response.data.data.isRate === true) {
                        this.setState({
                            rate: false,
                            rateValue: response.data.data.rate,
                            rateValueSec: true,
                        })
                    } else {
                        this.setState({
                            rate: true,
                        })

                    }
                    document.getElementById("accept").style.color = "#39B54E";
                    document.getElementById("done").style.color = "#39B54E";
                    this.setState({
                        img1: acceptG,
                        img4: deliverdG,
                        cancel: false,

                    })
                } else {
                    document.getElementById("accept").style.color = "#707070";
                    document.getElementById("done").style.color = "#707070";
                    document.getElementById("payAgain").style.display = "block";

                    this.setState({
                        img1: acceptOrder,
                        img4: deliverd,
                        cancel: true,

                    })
                }
            }
            else {
                this.setState({
                    orderTypeAr: "نجيك البيت",
                    orderTypeEn: "Home Delivery",
                    withDriver: true,
                    inProgressState: true,
                })
                if (response.data.data.statusId === 1) {
                    document.getElementById("accept").style.color = "#39B54E";
                    document.getElementById("kitchen").style.color = "#707070";
                    document.getElementById("delivery").style.color = "#707070";
                    document.getElementById("done").style.color = "#707070";
                    this.setState({
                        img1: acceptG,
                        img2: orderInProgress,
                        img3: orderDriver,
                        img4: deliverd,
                        cancel: true,

                    })

                } else if (response.data.data.statusId === 2) {
                    document.getElementById("accept").style.color = "#39B54E";
                    document.getElementById("kitchen").style.color = "#39B54E";
                    document.getElementById("delivery").style.color = "#707070";
                    document.getElementById("done").style.color = "#707070";
                    this.setState({
                        img1: acceptG,
                        img2: progreesG,
                        img3: orderDriver,
                        img4: deliverd,
                        cancel: true,
                    })
                } else if (response.data.data.statusId === 3) {
                    document.getElementById("accept").style.color = "#39B54E";
                    document.getElementById("kitchen").style.color = "#39B54E";
                    document.getElementById("delivery").style.color = "#39B54E";
                    document.getElementById("done").style.color = "#707070";
                    this.setState({
                        img1: acceptG,
                        img2: progreesG,
                        img3: deliveryG,
                        img4: deliverd,
                        cancel: true,

                    })
                } else if (response.data.data.statusId === 4) {
                    if (response.data.data.isRate === true) {
                        this.setState({
                            rate: false,
                            rateValue: response.data.data.rate,
                            rateValueSec: true,

                        })
                    } else {
                        this.setState({
                            rate: true,
                        })
                    }
                    document.getElementById("accept").style.color = "#39B54E";
                    document.getElementById("kitchen").style.color = "#39B54E";
                    document.getElementById("delivery").style.color = "#39B54E";
                    document.getElementById("done").style.color = "#39B54E";
                    this.setState({
                        img1: acceptG,
                        img2: progreesG,
                        img3: deliveryG,
                        img4: deliverdG,
                        cancel: false,

                    })
                } else {
                    document.getElementById("accept").style.color = "#707070";
                    document.getElementById("kitchen").style.color = "#707070";
                    document.getElementById("delivery").style.color = "#707070";
                    document.getElementById("done").style.color = "#707070";
                    document.getElementById("payAgain").style.display = "block";

                    this.setState({
                        img1: acceptOrder,
                        img2: orderInProgress,
                        img3: orderDriver,
                        img4: deliverd,
                        cancel: true,

                    })
                }
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                window.location.pathname = "/sign-in"
            } else {
                window.location.pathname = "/404"
            }
        })

        let carOptionPayment = 'false';
        if(this.state.carOption){
            carOptionPayment = 'true';
        }

        await axios.get(window.$urlName + 'infos/allowed-payment-methods', {
            headers: {
                'Accept': 'application/json'
            },
            params: {
                branchId: this.state.branchId,
                carOption: carOptionPayment,
                price: this.state.totalPrice
            }
            }).then((response) => {
            if(response.data.data.allowCash === true){
                if (this.state.totalPrice < 1000) {
                    this.setState({
                        cashPay: true,
                    });
                }
            }
            if(response.data.data.allowWallet === true){
                axios.get(window.$urlName + 'customers/wallet-balance', {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token,
                        'Accept': 'application/json'
                    },
                }).then((response) => {
                    if (response.data.data.balance > this.state.totalPrice) {
                        this.setState({
                            walletPay: true
                        })
                    }
                }).catch(function (error) {
                    // console.log(error.response)
                });
            }
            if(response.data.data.allowUrway === true){
                this.setState({
                    cardPay:true
                })
            }
            this.setState({
                loading:false
            })

        }).catch(function (error) {
            // console.log(error.response)

        });


        if(this.state.paymentMethod ==='urway' || this.state.paymentMethod ==='tap'){
            this.setState({
                cardPay:false
            })
        }

        if(!this.state.cashPay && !this.state.walletPay && !this.state.cardPay){
            this.setState({
                allowChangePayment:false
            })
        }
        else if(!this.state.cashPay && !this.state.walletPay && this.state.paymentMethod ==='urway' && this.state.paymentMethod ==='tap' || this.state.paymentMethod ==='cash' || this.state.paymentMethod ==='wallet'){
            this.setState({
                allowChangePayment:false
            })
        }

        if(this.state.statusId !== 6){
            this.setState({
                allowChangePayment:false
            })
        }

    };

    orderErrorTypeModel = () => {
        this.setState(prevState => ({
            orderErrorMsgModel: !prevState.orderErrorMsgModel,
        }));

        if(this.state.orderErrorMsgModel){
            this.setState({
                redirect: true,
                loading: false,
            });
        }
    };

    cancelOrder = () => {
        if(this.state.cancelReason === ''){
            if(translate.getLocale() === 'ar') {
                alert("برجاء اختار سبب لإلغاء الطلب");
            }else{
                alert("Please choose a reason for order cancellation");
            }
        }else{
            // let type = {
            //     type: "canceled"
            // };
            let message = {
                msg: "برجاء إلغاء الطلب رقم #"+this.state.orderId+" بسبب "+this.state.cancelReason
            };

            // axios.put(window.$urlName + 'orders/' + this.state.orderId, type, {
            //     headers: {
            //         'Authorization': 'Bearer ' + this.state.token,
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json',

            //     }
            // }).then((response) => {
            //     this.setState({
            //         deleteModel: false,
            //         loading: false,
            //     });
            // });

            axios.post(window.$urlName + 'chats', message, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }).then((response) => {
                this.setState({
                    ErrorMsgAr: "برجاء الإنتظار, جاري تنفيذ طلبك من قبل احد ممثلي خدمة العملاء",
                    ErrorMsgEn: "Please wait, your request is being processed by a customer service representative",
                    orderErrorMsgModel: true,
                    deleteModel: false,
                    loading: false,
                });
            });
        }


    };

    receiveOrder = () => {
        let values = {
            latitude: this.state.latitude,
            longitude: this.state.longitude
        };

        axios.put(window.$urlName + 'orders/' + this.state.orderId + '/add_latlng', values, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',

            }
        }).then((response) => {
            this.setState({
                carModel: true,
                carModelBtn: false,
                carModelMsgAr: "تم تحديد موقعك ، سيصل مندوبنا في اقرب وقت",
                carModelMsgEn: "Your location has been determined, our representative will arrive in the shortest time",
            });
        }).catch(function (error) {
            this.setState({
                carModel: true,
                carModelBtn: false,
                carModelMsgAr: "موقعك بعيد عن الفرع",
                carModelMsgEn: "Your location is far from the branch",
            });
        }.bind(this))

    };

    handelChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    handleOptionChange = (changeEvent) => {
        if(changeEvent.target.value === "reason1"){
            this.setState({
                cancelReason: "وقت التوصيل غير مناسب",
            })
        }else if(changeEvent.target.value === "reason2"){
            this.setState({
                cancelReason: "أرغب في تغيير الطلب",
            })
        }
        else if(changeEvent.target.value === "reason3"){
            this.setState({
                cancelReason: "لا أستطيع استلام الطلب",
            })
        }
        else{
            this.setState({
                selectedPaymentOption: changeEvent.target.value,
                disabledPaymentSubmit: true,
            })
        }
    };

    deleteTypeModel = () => {
        this.setState(prevState => ({
            deleteModel: !prevState.deleteModel,
        }));
    };

    carReceiveModel = () => {
        this.setState(prevState => ({
            carModel: !prevState.carModel,
            carModelBtn: true,
            carModelMsgAr: "هل انت قريب من الفرع الان؟",
            carModelMsgEn: "Are you close to the branch now?",
        }));
    };

    changePaymentModel = () => {
        this.setState(prevState => ({
            changePayModel: !prevState.changePayModel,
            selectedPaymentOption: "",
            disabledPaymentSubmit: false
        }));
    };

    changePaymentMethod = (e) => {
        e.preventDefault();
        this.setState({
            disabled: false,

        })

        if (this.state.token == null) {
            this.setState({
                selectedOption: "",
                loggedIn: false
            })
        } else {
            let headers = {
                'Authorization': 'Bearer ' + this.state.token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            };
            let data = {
                type: "paymentMethod",
                paymentMethod: this.state.selectedPaymentOption,
            };

            axios.put(window.$urlName + 'orders/'+ this.state.orderId, data, {
                headers
            }).then((response) => {
                if (response.data.message === "success") {
                    if(response.data.data !== ''){
                        window.location.href = response.data.data
                    }else{
                        window.location.reload();
                    }
                } else {
                    this.setState({
                        loading: false,
                        msgAr:"لا يمكن إتمام العملية",
                        msgEn:"The operation could not be completed",
                        errorMsgModel:true
                    });
                }

            }).catch(function (error) {
                this.setState({
                    loading: false,
                    msgAr:"الكود غير صحيح",
                    msgEn:"Invalid code",
                    errorMsgModel:true
                });
            }.bind(this))
        }


    };

    payAgain = () => {
        axios.get(window.$urlName + 'orders/' + this.state.orderId + '/payment-url', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }
        ).then((response) => {
            window.location.href = response.data.data.url
            this.props.RESETAPP()
        }).catch(function (error) {
            // console.log(error.response)
        });
    };

    render() {
        TagManager.dataLayer(tagManagerArgs)

        if (this.state.loggedIn === false) {
            return <Redirect to="/sign-in"/>
        }
        const {redirect} = this.state;

        if (redirect) {
            return <Redirect to="/profile"/>
        }

        let products = this.state.products.map((product, index) => {
            return (
                <div className="orderRow" key={index} id={product.productId}>
                    <div className="row">

                        <div className="col-lg-3 col-md-3" style={{padding: "0px"}}>
                            <div className="categoryImg" style={{backgroundImage: `url(${product.image})`}}>
                            </div>
                        </div>

                        <div className="col-lg-9 col-md-9">

                            <div className="orderTable">

                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <Translate content="name" component="th"/>
                                            <Translate content="quantity" component="th"/>
                                            <Translate content="price" component="th"/>
                                            <Translate content="size" component="th"/>
                                            <Translate content="calorie" component="th"/>
                                            <Translate content="additions" component="th"/>
                                            <Translate content="extras" component="th"/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            {translate.getLocale() === 'ar' ? <th>{product.productArName}</th> :
                                                <th>{product.productEnName}</th>}
                                            <th className="arialFont">{product.quantity}</th>
                                            <th className="arialFont">{(product.price / product.quantity).toFixed(2)}</th>
                                            {translate.getLocale() === 'ar' ? <th>{product.sizeArName}</th> :
                                                <th>{product.sizeEnName}</th>}
                                            <th className="arialFont">{product.calories}</th>

                                            {translate.getLocale() === 'ar' ?
                                                <th>{product.additionals.length !== 0 ? product.additionals.map((item) => item.additionalArName) : "-"}</th> :
                                                <th>{product.additionals.length !== 0 ? product.additionals.map((item) => item.additionalEnName) : "-"}</th>}

                                            {translate.getLocale() === 'ar' ?
                                                <th>{product.extras.length !== 0 ? product.extras.map((item) => item.arName + " ") : "-"}</th> :
                                                <th>{product.extras.length !== 0 ? product.extras.map((item) => item.enName + " ") : "-"}</th>}

                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            )


        });
        // Set the date we're counting down to

        // let countDownDate = new Date(this.state.timeRemind * 1000).toISOString().substr(11, 8);
        return (
            <Fragment>


                <div className="container-fluid finalOrder">

                    <div className="row">
                        <div className="col-lg-8 col-md-8 processRightSide">
                            <Translate content="order_details" component="h1" className="mealsTitle"/>

                            <div className="loadingSec">

                                <BallBeat
                                    color={'#2C2C2C'}
                                    loading={this.state.loading}
                                />
                            </div>
                            <div className="orderTable">
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <Translate content="order_id" component="th"/>
                                            <Translate content="order_received" component="th"/>
                                            <Translate content="order_type" component="th"/>
                                            <Translate content="branch_name" component="th"/>
                                            <Translate content="paymentMethod" component="th"/>

                                            <Translate content="notes" component="th"/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th className="arialFont">{this.state.orderId}</th>
                                            <th className="arialFont">{this.state.createdAt}</th>
                                            {translate.getLocale() === 'ar' ? <th>{this.state.orderTypeAr}</th> :
                                                <th>{this.state.orderTypeEn}</th>}
                                            {translate.getLocale() === 'ar' ? <th>{this.state.branchAr}</th> :
                                                <th>{this.state.branchEn}</th>}
                                            <th className="arialFont">{this.state.paymentMethod?this.state.paymentMethod:"-"}</th>

                                            <th>{this.state.notes?this.state.notes:"-"}</th>

                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <Translate content="meals" component="h1" className="mealsTitle"/>
                            {products}
                            <div className="totalPriceRow" style={{background: "#707070"}}>
                                <div className="row">
                                    <div className="col-lg-3 col-md-3">
                                        {/*<Translate content="branch_name" component="p"/>*/}
                                        <Translate content="price" component="p"/>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        {/*{translate.getLocale() === 'ar' ? <p>{this.state.branchAr}</p> :*/}
                                        {/*<p>{this.state.branchEn}</p>}*/}
                                        <p><span
                                            className="arialFont">{parseFloat(this.state.originalPrice).toFixed(1)}</span>{translate.getLocale() === 'ar' ?
                                            <span>{this.state.currAr}</span> :
                                            <span>{this.state.currEn}</span>}</p>
                                    </div>

                                    <div className="col-lg-3 col-md-3">
                                        <Translate content="delivery_price" component="p"/>
                                    </div>
                                    <div className="col-lg-3 col-md-3">

                                        <p><span
                                            className="arialFont">{this.state.deliveryPrice ? parseFloat(this.state.deliveryPrice).toFixed(1) : 0}</span>{translate.getLocale() === 'ar' ?
                                            <span>{this.state.currAr}</span> :
                                            <span>{this.state.currEn}</span>}</p>
                                    </div>

                                </div>
                            </div>
                            <div className="totalPriceRow">
                                <div className="row">
                                    <div className="col-lg-2 col-md-2">
                                        <Translate content="discount" component="p"/>
                                    </div>
                                    <div className="col-lg-2 col-md-2">

                                        <p><span
                                            className="arialFont">{parseFloat(this.state.discount).toFixed(1)}</span>{translate.getLocale() === 'ar' ?
                                            <span>{this.state.currAr}</span> :
                                            <span>{this.state.currEn}</span>}</p>

                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <p><Translate content="tax"/> ({this.state.tax} %)</p>
                                    </div>
                                    <div className="col-lg-2 col-md-2">

                                        <p><span
                                            className="arialFont">{this.state.taxValue}</span>{translate.getLocale() === 'ar' ?
                                            <span>{this.state.currAr}</span> :
                                            <span>{this.state.currEn}</span>}</p>

                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <Translate content="total_cost" component="p"/>
                                    </div>
                                    <div className="col-lg-2 col-md-2">

                                        <p><span
                                            className="arialFont">{parseFloat(this.state.totalPrice).toFixed(1)}</span>{translate.getLocale() === 'ar' ?
                                            <span>{this.state.currAr}</span> :
                                            <span>{this.state.currEn}</span>}</p>

                                    </div>

                                </div>
                            </div>


                        </div>
                        <div className="col-lg-4 col-md-4 processLeftSide">
                            {this.state.now ? <div className="estimateTime" id="estimateTime">
                                <Translate content="estimate_time" component="p"/>
                                <div className="timeDiv">
                                    <span id="counter" className="arialFont"></span>
                                    {/*<Translate content="min"/>*/}
                                </div>
                            </div> : null}
                            {this.state.later ? <div className="estimateTime">
                                <Translate content="estimate_time" component="p"/>
                                <div className="timeDiv">
                                    <span className="arialFont" style={{display: "block"}}>{this.state.date}</span>
                                    <span className="arialFont">{this.state.time}</span>

                                </div>
                            </div> : null}
                            <div className="orderState">
                                <Translate content="order_state" component="h2"/>
                                <ul className="orderListState">
                                    <li><img src={this.state.img1} alt="accepted"/>
                                        <div className="squereList"></div>
                                        <Translate content="order_accepted" id="accept"/>
                                    </li>
                                    {this.state.inProgressState ? <li><img src={this.state.img2} alt="inProgress"/>
                                        <div className="squereList"></div>
                                        <Translate content="order_InProgress" id="kitchen"/> {this.state.carOption && this.state.statusId === 2 ? <div className="carBtn" onClick={this.carReceiveModel}><img src={car} alt="car"/></div>:null}</li> : null}
                                    {this.state.withDriver ? <li><img src={this.state.img3} alt="withDriver"/>
                                        <div className="squereList"></div>
                                        <Translate content="order_with_driver" id="delivery"/>
                                    </li> : null}
                                    <li><img src={this.state.img4} alt="delivered"/>
                                        <div className="squereList"></div>
                                        <Translate content="delivered" id="done"/></li>
                                </ul>
                                <div className="row">
                                    {this.state.driverPhone ?
                                    <div className="col-lg-6 col-md-6">

                                        <button type="button" className="callDerive">
                                            <a href={this.state.driverPhone} className="Blondie">
                                                <Translate content="call_driver"/>
                                            </a>
                                        </button>

                                    </div>
                                        : null}
                                    {this.state.cancel ?
                                    <div className="col-lg-6 col-md-6">

                                            <button type="button" className="cancelRequest" id="cancelRequest"
                                                    disabled={!this.state.cancel}
                                                    onClick={() => this.deleteTypeModel()}>
                                                <Translate content="cancel_request"/></button>
                                    </div>
                                        : null}

                                    {this.state.statusId === 6 ?
                                        <div className="col-lg-6 col-md-6">
                                            <button id="payAgain" className="payAgainButt" onClick={this.payAgain}
                                                    style={{display: "none"}}><Translate content="pay_again"/></button>
                                        </div>
                                        : null}

                                    {this.state.allowChangePayment ?
                                        <div className="col-lg-12 col-md-12" style={{textAlign: "center"}}>
                                            <button id="payAgain" className="payAgainButt changePayBtn" onClick={this.changePaymentModel}><Translate content="changePaymentMethod"/></button>
                                        </div>
                                        : null}
                                </div>

                                {this.state.rate ? <Rate orderId={this.state.orderId}/> : null}
                                {this.state.rateValueSec ? <div className="rateBody">
                                    <Translate content="your_rate" component="p"/>
                                    <ReactStars
                                        value={this.state.rateValue}
                                        count={5}
                                        size={25}
                                        edit={false}
                                        half={false}
                                        color2={'#ffd700'}
                                        className={"rateDiv"}/>
                                </div> : null}


                                <hr/>
                                <Translate content="order_scan" component="h2"/>
                                <div className="QrBody">
                                    <Barcode value={this.state.orderId.toString()} />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <Modal isOpen={this.state.orderErrorMsgModel} toggle={this.orderErrorTypeModel}
                       className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="sorry" component="p"/>

                            {translate.getLocale() === 'ar' ?
                                <p> {this.state.ErrorMsgAr}</p> :
                                <p>{this.state.ErrorMsgEn}</p>}

                            <button type="button" className="cancelDelete" onClick={this.orderErrorTypeModel}><Translate
                                content="cancel"/></button>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.deleteModel} toggle={this.deleteTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="delete_modal" component="p"/>
                            <div className="form-group">
                                <div className="radio">
                                    <input type="radio" value="reason1" name="cancelReason" id="reason1" onChange={this.handleOptionChange}/>
                                    <label htmlFor="reason1"><Translate
                                        content="cancelReason1"/></label>
                                </div>
                                <div className="radio">
                                    <input type="radio" value="reason2" name="cancelReason" id="reason2" onChange={this.handleOptionChange}/>
                                    <label htmlFor="reason2"><Translate
                                        content="cancelReason2"/></label>
                                </div>
                                <div className="radio">
                                    <input type="radio" value="reason3" name="cancelReason" id="reason3" onChange={this.handleOptionChange}/>
                                    <label htmlFor="reason3"><Translate
                                        content="cancelReason3"/></label>
                                </div>
                            </div>

                            <button type="button" className="confirmDelete" onClick={this.cancelOrder}><Translate
                                content="confirm"/></button>
                            <button type="button" className="cancelDelete" onClick={this.deleteTypeModel}><Translate
                                content="cancel"/></button>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.carModel} toggle={this.carReceiveModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <div className="form-group">
                                <p>{translate.getLocale() === 'ar' ? this.state.carModelMsgAr : this.state.carModelMsgEn }</p>
                            </div>
                            {this.state.carModelBtn ? <button type="button" className="confirmDelete" onClick={this.receiveOrder}><Translate
                                content="confirm"/></button> : null}

                            <button type="button" className="cancelDelete" onClick={this.carReceiveModel}><Translate
                                content="cancel"/></button>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.changePayModel} toggle={this.changePaymentModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="changePaymentMethod" component="p"/>
                            <form onSubmit={this.changePaymentMethod}>
                                <div className="paymentBody" id="paymentBody">
                                    {this.state.loading ? <div className="loadingSec">
                                            <BallBeat
                                                color={'white'}
                                                loading={this.state.loading}
                                            />
                                        </div> :

                                        <div className="row paymentRadio">
                                            {this.state.walletPay ?
                                                <div className="radio">
                                                    <input type="radio" value="wallet" name="payment" id="wallet"
                                                           onChange={this.handleOptionChange} required/>
                                                    <label htmlFor="wallet"><Translate
                                                        content="wallet_payment"/></label>
                                                </div> : null}
                                            {this.state.cashPay ?
                                                <div className="radio">
                                                    <input type="radio" value="cash" name="payment" id="cash"
                                                           onChange={this.handleOptionChange}/>
                                                    <label htmlFor="cash"> <Translate content="arrivalPayment"/>
                                                    </label>
                                                </div>
                                                : null}
                                            {this.state.cardPay ?
                                                <div className="radio">

                                                    <input type="radio" value="tap" name="payment" id="tap"
                                                           onChange={this.handleOptionChange}/>
                                                    <label htmlFor="tap"><Translate
                                                        content="creditCardPayment"/></label>
                                                    <div className="creditType">
                                                        <img src={masterCard} alt="masterCard"/>
                                                        <img src={visa} alt="visa"/>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    }
                                </div>
                                <div className="paymentButton">
                                    <button type="submit" disabled={!this.state.disabledPaymentSubmit}>
                                        <Translate
                                            content="confirm"/></button>
                                    <button type="button" className="cancelDelete" onClick={this.changePaymentModel}><Translate
                                        content="cancel"/></button>

                                </div>
                            </form>

                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

export default OrderProcess