import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {Link} from 'react-router-dom';
import {Redirect} from 'react-router-dom';
import {BallBeat} from "react-pure-loaders";
import connect from "react-redux/es/connect/connect";
import {authounticateUser} from "./actions/cartActions";
import {Modal, ModalBody} from "reactstrap";
import masterCard from "./component/assest/img/master.png";
import visa from "./component/assest/img/visa.png";
let translate = require('counterpart');

class chargeWallet extends Component {
    constructor(props) {
        super(props);
        let loggedIn = false;

        this.state = {
            token: localStorage.getItem("token"),
            loading: false,
            balance: '',
            maxChargeWallet:'',
            paymentMethod:'tap',
            msgAr:"",
            msgEn:"",
            errorMsgModel: false,
            loggedIn
        };

        axios.get(window.$urlName + 'customers/wallet-balance', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token,
                'Accept': 'application/json'
            }
        }).then((response) => {
            this.setState({
                maxChargeWallet: response.data.data.maxChargeWallet,
                loading: false
            })

        }).catch(function (error) {
            if (error.response.status === 403) {
                window.location.pathname = "/verification-code"
            } else {
                window.location.pathname = "/sign-in"
            }
        });
    }

    errorTypeModel = () => {
        this.setState(prevState => ({
            errorMsgModel: !prevState.errorMsgModel,

        }));
    };

    handelChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    handelCharge = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
        });

        if(this.state.balance > this.state.maxChargeWallet){
            this.setState({
                loading: false,
                msgAr:"الحد الاقصي لشحن المحفظة "+this.state.maxChargeWallet,
                msgEn:"Max Amount for charging wallet "+this.state.maxChargeWallet,
                errorMsgModel:true
            });
        }else{
            let headers = {
                'Authorization': 'Bearer ' + this.state.token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            };
            let data = {
                amount: this.state.balance,
                paymentMethod: this.state.paymentMethod,
                platform: "web",
            };

            axios.post(window.$urlName + 'wallets', data, {
                headers
            }).then((response) => {
                if (response.data.message === "success") {
                    window.location.href = response.data.data.url
                } else {
                    this.setState({
                        loading: false,
                        msgAr:"لا يمكن إتمام العملية",
                        msgEn:"The operation could not be completed",
                        errorMsgModel:true
                    });
                }

            }).catch(function (error) {
                this.setState({
                    loading: false,
                    msgAr:"حدث خطأ, لا يمكن إتمام العملية",
                    msgEn:"Something wrong, The operation could not be completed",
                    errorMsgModel:true
                });
            }.bind(this))
        }
    };

    render() {
        if (this.state.loggedIn) {
            this.props.authounticateUser();
            return <Redirect to="/"/>
        }

        return (
            <Fragment>
                <div className="container">
                    <form onSubmit={this.handelCharge}>
                        <div className="signForm chargeForm">
                            <div id="confirmCode">
                                <div className="form-group">
                                    <label htmlFor="balance"><Translate content="chargeWallet"/></label>
                                    <div className="loadingSec">
                                        <BallBeat
                                            color={'#2C2C2C'}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                    <Translate component="input" type="number" id="balance" name="balance"
                                               className="form-control" value={this.state.balance}
                                               max={this.state.maxChargeWallet}
                                               onChange={this.handelChange} attributes={{placeholder: 'enterBalance'}}
                                               required/>

                                    <div className="row paymentRadio">
                                        <div className="radio">
                                            <input type="radio" value="tap" name="paymentMethod" id="paymentMethod" onChange={this.handelChange} checked/>
                                            <label htmlFor="tap"><Translate
                                                content="creditCardPayment"/></label>
                                            <div className="creditType">
                                                <img src={masterCard} alt="masterCard"/>
                                                <img src={visa} alt="visa"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" id="sign-in-button" className="orangeButton">
                            <Translate content="confirm"/></button>
                        <Link to="profile" className="verBackLink chargeBackLink"><Translate content="back"/></Link>
                    </form>

                </div>
                <Modal isOpen={this.state.errorMsgModel} toggle={this.errorTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="sorry" component="p"/>

                            {translate.getLocale() === 'ar' ? <p> {this.state.msgAr}</p> :
                                <p>{this.state.msgEn}</p>}
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authounticateUser: () => {
            dispatch(authounticateUser())
        },


    }
};

export default connect(null, mapDispatchToProps)(chargeWallet)



