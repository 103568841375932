import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {Link} from 'react-router-dom';
import {Redirect} from 'react-router-dom';
import {BallBeat} from "react-pure-loaders";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import connect from "react-redux/es/connect/connect";
import {authounticateUser} from "./actions/cartActions";
import {Modal, ModalBody} from "reactstrap";
let translate = require('counterpart');

class VerificationCode extends Component {
    constructor(props) {
        super(props);
        let loggedIn = false;

        this.state = {
            token: localStorage.getItem("token"),
            loading: false,
            code: '',
            msgAr:"",
            msgEn:"",
            errorMsgModel: false,
            loggedIn
        };
    }
    errorTypeModel = () => {
        this.setState(prevState => ({
            errorMsgModel: !prevState.errorMsgModel,

        }));

    };
    handelChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };
    handelCode = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
        });
        let data = {
            phoneVerificationCode: this.state.code,
        };
        let headers = {
            'Authorization': 'Bearer ' + this.state.token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.post(window.$urlName + 'customers/verify-user', data, {headers}).then((response) => {
            if (response.data.message === "success") {
                localStorage.setItem("token", this.state.token);
                axios.get(window.$urlName + 'customers/account-info', {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token,
                        'Accept': 'application/json'
                    }
                }).then((response) => {
                    localStorage.setItem("userName",response.data.data.name)
                    this.setState({
                        loggedIn: true,
                        loading: false
                    })
                })


            } else {
                this.setState({
                    loading: false,
                    msgAr:"الكود غير صحيح",
                    msgEn:"Invalid code",
                    errorMsgModel:true
                });
            }


        }).catch(function (error) {
            this.setState({
                loading: false,
                msgAr:"الكود غير صحيح",
                msgEn:"Invalid code",
                errorMsgModel:true
            });
        }.bind(this))
    };


    resend = () => {
        let headers = {
            'Authorization': 'Bearer ' + this.state.token,
            'Accept': 'application/json'
        };
        axios.get(window.$urlName + 'customers/phone-verification-SMS-send', {headers}).then((response) => {
            this.setState({
                msgAr:"تم إرسال الكود",
                msgEn:"Your code has been send",
                errorMsgModel:true
            });
        }).catch(function (error) {
            // console.error(error);
        });
    };

    phoneForm = () => {
        document.getElementById("confirmCode").style.display = "none";
        document.getElementById("changePhone").style.display = "block"
    }

    render() {
        if (this.state.loggedIn) {
            this.props.authounticateUser();
            return <Redirect to="/"/>
        }
        let phoneRegEx = /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;

        return (
            <Fragment>
                <div className="container">
                    <div className="signForm">

                        <div id="confirmCode">
                            <form onSubmit={this.handelCode}>
                                <div className="form-group">
                                    <label htmlFor="code"><Translate content="enter_code"/></label>
                                    <div className="loadingSec">
                                        <BallBeat
                                            color={'#2C2C2C'}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                    <Translate component="input" type="text" id="code" name="code"
                                               className="form-control" value={this.state.code}
                                               onChange={this.handelChange} attributes={{placeholder: 'code'}}
                                               required/>
                                </div>

                                <div className="resend"><Translate component="p" content="didnt_Arrive"/> <Translate
                                    content="resend" onClick={this.resend}/><span>/</span> <Link to="/change-phone"> <Translate content="change_phone"/></Link></div>
                                <button type="submit" id="sign-in-button">
                                    <Translate content="confirm"/></button>
                            </form>
                        </div>


                        <Formik
                            initialValues={{
                                phone: '',
                            }}
                            validationSchema={Yup.object().shape({
                                phone: Yup.string()
                                    .matches(phoneRegEx, <Translate content="phone_not_valid"/>)
                                    .required(<Translate content="phone_required"/>),

                            })}
                            onSubmit={values => {
                                axios.put(window.$urlName + 'customers', values, {
                                    headers: {
                                        'Authorization': 'Bearer ' + this.state.token,
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json'
                                    }
                                }).then((response) => {
                                    let headers = {
                                        'Authorization': 'Bearer ' + this.state.token,
                                        'Accept': 'application/json'
                                    };
                                    axios.get(window.$urlName + 'customers/phone-verification-SMS-send', {headers}).then((response) => {
                                        // console.log(response.data)
                                        document.getElementById("confirmCode").style.display = "block"
                                        document.getElementById("changePhone").style.display = "none"
                                    }).catch(function (error) {
                                        // console.error(error);
                                    });

                                }).catch(function (error) {
                                    alert(error.response.data.error)
                                    // console.error(error);
                                });

                            }}

                            render={({errors, status, touched}) => (
                                <Form id="changePhone" style={{display: 'none'}}>
                                    <Translate content="change_phone" component="h1"/>
                                    <div className="form-group">
                                        <label htmlFor="phone"><Translate content="enter_phone"/></label>
                                        <Field name="phone" id="phone" type="tel"
                                               className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')} />
                                        <ErrorMessage name="phone" component="div" className="invalid-feedback"/>
                                    </div>

                                    <button type="submit"><Translate content="confirm"/></button>
                                </Form>
                            )}
                        />
                        <Link to="sign-up" className="verBackLink"><Translate content="back"/></Link>

                    </div>


                </div>
                <Modal isOpen={this.state.errorMsgModel} toggle={this.errorTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="sorry" component="p"/>

                            {translate.getLocale() === 'ar' ? <p> {this.state.msgAr}</p> :
                                <p>{this.state.msgEn}</p>}
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authounticateUser: () => {
            dispatch(authounticateUser())
        },


    }
};
export default connect(null, mapDispatchToProps)(VerificationCode)



