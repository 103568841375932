
//Types should be in const to avoid typos and duplication since it's a string and could be easily miss spelled
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SUB_QUANTITY = 'SUB_QUANTITY';
export const ADD_QUANTITY = 'ADD_QUANTITY';
export const RESET_APP = 'RESET_APP';
export const CLEAR_PROMO = 'CLEAR_PROMO';
export const SET_PROMO = 'SET_PROMO';
export const AUTHENTICATE_THE_USER = 'AUTHENTICATE_THE_USER';
export const SET_DELIVERY = 'SET_DELIVERY';
export const SET_LOCATION = 'SET_LOCATION';
