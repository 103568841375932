import React, {Component, Fragment} from "react"
import Translate from "react-translate-component";
import {Modal, ModalBody} from 'reactstrap';
import axios from "axios";
import {connect} from 'react-redux';
import {addToCart} from './actions/cartActions'
import Cart from "./cart";
import {BallBeat} from "react-pure-loaders";
import Swiper from "react-id-swiper";
import {RESETAPP} from "./actions/cartActions";
import {deliveryPrice} from "./actions/cartActions";
import Sticky from 'react-sticky-el';
import Search from "./search";
import RecommendedProducts from "./recommededProducts";
import {Redirect} from 'react-router-dom';

let translate = require('counterpart');

class FoodList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            products: JSON.parse(localStorage.getItem("products") || "[]"),
            categoryMessageShow: localStorage.getItem("categoryMessageShow"),
            categoryMessageEn: localStorage.getItem("categoryMessageEn"),
            categoryMessageAr: localStorage.getItem("categoryMessageAr"),
            recommendedProducts: JSON.parse(localStorage.getItem("recommendedProducts") || "[]"),
            categories: JSON.parse(localStorage.getItem("categories") || "[]"),
            branchId: parseInt(localStorage.getItem("branchId")),
            branchAr: localStorage.getItem("branchAr"),
            branchEn: localStorage.getItem("branchEn"),
            carOption: localStorage.getItem("carOption"),
            branches: JSON.parse(localStorage.getItem("branches") || "[]"),
            branchcategories: [],
            showActionFilterList: false,
            // branchModal: false,
            // categoryModal: false,
            selectedOption: "",
            selectedOptionAddition: "",
            arName: "",
            enName: "",
            modal: false,
            sizes: [],
            sizeId: "",
            sizeAr: "",
            sizeEn: "",
            productId: null,
            additionId: null,
            sizePrice: 0,
            totalAddPrice: null,
            additionsPrice: [],
            totalPrice: null,
            quantity: 1,
            newQuantity: this.quantity,
            max: 5,
            min: 1,
            key: '1',
            selectedProducts: [],
            selectedAdditionals: [],
            selectedExtras: [],
            additionals: [],
            extras: [],
            singleProduct: '',
            loading: true,
            noOrderAr: '',
            noOrderEn: '',
            redirect: false,
            deliveryTypeAr: "",
            deliveryTypeEn: "",
            disable_button: false,
            calories: "0",
            requiredAdd: null,
            clearBasketModel: false,
            addArName: "",
            addEnName: "",
            extrasArName: [],
            extrasEnName: [],
            banners: [],
            checked: false,
            catArName: localStorage.getItem("catArName"),
            catEnName: localStorage.getItem("catEnName"),
            loadingSwip: true,
            redirectBack: false

            // fastDelivery: 0,
            // ordinaryDelivery: 0,
            // deliveryPrice: 0,

        };
    }

    componentDidMount() {
        axios.get(window.$urlName + 'banners', {
            params: {
                type: 'website',
            }
        }).then((response) => {
            this.setState({
                banners: response.data.data,
                loadingSwip: false
            });
        });

        if (localStorage.getItem("branches") !== null && localStorage.getItem("categories") !== null) {
            localStorage.setItem("menuRoute", "true")
        }

        if (localStorage.getItem("typeId") === '1') {
            this.setState({
                deliveryTypeAr: "تستلم من الفرع",
                deliveryTypeEn: "Branch Pickup",
            })
        }
        else if (localStorage.getItem("typeId") === '3') {
            this.setState({
                deliveryTypeAr: "حملة شاركهم",
                deliveryTypeEn: "Share them campaign",
            })
        }
        else {
            this.setState({
                deliveryTypeAr: "نجيك البيت",
                deliveryTypeEn: "Home Delivery",
            })
        }

    }

    //
    // branchTypeModal = () => {
    //     this.setState(prevState => ({
    //         branchModal: !prevState.branchModal,
    //
    //     }));
    // };


    clearTypeModel = () => {
        this.setState(prevState => ({
            clearBasketModel: !prevState.clearBasketModel,

        }));

    };

    checkBasket = () => {
        if (JSON.parse(localStorage.getItem("reducer") || "[]").length !== 0) {
            this.clearTypeModel();
        } else {
            this.setState({
                redirectBack: true
            })
        }


    };
    // categoryTypeModel = () => {
    //     this.setState(prevState => ({
    //         categoryModal: !prevState.categoryModal,
    //         branchModal: !prevState.branchModal,
    //
    //
    //     }));
    // };
    clearBasket = () => {
        // this.branchTypeModal();
        this.props.RESETAPP()
        this.setState({
            redirectBack: true
        })

    }

    getProducts = (id, catArName, catEnName) => {
        axios.get(window.$urlName + 'products', {
            params: {
                categoryId: id,
                branchId: this.state.branchId,
                categoryMessage: 'true',
                categoryRecommended: 'true'
            }
        }).then((response) => {
            this.setState({
                products: response.data.data.products,
                catArName: catArName,
                catEnName: catEnName,
                categoryMessageShow: response.data.data.popupShow,
                categoryMessageEn: response.data.data.popupMessageEn,
                categoryMessageAr: response.data.data.popupMessageAr,
                recommendedProducts: response.data.data.recommended,
                key: id.toString(),
            })

        });
    };


    // handleBranchOptionChange = (id, BranchAr, BranchEn, arCurrency, enCurrency, branchTimeFrom, branchTimeTo, lat, lng, e) => {
    //     if (parseInt(localStorage.getItem("branchId")) !== id) {
    //         this.props.RESETAPP()
    //     }
    //     localStorage.setItem("branchId", id);
    //     localStorage.setItem("arCurrency", arCurrency);
    //     localStorage.setItem("enCurrency", enCurrency);
    //
    //     axios.get(window.$urlName + 'categories').then((response) => {
    //
    //         this.setState({
    //             branchId: id,
    //             branchAr: BranchAr,
    //             branchEn: BranchEn,
    //             branchcategories: response.data.data,
    //             categories: response.data.data,
    //             loading: false,
    //
    //         });
    //         localStorage.setItem("categories", JSON.stringify(response.data.data));
    //         localStorage.setItem("branchAr", BranchAr);
    //         localStorage.setItem("branchEn", BranchEn);
    //         this.categoryTypeModel();
    //     });
    //
    // };
    // handleCategoryOptionChange = (e, catArName, catEnName) => {
    //     this.setState({
    //         categoryId: e.target.value,
    //         catArName: catArName,
    //         catEnName: catEnName,
    //     });
    // };
    // handelCategorySubmit = (e) => {
    //     e.preventDefault();
    //     let typeId = parseInt(localStorage.getItem("typeId"));
    //     if (typeId === 2) {
    //         axios.get(window.$urlName + 'products', {
    //             params: {
    //                 categoryId: this.state.categoryId,
    //                 branchId: this.state.branchId,
    //                 type: "delivery",
    //             }
    //         }).then((response) => {
    //
    //             this.setState({
    //                 products: response.data.data,
    //             });
    //
    //             this.categoryTypeModel();
    //             this.branchTypeModal();
    //
    //         });
    //     } else {
    //
    //         axios.get(window.$urlName + 'products', {
    //             params: {
    //                 categoryId: this.state.categoryId,
    //                 branchId: this.state.branchId,
    //             }
    //         }).then((response) => {
    //
    //             this.setState({
    //                 products: response.data.data,
    //
    //             });
    //
    //             this.categoryTypeModel();
    //             this.branchTypeModal();
    //         });
    //
    //     }
    //
    //
    // };


    render() {
        const {redirectBack} = this.state;

        if (redirectBack) {
            return <Redirect to={{
                pathname: '/nearby-branches',
            }}
            />
        }

        // let branchcategories = this.state.branchcategories.map((category) => {
        //     return (
        //         <div className="countrySelect" key={category.id}>
        //             <input type="radio" name="cat" value={category.id} id={category.enName}
        //                    onChange={(e) => this.handleCategoryOptionChange(e, category.arName, category.enName)}
        //                    required/>
        //             {translate.getLocale() === 'ar' ? <label htmlFor={category.enName}>{category.arName}</label> :
        //                 <label htmlFor={category.enName}>{category.enName}</label>}
        //         </div>
        //     )
        // });
        //
        // let branches = this.state.branches.map((branch) => {
        //     return (
        //         <div className="countrySelect" key={branch.id}>
        //             <input type="radio" name="branch" value={branch.id} id={branch.enName} disabled={!branch.open}
        //                    onChange={(e) => this.handleBranchOptionChange(branch.id, branch.arName, branch.enName, branch.arCurrency, branch.enCurrency, branch.workingFrom, branch.workingTo, branch.latitude, branch.longitude, e)}/>
        //             {translate.getLocale() === 'ar' ? <label htmlFor={branch.enName}>{branch.arName}</label> :
        //                 <label htmlFor={branch.enName}>{branch.enName}</label>}
        //             {branch.open === true ? <span style={{color: 'green'}}><i className="fa fa-circle"/></span> :
        //                 <span style={{color: 'red'}}><i className="fa fa-circle"/></span>}
        //
        //
        //         </div>
        //     )
        // });


        let categories = this.state.categories.map((category) => {
            return (
                <div key={category.id}>
                    <div id={category.id}
                         onClick={() => this.getProducts(category.id, category.arName, category.enName)}>
                        <div className="foodIcon">
                            <div className="iconGround">
                                <img src={category.image} alt="meat"/>
                            </div>
                            {translate.getLocale() === 'ar' ? <p>{category.arName}</p> : <p>{category.enName}</p>}

                        </div>
                    </div>
                </div>
            )
        });

        const homeParamsSwiper = {
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            spaceBetween: 30,
            autoplay: {
                delay: 4000,
            },
        };

        const categorySwiper = {
            // slidesPerView: 6,
            spaceBetween: 30,
            // shouldSwiperUpdate:true,
            rebuildOnUpdate: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                1024: {
                    slidesPerView: 6,
                },
                768: {
                    slidesPerView: 5,
                },
                640: {
                    slidesPerView: 4,
                },
                320: {
                    slidesPerView: 3,
                }
            },
            // autoplay: {
            //     delay: 3000,
            // },
            // loop: true,
            // loopFillGroupWithBlank: true,
        };


        let banners = this.state.banners.map((banner, index) => {
                return (
                    <div className="homeSlide" style={{backgroundImage: `url(${banner.image})`}} key={index}></div>
                )
            });

        return (
            <Fragment>
                <div className="homeSwiper">
                    {this.state.banners.length !== 0 ? (
                        <Swiper {...homeParamsSwiper}>
                            {banners}
                        </Swiper>
                    ) : <div className="loadingSec" style={{marginTop: "200px", marginBottom: "200px"}}>
                        <BallBeat
                            color={'white'}
                            loading={this.state.loadingSwip}
                        />
                    </div>}

                    <div className="container-fluid productCategory">

                        <div className="container">

                            <div className="row">
                                {this.state.categories.length !== 0 ? (
                                    <Swiper {...categorySwiper} activeSlideKey={this.state.key}>
                                        {categories}
                                    </Swiper>
                                ) : null}

                            </div>
                        </div>
                    </div>

                </div>

                <div className="container-fluid">
                    <Sticky stickyClassName="stickyFilter">
                        <div className="row mapFilterDiv">
                            <div className="container-fluid filterNav">
                                <div className="container">

                                    <Translate content="you_choose" component="p"/><span>>></span>
                                    {translate.getLocale() === 'ar' ? <span>{this.state.branchAr}</span> :
                                        <span>{this.state.branchEn}</span>}
                                    <Translate content="change_branch" component="button"
                                               onClick={() => {
                                                   this.checkBasket();
                                               }}/>
                                    <span>/</span>
                                    <Translate content="delivery_type" component="p"/> <span>>></span>
                                    {translate.getLocale() === 'ar' ? <span>{this.state.deliveryTypeAr}</span> :
                                        <span>{this.state.deliveryTypeEn}</span>}


                                </div>
                            </div>
                        </div>
                    </Sticky>
                    <div className="row listBody">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9 col-md-9">
                                    {translate.getLocale() === 'ar' ?
                                        <p className="categoryTitle">{this.state.catArName}</p> :
                                        <p className="categoryTitle">{this.state.catEnName}</p>}

                                    {this.state.categoryMessageShow && this.state.categoryMessageAr !== '' ? <label className="categoryMessage"><i
                                        className="fa fa-info-circle"></i> {translate.getLocale() === 'ar' ? this.state.categoryMessageAr : this.state.categoryMessageEn} </label> : ""}
                                    <Search products={this.state.products}/>
                                    {/*{products}*/}
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    {/*<Sticky  topOffset={80}>*/}
                                    <Cart deliveryPrice={this.state.deliveryPrice}
                                          ordinaryDelivery={this.state.ordinaryDelivery}
                                          fastDelivery={this.state.fastDelivery}/>
                                    {/*</Sticky>*/}
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    {this.state.recommendedProducts.length !== 0 ? (
                                        <RecommendedProducts products={this.state.recommendedProducts}/>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/*<Modal isOpen={this.state.branchModal} toggle={this.branchTypeModal} className="countryModal">*/}
                {/*<ModalHeader toggle={this.branchTypeModal}><Translate content="choose_branch"/></ModalHeader>*/}
                {/*<ModalBody>*/}
                {/*<div className="container">*/}
                {/*{branches}*/}
                {/*</div>*/}
                {/*</ModalBody>*/}
                {/*</Modal>*/}
                {/*<Modal isOpen={this.state.categoryModal} toggle={this.categoryTypeModel} className="countryModal">*/}
                {/*<ModalHeader toggle={this.categoryTypeModel}><Translate content="choose_category"/></ModalHeader>*/}
                {/*<ModalBody>*/}
                {/*<form onSubmit={this.handelCategorySubmit}>*/}
                {/*<div className="container">*/}
                {/*<div className="loadingSec">*/}

                {/*<BallBeat*/}
                {/*color={'#2C2C2C'}*/}
                {/*loading={this.state.loading}*/}
                {/*/>*/}
                {/*</div>*/}
                {/*{branchcategories}*/}
                {/*<button type="submit" className="typeButton"><Translate*/}
                {/*content="confirm"/></button>*/}
                {/*</div>*/}
                {/*</form>*/}
                {/*</ModalBody>*/}
                {/*</Modal>*/}
                <Modal isOpen={this.state.clearBasketModel} toggle={this.clearTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="clear_basket" component="p"/>
                            <button type="button" className="confirmDelete" onClick={this.clearBasket}><Translate
                                content="confirm"/></button>
                            <button type="button" className="cancelDelete" onClick={this.clearTypeModel}><Translate
                                content="cancel"/></button>
                        </div>
                    </ModalBody>
                </Modal>

            </Fragment>
        )
    }


}


const mapDispatchToProps = (dispatch) => {

    return {
        addToCart: (id, sizeId, additionals, sortedExtras) => {
            dispatch(addToCart(id, sizeId, additionals, sortedExtras))
        },
        RESETAPP: () => {
            dispatch(RESETAPP())
        },
        deliveryPrice: (ordinary, fast, delivery) => {
            dispatch(deliveryPrice(ordinary, fast, delivery))
        },

    }
};

export default connect(null, mapDispatchToProps)(FoodList)

