import React, {Component, Fragment} from "react";
import Translate from "react-translate-component";
import axios from "axios";
import {BallBeat} from "react-pure-loaders";
import {Redirect} from "react-router-dom"
import DatePicker from "react-datepicker";
let translate = require('counterpart');

class MudiafBooking extends Component {

    constructor(props) {
        super(props)
        let loggedIn = true;

        this.state = {
            token: localStorage.getItem("token"),
            orderId: null,
            menuNameAr:"",
            menuNameEn:"",
            menuPic:"",
            menuId: window.location.href.split('/')[4],
            minPersonsNumber: 0,
            maxPersonsNumber: 0,
            pricePersonSAR: 0,
            datePickerIsOpen: true,
            startDate: new Date().setHours(new Date().getHours() + 2),
            minTime: new Date().setHours(new Date().getHours() + 2),
            currAr: "ريال",
            currEn: "SAR",
            totalPrice: 0,
            persons: 0,
            address: "",
            notes: "",
            selectedOption: "",
            date: new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate(),
            time: "",
            cashPay: true,
            cardPay: true,
            loading: false,
            disabled: true,
            services:[],
            loggedIn
        };
    }

    async componentDidMount() {

        let headers = {
            'Accept': 'application/json',
        };

        axios.get(window.$urlName + 'mdiaf/menus/'+ this.state.menuId, {
            headers
        }).then((response) => {
            this.setState({
                menuNameAr: response.data.data.arName,
                menuNameEn: response.data.data.enName,
                menuPic: response.data.data.image,
                minPersonsNumber: response.data.data.minPersonsNumber,
                maxPersonsNumber: response.data.data.maxPersonsNumber,
                persons: response.data.data.minPersonsNumber,
                pricePersonSAR: response.data.data.pricePersonSAR,
                // totalPrice: response.data.data.pricePersonSAR * response.data.data.minPersonsNumber,
                totalPrice: response.data.data.pricePersonSAR,
                loading:false
            })
        }).catch(function (error) {
            window.location.pathname = "/404"
        });

        axios.get(window.$urlName + 'mdiaf/services', {
            headers
        }).then((response) => {
            this.setState({
                services: response.data.data.mdiafServices,
                loading:false
            })
        }).catch(function (error) {
            window.location.pathname = "/404"
        });

        let current = new Date();
        let d = current.getDate();
        let m = current.getMonth() + 1;
        let y = current.getFullYear();
        if(d<10){
            d = '0'+d;
        }
        if(m<10){
            m = '0'+m;
        }
        let selectedDate = y + '-' + m + '-' + d;

        let hh = current.getHours()+2;
        let mm = current.getMinutes();

        if(hh<10){
            hh = '0'+hh;
        }
        if(mm<10){
            mm = '0'+mm;
        }

        let selectedTime = hh + ':' + mm ;

        this.setState({
            date: selectedDate,
            time: selectedTime,
        });
    }

    handleChange = (e) => {
        this.setState ({
            [e.target.id]: e.target.value
        });

        if(e.target.id ==='persons'){
            this.setState ({
                totalPrice: this.state.pricePersonSAR
                // totalPrice: e.target.value * this.state.pricePersonSAR
            });
        }
    };

    handleChangeTime = (date) => {

        if (date.toLocaleDateString() === new Date().toLocaleDateString()) {
            this.setState({
                startDate: date,
                minTime: new Date().setHours(new Date().getHours() + 2)
            });

        } else {
            this.setState({
                startDate: date,
                minTime: new Date(new Date().setHours(9, 30)),

            });
        }

        let d = date.getDate();
        let m = date.getMonth() + 1;
        let y = date.getFullYear();
        if(d<10){
            d = '0'+d;
        }
        if(m<10){
            m = '0'+m;
        }
        let selectedDate = y + '-' + m + '-' + d;

        let hh = date.getHours();
        let mm = date.getMinutes();

        if(hh<10){
            hh = '0'+hh;
        }
        if(mm<10){
            mm = '0'+mm;
        }

        let selectedTime = hh + ':' + mm ;

        this.setState({
            date: selectedDate,
            time: selectedTime,
        });
    };

    getError = (msg) => {
        if (msg === "branch not support branch pick up") {
            this.setState({
                ErrorMsgAr: "الفرع لا يدعم خدمة توصيل الطالبات",
                ErrorMsgEn: "branch not support branch pick up",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "branch not support delivery") {
            this.setState({
                ErrorMsgAr: "الفرع لا يدعم خدمة توصيل المنزل",
                ErrorMsgEn: "branch not support delivery",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "branch not exist") {
            this.setState({
                ErrorMsgAr: "الفرع خارج نطاق الخدمة",
                ErrorMsgEn: "branch not exist",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "branch not active") {
            this.setState({
                ErrorMsgAr: "الفرع غير متاح حاليا",
                ErrorMsgEn: "branch not active",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "branch not open") {
            this.setState({
                ErrorMsgAr: "الفرع مغلق الأن",
                ErrorMsgEn: "branch not open",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "meal not exist") {
            this.setState({
                ErrorMsgAr: "الوجبة غير متاحة حاليا",
                ErrorMsgEn: "meal not exist",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "meal not active") {
            this.setState({
                ErrorMsgAr: "الوجبة غير متاحة حاليا",
                ErrorMsgEn: "meal not active",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "product not delivery") {
            this.setState({
                ErrorMsgAr: "الوجبة غير متاحة للتوصيل",
                msgEn: "product not delivery",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "additional not delivery") {
            this.setState({
                ErrorMsgAr: "الإضافات غير متاحة للتوصيل",
                ErrorMsgEn: "additional not delivery",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "extra not exist") {
            this.setState({
                ErrorMsgAr: "الزيادات غير متاحة",
                ErrorMsgEn: "extra not exist",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "extra not belong to product") {
            this.setState({
                ErrorMsgAr: "الزيادات غير متوفرة مع الوجبة",
                ErrorMsgEn: "extra not belong to product",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "The selected code is invalid.") {
            this.setState({
                ErrorMsgAr: "الكود غير صحيح",
                ErrorMsgEn: "The selected code is invalid.",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "address not valid") {
            this.setState({
                ErrorMsgAr: "العنوان غير صحيح",
                ErrorMsgEn: "address not valid",
                orderErrorMsgModel: true,
                loading: false,
            })
        }
        else if (msg === "time not in branch time") {
            this.setState({
                ErrorMsgAr: "الفرع مغلق في الوقت المحدد",
                ErrorMsgEn: "time not in branch time",
                orderErrorMsgModel: true,
                loading: false,
            })
        }
        else if (msg === "branch not support fast delivery") {
            this.setState({
                ErrorMsgAr: "الفرع لا يوفر التوصيل السريع",
                ErrorMsgEn: "branch not support fast delivery",
                orderErrorMsgModel: true,
                loading: false,
            })
        }
        else if (msg === "branch busy") {
            this.setState({
                ErrorMsgAr: "الفرع مشغول، تقدر تطلب بعد الزحام.",
                ErrorMsgEn: "This branch is currently busy. You can order for later when branch is not busy.",
                orderErrorMsgModel: true,
                loading: false,
            })
        }
        else if (msg === "branch not support ordinary delivery") {
            this.setState({
                ErrorMsgAr: "الفرع لا يوفر التوصيل العادي",
                ErrorMsgEn: "branch not support ordinary delivery",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else {
            this.setState({
                ErrorMsgAr: "حدث خطأغير متوقع",
                ErrorMsgEn: "something went wrong",
                orderErrorMsgModel: true,
                loading: false,
            })
        }


    };

    orderSubmit = (values) => {
        axios.post(window.$urlName + 'mdiaf-orders', values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.state.token,
            }
        }).then((response) => {
            if (this.state.selectedOption === "tap") {
                if (response.data.data.url === null) {
                    this.setState({
                        redirect: true,
                        orderId: response.data.data.orderId
                    });
                    localStorage.setItem("menuRoute", "false");
                    localStorage.setItem("orderId", response.data.data.orderId);
                } else {
                    window.location.href = response.data.data.url
                }
            } else {
                this.setState({
                    redirect: true,
                    orderId: response.data.data.orderId
                });
                localStorage.setItem("menuRoute", "false");
                localStorage.setItem("orderId", response.data.data.orderId);
            }

        }).catch(error => {
            if (error.response.status === 401) {
                this.setState({
                    selectedOption: "",
                    loggedIn: false,
                })
            } else {
                this.getError(error.response.data.error)
            }

        });
    };

    handelSubmit = (e) => {
        e.preventDefault();
        this.setState({
            disabled: false,
        });

        if (this.state.token == null) {
            this.setState({
                selectedOption: "",
                loggedIn: false
            })
        } else {
            let values = {
                mdiafMenuId: parseInt(this.state.menuId),
                personsNumber: parseInt(this.state.persons),
                paymentMethod: this.state.selectedOption,
                description: this.state.notes,
                address: this.state.address,
                date: this.state.date,
                time: this.state.time,
            };
            this.orderSubmit(values);
        }
    };

    handleOptionChange = (changeEvent) => {
        if (this.state.token == null) {
            this.setState({
                selectedOption: "",
                loggedIn: false
            })
        } else {
            this.setState({
                selectedOption: changeEvent.target.value,
                disabled: true,
            });
        }
    };

    render() {
        if (this.state.loggedIn === false) {
            const location =
                {pathname: '/sign-in', state: {from: 'mudiafBooking'}};
            this.props.history.push(location);
        }

        const {redirect} = this.state;

        if (redirect) {
            return <Redirect to={{
                pathname: `/medhyaf-orders/${this.state.orderId}`,
            }}
            />
        }

        // let services = this.state.services.map((service) => {
        //     return (

        //         <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" key={service.id}>
        //             <div className="branchSingleCard">

        //                 <div className="branchImg" style={{backgroundImage: `url(${service.featuredImg})`}}>
        //                 </div>
        //                 {translate.getLocale() === 'ar' ? <h3>{service.arName}</h3> :
        //                     <h3>{service.enName}</h3>}
        //                 <p> {service.price}
        //                     {translate.getLocale() === 'ar' ? <span>{this.state.currAr}</span> : <span>{this.state.currEn}</span>}</p>
        //                 <p>{translate.getLocale() === 'ar' ? <span>{service.arDescription}</span> : <span>{service.enDescription}</span>}</p>
        //             </div>

        //         </div>
        //     )
        // });

        return (
            <Fragment>

                <div className="container" id="basketFull">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="loadingSec">
                                <BallBeat
                                    color={'white'}
                                    loading={this.state.loading}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <form onSubmit={this.handelSubmit} style={{width:"100%",margin:"auto"}}>
                        <div className="mudiafCheckout">
                            <div className="mudiafCheckoutHeader">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <div className="branchImg" style={{backgroundImage: `url(${this.state.menuPic})`}}>
                                        </div>
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                {translate.getLocale() === 'ar' ? <p style={{marginTop:"20px"}}>{this.state.menuNameAr}</p> : <p style={{marginTop:"20px"}}>{this.state.menuNameEn}</p>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <p><Translate content="min_persons" component="span"/> {this.state.minPersonsNumber} <Translate content="person" component="span"/></p>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <p><Translate content="personPrice" component="span"/> {this.state.pricePersonSAR} {translate.getLocale() === 'ar' ? <span>{this.state.currAr}</span> : <span>{this.state.currEn}</span>}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="mudiafCheckoutWhite">

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <p><Translate content="num_persons"/></p>
                                        <Translate component="input" type="number" id="persons" required="required" onChange={this.handleChange} min={this.state.minPersonsNumber} max={this.state.maxPersonsNumber}/>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <p><Translate content="chooseDate"/></p>

                                        <DatePicker
                                            selected={this.state.startDate}
                                            onChange={this.handleChangeTime}
                                            showTimeSelect
                                            dateFormat=" h:mm ,yyyy/MM/dd"
                                            minTime={this.state.minTime}
                                            maxTime={new Date(new Date().setHours(23, 59))}
                                            minDate={new Date()}
                                            timeFormat="HH:mm"
                                        />
                                    </div>
                                </div>

                                <hr/>

                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <Translate attributes={{placeholder: 'enterAddress'}} id="address" defaultValue={this.state.address} onChange={this.handleChange} component="textarea" required="required"/>
                                    </div>
                                </div>

                                <hr/>

                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <Translate attributes={{placeholder: 'enter_your_notes'}} id="notes" defaultValue={this.state.notes} onChange={this.handleChange}  component="textarea" required="required"/>
                                    </div>
                                </div>

                                <hr/>

                                <div className="row">
                                    {this.state.cashPay ?
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="radio">
                                                <input type="radio" value="cash" name="payment" id="cash"
                                                       onChange={this.handleOptionChange}/>
                                                <label htmlFor="cash"> <Translate content="arrivalPayment"/>
                                                </label>
                                            </div>
                                        </div>
                                        : null}
                                    {this.state.cardPay ?
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="radio">
                                                <input type="radio" value="tap" name="payment" id="tap"
                                                       onChange={this.handleOptionChange}/>
                                                <label htmlFor="tap"><Translate
                                                    content="creditCardPayment"/></label>
                                            </div>
                                        </div>
                                        : null}
                                </div>

                                {/* <hr/>

                                <div className="row">
                                    {services}
                                </div> */}
                            </div>

                            <div className="mudiafCheckoutFooter">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <Translate content="total_price" component="p"/>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <p> {this.state.totalPrice} {translate.getLocale() === 'ar' ? <span>{this.state.currAr}</span> : <span>{this.state.currEn}</span>} </p>                                    </div>
                                </div>
                            </div>

                            <div className="paymentButton">
                                <button type="submit" disabled={!this.state.selectedOption || !this.state.disabled}>
                                    <Translate content="confirm"/></button>
                            </div>
                        </div>
                        </form>
                    </div>

                </div>
            </Fragment>
        )
    }
}

export default MudiafBooking
