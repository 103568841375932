import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import Orders from "./orders";
import MudiafOrders from "./mudiafOrders";
import {BallBeat} from "react-pure-loaders";
import {RESETAPP} from "./actions/cartActions";
import connect from "react-redux/es/connect/connect";
import eye from "./component/assest/img/eye.svg";
import {Modal, ModalBody} from "reactstrap";
import {Redirect} from 'react-router-dom';

let translate = require('counterpart');


class MyPage extends Component {
    constructor(props) {
        super(props);
        const token = localStorage.getItem("token");
        let loggedIn = true;

        if (token == null) {
            loggedIn = false
        }
        this.state = {
            token: localStorage.getItem("token"),
            userName: '',
            phone: '',
            walletAmount: '',
            showActionFilterList: false,
            checkedItems: new Map(),
            loading: true,
            loadingUpdate: false,
            chargeWallet: true,
            arCurrency: '',
            enCurrency: '',
            hidden: true,
            msgAr:"",
            msgEn:"",
            errorMsgModel: false,
            updatePhone: false,
            loggedIn
        };

    }

    componentDidMount() {

        axios.get(window.$urlName + 'customers/account-info', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token,
                'Accept': 'application/json'
            }
        }).then((response) => {
            this.setState({
                userName: response.data.data.name,
                phone: response.data.data.phone,
                loading: false
            });

        }).catch(function (error) {
            if (error.response.status === 403) {
                window.location.pathname = "/verification-code"
            } else {
                window.location.pathname = "/sign-in"
            }
        });



    }
    errorTypeModel = () => {
        this.setState(prevState => ({
            errorMsgModel: !prevState.errorMsgModel,

        }));

    };
    getBalance = () => {
        this.setState({
            loading: true

        })
        axios.get(window.$urlName + 'customers/wallet-balance', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token,
                'Accept': 'application/json'
            }
        }).then((response) => {
            this.setState({
                walletAmount: parseFloat(response.data.data.balance).toFixed(2),
                arCurrency: response.data.data.arCurrency,
                enCurrency: response.data.data.enCurrency,
                chargeWallet: response.data.data.allowChargeWallet,
                loading: false
            })

        }).catch(function (error) {
            if (error.response.status === 403) {
                window.location.pathname = "/verification-code"
            } else {
                window.location.pathname = "/sign-in"
            }
        });
    };

//     axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + event.latLng.lat() + ',' + event.latLng.lng() + '&language=ar&key=AIzaSyAvdah9hpjf0m5BYez2HeIp4msDrjMtqsk')
// .then((response) => {
//     console.log(response.data.results[0].formatted_address)
//     this.setState({
//                       arAddress: response.data.results[0].formatted_address
// });
// });
// axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + event.latLng.lat() + ',' + event.latLng.lng() + '&language=en&key=AIzaSyAvdah9hpjf0m5BYez2HeIp4msDrjMtqsk')
//     .then((response) => {
//         console.log(response.data.results[0].formatted_address)
//         this.setState({
//             enAddress: response.data.results[0].formatted_address
//         });
//     });
    displayUpdateForm = () => {
        document.getElementById('accountInfo').style.display = "none";
        document.getElementById('updateForm').style.display = "block";
    };
    logoutTypeModel = () => {
        this.setState(prevState => ({
            logoutModel: !prevState.logoutModel,

        }));


    };
    logoutForm = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("menuRoute");
        localStorage.removeItem("userName");
        this.props.RESETAPP();
        window.location.pathname = "/sign-in"
    };
    toggleShow = () => {
        this.setState({ hidden: !this.state.hidden });
    }
    render() {
        let {updatePhone} = this.state
        if(updatePhone){
            return <Redirect to={{
                pathname: '/update-phone',
                state: {phone: this.state.phone},
            }}
            />
        }

        let formDisplay = {
            display: 'none'
        };
        if (this.state.loggedIn === false) {
            const location =
                {pathname: '/sign-in', state: {from: 'profile'}};
            this.props.history.push(location);
        }

        let phoneRegEx = /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;

        return (

            <Fragment>
                <div className="container-fluid pageCovePhoto">
                </div>
                <div className="container-fluid">
                    <Tabs>
                        <div className="row">
                            <div className="col-lg-3 col-md-3">
                                <div className="tabList">
                                    <TabList>
                                        <Tab><Translate content="my_profile"/></Tab>
                                        <Tab onClick={this.getBalance}><Translate content="my_wallet"/></Tab>
                                        <Tab><Translate content="my_orders"/></Tab>
                                        {/* <Tab><Translate content="mudiaf_orders"/></Tab> */}
                                        <Tab onClick={this.logoutTypeModel}><Translate content="log_out"/></Tab>
                                    </TabList>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-9">
                                <TabPanel>
                                    <div className="panelBody">
                                        <div className="myProfileForm">
                                            <h1><Translate content="my_profile"/></h1>
                                            <div id="accountInfo">
                                                <table className="table">
                                                    <tbody>
                                                    <tr>
                                                        <Translate content="name" component="td" className="head"/>
                                                        <td className="arialFont">{this.state.userName} <BallBeat
                                                            color={'#2C2C2C'}
                                                            loading={this.state.loading}
                                                        />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <Translate content="tel" component="td" className="head"/>

                                                        <td className="arialFont"><BallBeat
                                                            color={'#2C2C2C'}
                                                            loading={this.state.loading}
                                                        />
                                                            {this.state.phone}
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <button type="button" className="orangeButton"
                                                        onClick={this.displayUpdateForm}><Translate
                                                    content="update"/></button>
                                            </div>

                                            <Formik enableReinitialize
                                                    initialValues={{
                                                        name: this.state.userName,
                                                        phone: this.state.phone,
                                                        password: '',
                                                    }}
                                                    validationSchema={Yup.object().shape({
                                                        name: Yup.string()
                                                            .required(<Translate content="name_required"/>),
                                                        phone: Yup.string()
                                                            .matches(phoneRegEx, <Translate content="phone_not_valid"/>)
                                                            .required(<Translate content="phone_required"/>),
                                                        password: Yup.string()
                                                            .min(6, <Translate content="at_least"/>)
                                                            .required(<Translate content="password_required"/>),

                                                    })}
                                                    onSubmit={values => {
                                                        this.setState({
                                                            loadingUpdate:true
                                                        })
                                                        if(this.state.phone !== values.phone){
                                                            axios.put(window.$urlName + 'customers', values, {
                                                                headers: {
                                                                    'Authorization': 'Bearer ' + this.state.token,
                                                                    'Content-Type': 'application/json',
                                                                    'Accept': 'application/json'
                                                                }
                                                            }).then((response) => {
                                                                axios.get(window.$urlName + 'customers/account-info', {
                                                                    headers: {
                                                                        'Authorization': 'Bearer ' + this.state.token,
                                                                        'Accept': 'application/json'
                                                                    }
                                                                }).then((response) => {
                                                                    localStorage.setItem("userName",response.data.data.name)
                                                                    localStorage.setItem("userPhone",values.phone)
                                                                    this.setState({
                                                                        loadingUpdate:false,
                                                                        updatePhone :true
                                                                    })

                                                                })

                                                            }).catch(function (error) {
                                                                if (error.response.status === 422) {
                                                                    this.setState({
                                                                        msgAr:"هذا الرقم مسجل من قبل",
                                                                        msgEn:"This phone is exist",
                                                                        errorMsgModel:true
                                                                    });

                                                                } else {
                                                                    // console.log(error)
                                                                }
                                                            }.bind(this));


                                                        }else {
                                                            let valuesUpdated  = {
                                                                name : values.name,
                                                                password : values.password
                                                            };
                                                            axios.put(window.$urlName + 'customers', valuesUpdated, {
                                                                headers: {
                                                                    'Authorization': 'Bearer ' + this.state.token,
                                                                    'Content-Type': 'application/json',
                                                                    'Accept': 'application/json'
                                                                }
                                                            }).then((response) => {
                                                                axios.get(window.$urlName + 'customers/account-info', {
                                                                    headers: {
                                                                        'Authorization': 'Bearer ' + this.state.token,
                                                                        'Accept': 'application/json'
                                                                    }
                                                                }).then((response) => {
                                                                    localStorage.setItem("userName",response.data.data.name)
                                                                    window.location.reload();


                                                                })

                                                            }).catch(function (error) {
                                                                if (error.response.status === 422) {
                                                                    this.setState({
                                                                        msgAr:"هذا الرقم مسجل من قبل",
                                                                        msgEn:"This phone is exist",
                                                                        errorMsgModel:true
                                                                    });

                                                                } else {
                                                                    // console.log(error)
                                                                }
                                                            }.bind(this));
                                                        }




                                                    }}

                                                    render={({errors, status, touched}) => (
                                                        <Form id="updateForm" style={formDisplay}>
                                                            <div className="loadingSec">
                                                                <BallBeat
                                                                    color={'#2C2C2C'}
                                                                    loading={this.state.loadingUpdate}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="name"><Translate
                                                                    content="name"/></label>
                                                                <Field name="name" id="name" type="text"
                                                                       className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}/>
                                                                <ErrorMessage name="name" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="phone"><Translate
                                                                    content="tel"/></label>
                                                                <Field name="phone" id="phone" type="tel"
                                                                       className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')}/>
                                                                <ErrorMessage name="phone" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="password"><Translate content="password"/></label>
                                                                <div style={{position:"relative"}}>

                                                                    <Field name="password" id="password" type={this.state.hidden ? "password" : "text"}
                                                                           className={'form-control' +  (errors.password && touched.password ? ' is-invalid' : '')}/>
                                                                    <span className="showButt" onClick={this.toggleShow}><img src={eye} alt="eye"/></span>
                                                                    <ErrorMessage name="password" component="div" className="invalid-feedback"/>
                                                                </div>
                                                            </div>



                                                            <button type="submit" className="orangeButton"><Translate
                                                                content="save"/></button>
                                                        </Form>
                                                    )}
                                            />

                                        </div>
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <div className="panelBody">
                                        <div className="walletBody">
                                            <h1><Translate content="my_wallet"/></h1>
                                            <div className="walletCredit">
                                                <div className="loadingSec">

                                                    <BallBeat
                                                        color={'#2C2C2C'}
                                                        loading={this.state.loading}
                                                    />
                                                </div>
                                                <p className="arialFont"> {this.state.walletAmount}

                                                    {translate.getLocale() === 'ar' ?
                                                        <span>{this.state.arCurrency}</span> :
                                                        <span>{this.state.enCurrency}</span>}


                                                </p>
                                            </div>
                                            {this.state.chargeWallet === true ?
                                                <a className="orangeButton" href="/charge-wallet"><Translate content="chargeWallet"/></a>
                                                :""}
                                        </div>
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <Orders/>
                                </TabPanel>


                                {/* <TabPanel>
                                    <MudiafOrders/>
                                </TabPanel> */}

                                <TabPanel>

                                    <div className="cancelOrderDiv">

                                        <Translate content="logout_modal" component="p"/>
                                        <button type="button" className="confirmDelete"
                                                onClick={this.logoutForm}><Translate
                                            content="logout"/></button>
                                        {/*<button type="button" className="cancelDelete" onClick={this.logoutTypeModel}><Translate*/}
                                        {/*content="cancel"/></button>*/}
                                    </div>

                                </TabPanel>

                            </div>
                        </div>
                    </Tabs>

                </div>
                {/*<Modal isOpen={this.state.logoutModel} toggle={this.logoutTypeModel} className="countryModal">*/}
                {/*<ModalBody>*/}
                {/*<div className="cancelOrder">*/}
                {/*<Translate content="logout_modal" component="p"/>*/}
                {/*<button type="button" className="confirmDelete" onClick={this.logoutForm}><Translate*/}
                {/*content="logout"/></button>*/}
                {/*<button type="button" className="cancelDelete" onClick={this.logoutTypeModel}><Translate*/}
                {/*content="cancel"/></button>*/}
                {/*</div>*/}
                {/*</ModalBody>*/}
                {/*</Modal>*/}
                <Modal isOpen={this.state.errorMsgModel} toggle={this.errorTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="sorry" component="p"/>

                            {translate.getLocale() === 'ar' ? <p> {this.state.msgAr}</p> :
                                <p>{this.state.msgEn}</p>}
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        RESETAPP: () => {
            dispatch(RESETAPP())
        },
    }
};
export default connect(null, mapDispatchToProps)(MyPage)
