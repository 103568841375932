import React, {Component, Fragment} from "react";
import Cart from "./cart";
import Translate from "react-translate-component";
import basket from "./component/assest/img/basketDefult.png";

class Basket extends Component {
    render() {
        let addedItems = JSON.parse(localStorage.getItem("reducer") || "[]").length ?
            (
                <div className="basketPage">
                    <Cart/>
                </div>
            ) :

            (<div style={{height: "500px"}}>
                    <div className="parent">
                        <div className="child">
                            <p className="nonOrdersBasket">
                                <img src={basket} alt="basket"/>
                                <Translate content="nothing_basket"/>
                            </p>
                        </div>
                    </div>
                </div>
            );
        return (
            <Fragment>
                {addedItems}
            </Fragment>
        )
    }
}

export default Basket