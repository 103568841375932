import React, {Component, Fragment} from 'react';
import Swiper from "react-id-swiper";
import Translate from "react-translate-component";
import share from './component/assest/img/shareIcon.svg'
import branch from './component/assest/img/branchIcon.svg'
import delivery from './component/assest/img/deliveryIcon.svg'
import branchGr from './component/assest/img/branchIconGr.svg'
import deliveryGr from './component/assest/img/deliverIconGr.svg'
import shareGr from './component/assest/img/shareIconGr.svg'

import {Link} from 'react-router-dom';
import axios from "axios";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Redirect} from 'react-router-dom';
import {BallBeat} from "react-pure-loaders";
import {RESETAPP} from "./actions/cartActions";
import connect from "react-redux/es/connect/connect";

let translate = require('counterpart');


class Home extends Component {

    constructor(props) {
        super(props);
        const token = localStorage.getItem("token");
        let loggedIn = true;

        if (token == null) {
            loggedIn = false
        }
        this.state = {
            token: localStorage.getItem("token"),
            categories: [],
            countries: [],
            cities: [],
            oldAddress: [],
            nearbyBranches: [],
            banners: [],
            selectedOption: "",
            cityId: null,
            branchTypeId: 1,
            homeTypeId: 2,
            shareTypeId: 3,
            modal: false,
            deliveryModal: false,
            countryModal: false,
            cityModal: false,
            redirect: false,
            login: false,
            loading: true,
            loadingSwip: true,
            activeSmoke: false,
            activeDessert: false,
            activeMeat: false,
            activeCheck: false,
            activeSoup: false,
            activeAppetizers: false,
            activeSpanish: false,
            CountryAr: "",
            CountryEn: "",
            CityAr: "",
            CityEn: "",
            arAddress: "",
            enAddress: "",
            key: "5",
            disabled_button: false,
            clearBasketModel: false,
            setAddress: false,
            deliveryIcon: deliveryGr,
            branchIcon: branchGr,
            shareIcon: shareGr,
            features1: {},
            features2: {},
            features3: {},
            features4: {},
            features5: {},
            features6: {},
            showFeature: false,
            Featureloading: true,


            loggedIn
        };


    }

    componentDidMount() {
        axios.get(window.$urlName + 'banners', {
            params: {
                type: 'website',
            }
        }).then((response) => {
            this.setState({
                banners: response.data.data,
                loadingSwip: false
            });
        });
        axios.get(window.$urlName + 'categories').then((response) => {
            this.setState({
                categories: response.data.data,
                loading: false
            });

        });

        axios.get(window.$urlName + 'categories', {params: {type: 'featured'}}).then((response) => {
            if (response.data.data.length !== 0) {
                
                this.setState({
                    features1: (response.data.data.length >= 1) ? response.data.data[0] :{},
                    features2: (response.data.data.length >= 2) ? response.data.data[1] :{},
                    features3: (response.data.data.length >= 3) ? response.data.data[2] :{},
                    features4: (response.data.data.length >= 4) ? response.data.data[3] :{},
                    features5: (response.data.data.length >= 5) ? response.data.data[4] :{},
                    features6: (response.data.data.length >= 6) ? response.data.data[5] :{},
                    showFeature: true,
                    Featureloading: false,
                })
            } else {
                this.setState({
                    showFeature: false,
                    Featureloading: false,

                })
            }

        });

    }

    toggleSmokes = () => {
        this.setState({activeSmoke: !this.state.activeSmoke})
    }
    toggleDessert = () => {
        this.setState({activeDessert: !this.state.activeDessert})
    }
    toggleMeat = () => {
        this.setState({activeMeat: !this.state.activeMeat})
    }
    toggleCheck = () => {
        this.setState({activeCheck: !this.state.activeCheck})
    }

    toggleAppetizers = () => {
        this.setState({activeAppetizers: !this.state.activeAppetizers})
    }
    toggleSpanish = () => {
        this.setState({activeSpanish: !this.state.activeSpanish})
    }
    clearTypeModel = () => {
        this.setState(prevState => ({
            clearBasketModel: !prevState.clearBasketModel,

        }));

    };

    clearBasket = () => {
        this.props.RESETAPP()
        this.setState(prevState => ({
            clearBasketModel: !prevState.clearBasketModel,
        }));

    }
    toggle = () => {
        if (this.props.lat && this.props.lng) {
            if (JSON.parse(localStorage.getItem("reducer") || "[]").length === 0) {
                axios.get(window.$urlName + 'branches', {
                    params: {
                        type: 'nearby',
                        class: 'delivery',
                        // setValue
                        latitude: this.props.lat,
                        longitude: this.props.lng,
                    }
                }).then((response) => {
                    localStorage.setItem("addressLat", this.props.lat);
                    localStorage.setItem("addressLong", this.props.lng);
                    this.setState({
                        nearbyBranches: response.data.data,
                        redirect: true
                    });

                });
                const {homeTypeId} = this.state;
                localStorage.setItem("typeId", homeTypeId);
            } else {
                this.setState(prevState => ({
                    clearBasketModel: !prevState.clearBasketModel,
                }));

            }
        } else {
            if (JSON.parse(localStorage.getItem("reducer") || "[]").length === 0) {
                axios.get(window.$urlName + 'addresses', {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token,
                        'Accept': 'application/json'
                    }
                })
                    .then((response) => {
                        this.setState({
                            oldAddress: response.data.data,
                            loading: false,
                            loggedIn: true

                        });

                    });
                this.setState(prevState => ({
                    modal: !prevState.modal,

                }));
                const {homeTypeId} = this.state;
                localStorage.setItem("typeId", homeTypeId);
            } else {
                this.setState(prevState => ({
                    clearBasketModel: !prevState.clearBasketModel,
                }));

            }
        }


    };

    countryTypeModel = () => {
        if (this.props.lat && this.props.lng) {
            if (JSON.parse(localStorage.getItem("reducer") || "[]").length === 0) {
                axios.get(window.$urlName + 'branches', {
                    params: {
                        type: 'nearby',
                        class: 'branch',
                        // setValue
                        latitude: this.props.lat,
                        longitude: this.props.lng,
                    }
                }).then((response) => {
                    console.log("here");
                    if(response.data.data.length > 0){
                        this.setState({
                            nearbyBranches: response.data.data,
                            redirect: true
                        });
                    }else{
                        axios.get(window.$urlName + 'countries').then((response) => {
                            this.setState({
                                countries: response.data.data,
                                loading: false,
                                loggedIn: true
                            });
                        });
                        this.setState(prevState => ({
                            countryModal: !prevState.countryModal,
                        }));
                    }
                   
                });
                const {branchTypeId} = this.state;
                localStorage.setItem("typeId", branchTypeId);
            } else {
                this.setState(prevState => ({
                    clearBasketModel: !prevState.clearBasketModel,
                }));

            }
        } else {
            if (JSON.parse(localStorage.getItem("reducer") || "[]").length === 0) {

                axios.get(window.$urlName + 'countries').then((response) => {
                    this.setState({
                        countries: response.data.data,
                        loading: false,
                        loggedIn: true

                    });

                });
                this.setState(prevState => ({
                    countryModal: !prevState.countryModal,

                }));
                const {branchTypeId} = this.state;
                localStorage.setItem("typeId", branchTypeId);
            } else {
                this.setState(prevState => ({
                    clearBasketModel: !prevState.clearBasketModel,
                }));

            }
        }


    };

    shareTypeModel = () => {
        if (JSON.parse(localStorage.getItem("reducer") || "[]").length === 0) {
            axios.get(window.$urlName + 'branches', {
                params: {
                    type: 'shareThem',
                    class: 'branch',
                    // setValue
                    latitude: 1,
                    longitude: 1,
                }
            }).then((response) => {
                this.setState({
                    nearbyBranches: response.data.data,
                    redirect: true
                });

            })
            const {shareTypeId} = this.state;
            localStorage.setItem("typeId", shareTypeId);
        } else {
            this.setState(prevState => ({
                clearBasketModel: !prevState.clearBasketModel,
            }));
        }
    };

    cityTypeModel = () => {
        this.setState(prevState => ({
            cityModal: !prevState.cityModal,
            countryModal: !prevState.countryModal,

        }));

    };
    handleOptionCityChange = (id, e, CityEn, CityAr) => {
        this.setState({
            cityId: id,
            CityEn: CityEn,
            CityAr: CityAr,
            disabled_button: true
        })
    };
    handelCitySubmit = (e) => {
        e.preventDefault();
        axios.get(window.$urlName + 'branches', {
            params: {
                type: 'city',
                class: 'branch',
                cityId: this.state.cityId,
            }
        }).then((response) => {
            this.setState({
                nearbyBranches: response.data.data,
                redirect: true
            });


        });
    }
    deliveryTypeModel = () => {
        this.setState(prevState => ({
            deliveryModal: !prevState.deliveryModal
        }));

    };

    handleOptionChange = (cities, changeEvent, CountryEn, CountryAr) => {
        this.setState({
            cities: cities,
            selectedOption: changeEvent.target.value,
            CountryEn: CountryEn,
            CountryAr: CountryAr,
        });
        this.cityTypeModel()
    };


    showMap = () => {
        if (this.state.loggedIn === false) {
            this.setState({
                login: true
            })
        } else {
            this.setState({
                setAddress: true
            })

        }

    };
    setAddress = (id, lat, long, arAddress, enAddress) => {
        localStorage.setItem("addressId", id);
        localStorage.setItem("addressLat", lat);
        localStorage.setItem("addressLong", long);
        axios.get(window.$urlName + 'branches', {
            params: {
                type: 'nearby',
                class: 'delivery',
                // setValue
                latitude: lat,
                longitude: long,
            }
        }).then((response) => {
            this.setState({
                nearbyBranches: response.data.data,
                arAddress: arAddress,
                enAddress: enAddress,
                redirect: true
            });


        });
    };

    deleteAddress = (id) => {
        axios.delete(window.$urlName + 'addresses/' + id, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token,
                'Accept': 'application/json'
            }
        }).then((response) => {
            window.location.reload();
        });
    };
    changeDeliveryIcon = () => {
        this.setState({
            deliveryIcon: delivery,
        })
    }
    changeDeliveryIconTo = () => {
        this.setState({
            deliveryIcon: deliveryGr,
        })
    }

    changeBranchIcon = () => {
        this.setState({
            branchIcon: branch,
        })
    }
    changeBranchIconTo = () => {
        this.setState({
            branchIcon: branchGr,
        })
    }

    changeShareIcon = () => {
        this.setState({
            shareIcon: share,
        })
    }
    changeShareIconTo = () => {
        this.setState({
            shareIcon: shareGr,
        })
    }

    render() {
        const {setAddress} = this.state;
        const {redirect} = this.state;
        const {login} = this.state;
        if (setAddress) {
            return <Redirect to={{
                pathname: '/set-address',

            }}
            />
        }
        if (redirect) {
            localStorage.setItem("nearbyBranches", JSON.stringify(this.state.nearbyBranches));
            localStorage.setItem("CountryEn", this.state.CountryEn);
            localStorage.setItem("CountryAr", this.state.CountryAr);
            localStorage.setItem("CityEn", this.state.CityEn);
            localStorage.setItem("CityAr", this.state.CityAr);
            localStorage.setItem("arAddress", this.state.arAddress);
            localStorage.setItem("enAddress", this.state.enAddress);

            return <Redirect to={{
                pathname: '/nearby-branches',
                // state: {
                //     branches: this.state.nearbyBranches,
                //     CountryEn: this.state.CountryEn,
                //     CountryAr: this.state.CountryAr,
                //     CityEn: this.state.CityEn,
                //     CityAr: this.state.CityAr,
                //     arAddress: this.state.arAddress,
                //     enAddress: this.state.enAddress,
                // }
            }}
            />
        }
        if (login) {
            const location =
                {pathname: '/sign-in', state: {from: 'home'}};
            this.props.history.push(location);


        }

        const homeParamsSwiper = {
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            spaceBetween: 30,
            autoplay: {
                delay: 4000,
            },
        };
        const categorySwiper = {
            slidesPerView: 6,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                1024: {
                    slidesPerView: 6,
                },
                768: {
                    slidesPerView: 5,
                },
                640: {
                    slidesPerView: 4,
                },
                320: {
                    slidesPerView: 3,
                }
            },
            // autoplay: {
            //     delay: 3000,
            // },


        };

        let banners =
            this.state.banners.map((banner, index) => {
                return (
                    <div className="homeSlide" style={{backgroundImage: `url(${banner.image})`}} key={index}></div>
                )
            });


        let oldAddress = this.state.oldAddress.map((address) => {
            return (

                <div className="col-lg-12 col-md-12" key={address.id}>
                    <div className="addressCard">
                        <div className="parent">
                            <div className="child">
                                <h3>{address.title}</h3>
                                {translate.getLocale() === 'ar' ? <p>{address.arAddress}</p> :
                                    <p>{address.enAddress}</p>}
                                <div className="addressButton">
                                    <button type="button"
                                            onClick={() => this.setAddress(address.id, address.latitude, address.longitude, address.arAddress, address.enAddress)}>
                                        <Translate content="choose"/></button>
                                    <button type="button" onClick={() => this.deleteAddress(address.id)}
                                            style={{background: "#DB095B"}}><Translate content="delete"/></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });

        let cities = this.state.cities.map((city) => {
            return (
                <div className="countrySelect" key={city.id}>
                    <input type="radio" name="country" value={city.id} id={city.id}
                           onChange={(e) => this.handleOptionCityChange(city.id, e, city.enName, city.arName)}/>
                    {translate.getLocale() === 'ar' ? <label htmlFor={city.id}>{city.arName}</label> :
                        <label htmlFor={city.id}>{city.enName}</label>}
                </div>
            )
        });

        let countries = this.state.countries.map((country) => {
            return (
                <div className="countrySelect" key={country.id}>
                    <input type="radio" name="country" value={country.id} id={country.id}
                           onChange={(e) => {
                               this.handleOptionChange(country.cities, e, country.enName, country.arName)
                           }}/>

                    {translate.getLocale() === 'ar' ? <label htmlFor={country.id}>{country.arName}</label> :
                        <label htmlFor={country.id}>{country.enName}</label>}
                </div>
            )
        });
        let categories = this.state.categories.map((category) => {
            return (
                <Link to="/" onClick={this.deliveryTypeModel} key={category.id}>
                    <div className="foodIcon">
                        <div className="iconGround">
                            <img src={category.image} alt="meat"/>
                        </div>
                        {translate.getLocale() === 'ar' ? <p>{category.arName}</p> : <p>{category.enName}</p>}

                    </div>
                </Link>
            )
        });

        return (
            <Fragment>
                <div className="homeSwiper">

                    {this.state.banners.length !== 0 ? (

                        <Swiper {...homeParamsSwiper}>
                            {banners}
                        </Swiper>
                    ) : <div className="loadingSec" style={{marginTop: "200px", marginBottom: "200px"}}>
                        <BallBeat
                            color={'white'}
                            loading={this.state.loadingSwip}
                        />
                    </div>}

                </div>

                <div className="container">
                    <div className="row receivedRow">
                        <div className="col-lg-7">
                            <Translate content="bestTry" component="p"/>
                        </div>
                        <div className="col-lg-5">
                            <div className="row">
                                <div className="col-lg-6">
                                    <button type="button" onMouseOver={this.changeDeliveryIcon}
                                            onMouseOut={this.changeDeliveryIconTo} onClick={this.toggle}><img
                                        src={this.state.deliveryIcon} alt="icon"/> <Translate content="from_home"/>
                                    </button>

                                </div>
                                <div className="col-lg-6">
                                    <button type="button" onMouseOver={this.changeBranchIcon}
                                            onMouseOut={this.changeBranchIconTo} onClick={this.countryTypeModel}>
                                        <img src={this.state.branchIcon} alt="icon"/> <Translate
                                        content="from_branch"/></button>
                                </div>
                                {/*<div className="col-lg-4">*/}
                                {/*<button type="button" onMouseOver={this.changeShareIcon} onMouseOut={this.changeShareIconTo} onClick={this.shareTypeModel}>*/}
                                {/*<img src={this.state.shareIcon} alt="icon"/> <Translate*/}
                                {/*content="share_them"/></button>*/}
                                {/*</div>*/}
                            </div>
                        </div>

                    </div>
                </div>


                <div className="container foodListSec" id={'section1'}>
                    <Translate content="foodList" component="h1"/>
                    <div className="row foodListRow">
                        <div className="loadingSec">
                            <BallBeat
                                color={'white'}
                                loading={this.state.loading}
                            />
                        </div>


                        {this.state.categories.length !== 0 ? (
                            <Swiper {...categorySwiper} >
                                {categories}
                            </Swiper>
                        ) : null}

                    </div>
                </div>
                <div className="container gideRow">
                    {this.state.showFeature ?

                        <div className="row">
                          
                            
                            
                            
                            <div className="col-lg-6 col-md-6 nonePadding">
                            {this.state.features1 !== {} ?
                                <div className="cardContainer">

                                    <div className="Spanish backGroundCard"
                                         style={{backgroundImage: `url(${this.state.features1.featuredImage})`}}>

                                        <div className="cardInfo">
                                            {/*<div className="backImgIcon" style={{backgroundImage: `url(${this.state.features1.image})`}}></div>*/}
                                            {/*<Translate content="spanish" component="h1"/>*/}
                                            {translate.getLocale() === 'ar' ?
                                                <p onClick={this.toggleSpanish}>{this.state.features1.arName}</p> :
                                                <p onClick={this.toggleSpanish}>{this.state.features1.enName}</p>}

                                            {/*<Translate content="spanish" component="p" onClick={this.toggleSpanish}/>*/}
                                            {this.state.activeSpanish &&
                                            <div
                                                className={"deliveryTypeSec" + (this.state.activeSpanish ? " animated" : '')}>
                                                <button type="button" className="typeButton" onClick={this.toggle}>
                                                    <Translate
                                                        content="from_home"/></button>
                                                <button type="button" className="typeButton"
                                                        onClick={this.countryTypeModel}>
                                                    <Translate content="from_branch"/></button>


                                            </div>}
                                        </div>

                                    </div>

                                </div>
                                
                                :""
                                }

                                {this.state.features2 !== {} ?

                                <div className="cardContainer">
                                    <div className="Chickens backGroundCard"
                                         style={{backgroundImage: `url(${this.state.features2.featuredImage})`}}>
                                        <div className="cardInfo">
                                            {/*<div className="backImgIcon" style={{backgroundImage: `url(${this.state.features2.image})`}}></div>*/}
                                            {translate.getLocale() === 'ar' ?
                                                <p onClick={this.toggleCheck}>{this.state.features2.arName}</p> :
                                                <p onClick={this.toggleCheck}>{this.state.features2.enName}</p>}
                                            {/*<Translate content="chicken" component="h1"/>*/}
                                            {/*<Translate content="chicken" component="p" onClick={this.toggleCheck}/>*/}
                                            {this.state.activeCheck && <div
                                                className={"deliveryTypeSec" + (this.state.activeCheck ? " animated" : '')}>
                                                <button type="button" className="typeButton" onClick={this.toggle}>
                                                    <Translate
                                                        content="from_home"/></button>
                                                <button type="button" className="typeButton"
                                                        onClick={this.countryTypeModel}>
                                                    <Translate content="from_branch"/></button>


                                            </div>}
                                        </div>


                                    </div>

                                </div>
                                :""}


                            </div>
                            <div className="col-lg-6 col-md-6 nonePadding">

                            {this.state.features3 !== {} ?
                                <div className="cardContainer">
                                    <div className="Meats backGroundCard"
                                         style={{backgroundImage: `url(${this.state.features3.featuredImage})`}}>
                                        <div className="cardInfo">
                                            {/*<div className="backImgIcon" style={{backgroundImage: `url(${this.state.features3.image})`}}></div>*/}
                                            {translate.getLocale() === 'ar' ?
                                                <p onClick={this.toggleMeat}>{this.state.features3.arName}</p> :
                                                <p onClick={this.toggleMeat}>{this.state.features3.enName}</p>}
                                            {/*<Translate content="meats" component="h1"/>*/}
                                            {/*<Translate content="meats" component="p" onClick={this.toggleMeat}/>*/}
                                            {this.state.activeMeat &&
                                            <div
                                                className={"deliveryTypeSec" + (this.state.activeMeat ? " animated" : '')}>
                                                <button type="button" className="typeButton" onClick={this.toggle}>
                                                    <Translate
                                                        content="from_home"/></button>
                                                <button type="button" className="typeButton"
                                                        onClick={this.countryTypeModel}>
                                                    <Translate content="from_branch"/></button>


                                            </div>}
                                        </div>


                                    </div>

                                </div>
                                :""}


                                {this.state.features4 !== {} ?
                                <div className="cardContainer">

                                    <div className="Smokes backGroundCard"
                                         style={{backgroundImage: `url(${this.state.features4.featuredImage})`}}>
                                        <div className="cardInfo">
                                            {/*<div className="backImgIcon" style={{backgroundImage: `url(${this.state.features4.image})`}}></div>*/}
                                            {translate.getLocale() === 'ar' ?
                                                <p onClick={this.toggleSmokes}>{this.state.features4.arName}</p> :
                                                <p onClick={this.toggleSmokes}>{this.state.features4.enName}</p>}
                                            {/*<Translate content="smokes" component="h1"/>*/}
                                            {/*<Translate content="smokes" component="p" onClick={this.toggleSmokes}/>*/}
                                            {this.state.activeSmoke && <div
                                                className={"deliveryTypeSec" + (this.state.activeSmoke ? " animated" : '')}>
                                                <button type="button" className="typeButton" onClick={this.toggle}>
                                                    <Translate
                                                        content="from_home"/></button>
                                                <button type="button" className="typeButton"
                                                        onClick={this.countryTypeModel}>
                                                    <Translate content="from_branch"/></button>


                                            </div>}
                                        </div>

                                    </div>

                                </div>
                                :""}


                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 nonePadding">

                                    {this.state.features5 !== {} ?
                                        <div className="cardContainer">

                                            <div className="Desserts backGroundCard"
                                                 style={{backgroundImage: `url(${this.state.features5.featuredImage})`}}>
                                                <div className="cardInfo">
                                                    {/*<div className="backImgIcon" style={{backgroundImage: `url(${this.state.features5.image})`}}></div>*/}
                                                    {translate.getLocale() === 'ar' ?
                                                        <p onClick={this.toggleDessert}>{this.state.features5.arName}</p> :
                                                        <p onClick={this.toggleDessert}>{this.state.features5.enName}</p>}

                                                    {/*<Translate content="desserts" component="h1"/>*/}
                                                    {/*<Translate content="desserts" component="p" onClick={this.toggleDessert}/>*/}
                                                    {this.state.activeDessert && <div
                                                        className={"deliveryTypeSec" + (this.state.activeDessert ? " animated" : '')}>
                                                        <button type="button" className="typeButton"
                                                                onClick={this.toggle}>
                                                            <Translate
                                                                content="from_home"/></button>
                                                        <button type="button" className="typeButton"
                                                                onClick={this.countryTypeModel}>
                                                            <Translate content="from_branch"/></button>


                                                    </div>}
                                                </div>
                                            </div>

                                        </div>
                                        :""}


                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 nonePadding">

                                    {this.state.features6 !== {} ?
                                        <div className="cardContainer">

                                            <div className="Appetizers backGroundCard"
                                                 style={{backgroundImage: `url(${this.state.features6.featuredImage})`}}>
                                                <div className="cardInfo">
                                                    {/*<div className="backImgIcon" style={{backgroundImage: `url(${this.state.features6.image})`}}></div>*/}
                                                    {translate.getLocale() === 'ar' ?
                                                        <p onClick={this.toggleAppetizers}>{this.state.features6.arName}</p> :
                                                        <p onClick={this.toggleAppetizers}>{this.state.features6.enName}</p>}

                                                    {/*<Translate content="salad" component="h1"/>*/}
                                                    {/*<Translate content="salad" component="p"*/}
                                                    {/*onClick={this.toggleAppetizers}/>*/}
                                                    {this.state.activeAppetizers && <div
                                                        className={"deliveryTypeSec" + (this.state.activeAppetizers ? " animated" : '')}>
                                                        <button type="button" className="typeButton"
                                                                onClick={this.toggle}>
                                                            <Translate
                                                                content="from_home"/></button>
                                                        <button type="button" className="typeButton"
                                                                onClick={this.countryTypeModel}>
                                                            <Translate content="from_branch"/></button>


                                                    </div>}
                                                </div>
                                            </div>

                                        </div>
                                        :""}


                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div className="loadingSec" style={{marginTop: "200px", marginBottom: "200px"}}>
                            <BallBeat
                                color={'white'}
                                loading={this.state.Featureloading}
                            />
                        </div>}
                </div>


                <Modal isOpen={this.state.modal} toggle={this.toggle} className="locationModel">
                    <ModalHeader toggle={this.toggle}><Translate content="choose_location"/></ModalHeader>
                    <ModalBody>
                        <div className="container">
                            <div className="loadingSec">

                                <BallBeat
                                    color={'white'}
                                    loading={this.state.loading}
                                />
                            </div>
                            <div id="addressSec">
                                <div className="row addressSec">
                                    {oldAddress}
                                </div>
                                <button className='orangeButton' id="newAddress" onClick={this.showMap}><Translate
                                    content="new_address"/></button>
                            </div>
                            {/*<div className="addressMap" id="addressMap" style={{display: 'none'}}>*/}

                            {/*<LocationPickerMap/>*/}
                            {/*</div>*/}
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.deliveryModal} toggle={this.deliveryTypeModel} className="typeModel">
                    <ModalHeader toggle={this.deliveryTypeModel}><Translate content="choose_type"/></ModalHeader>
                    <ModalBody>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <button type="button" className="typeButton" onClick={this.countryTypeModel}>
                                        <Translate content="from_branch"/></button>
                                </div>
                                <div className="col-lg-12">
                                    <button type="button" className="typeButton" onClick={this.toggle}><Translate
                                        content="from_home"/></button>

                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>


                <Modal isOpen={this.state.countryModal} toggle={this.countryTypeModel} className="countryModal">
                    <ModalHeader toggle={this.countryTypeModel}><Translate content="choose_country"/></ModalHeader>
                    <ModalBody>
                        <div className="container">
                            <div className="loadingSec">

                                <BallBeat
                                    color={'white'}
                                    loading={this.state.loading}
                                />
                            </div>
                            {countries}
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.cityModal} toggle={this.cityTypeModel} className="countryModal">
                    <ModalHeader toggle={this.cityTypeModel}><Translate content="choose_city"/></ModalHeader>
                    <ModalBody>
                        <form onSubmit={this.handelCitySubmit}>

                            <div className="container">
                                <div className="loadingSec">

                                    <BallBeat
                                        color={'white'}
                                        loading={this.state.loading}
                                    />
                                </div>
                                {cities}
                                <button type="submit" className="typeButton" disabled={!this.state.disabled_button}>
                                    <Translate
                                        content="confirm"/></button>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.clearBasketModel} toggle={this.clearTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="change_delivery" component="p"/>
                            <button type="button" className="confirmDelete" onClick={this.clearBasket}><Translate
                                content="confirm"/></button>
                            <button type="button" className="cancelDelete" onClick={this.clearTypeModel}><Translate
                                content="cancel"/></button>
                        </div>
                    </ModalBody>
                </Modal>

            </Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        lat: state.lat,
        lng: state.lng,
    }
};
const mapDispatchToProps = (dispatch) => {

    return {
        RESETAPP: () => {
            dispatch(RESETAPP())
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Home)
