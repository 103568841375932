import React, {Component} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {BallBeat} from "react-pure-loaders";
import {Link} from "react-router-dom";

let translate = require('counterpart');

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            done: [],
            inProgress: [],
            waitingPayment: [],
            status: '',

            defaultEnAddress: 'Branch Pickup',
            defaultArAddress: 'تستلم من الفرع',
            loading: true
        };
        axios.get(window.$urlName + 'orders', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token,
                'Accept': 'application/json'
            }
        }).then((response) => {
                this.setState({
                    done: response.data.data.done,
                    inProgress: response.data.data.inProgress,
                    waitingPayment: response.data.data.waitingPayment,
                    loading: false
                });
        document.getElementById("ordersBody").style.display="block";

        }).catch(function (error) {
            // console.error(error);
        });
    }


    render() {


        let doneOrders = this.state.done.length ? (
            this.state.done.map((item) => {
            return (
                <div className="col-lg-6 col-md-6" key={item.id}>
                    <Link to={{pathname: `/order/${item.id}`}}>
                        <div className="orderDiv">
                            <div className="orderHead">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        {translate.getLocale() === 'ar' ? <p>{item.branchArName}</p> :
                                            <p>{item.branchEnName}</p>}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="arialFont">{item.createdAt}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="orderBody">
                                {translate.getLocale() === 'ar' ?
                                    <h3>{parseInt(item.typeId) === 2 ? item.addressArAddress.substring(0,70) : this.state.defaultArAddress.substring(0,70)}</h3> :
                                    <h3>{parseInt(item.typeId) === 2 ? item.addressEnAddress.substring(0,70) : this.state.defaultEnAddress.substring(0,70)}</h3>}
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <h2><Translate content="order_id"/></h2>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                         <p>{item.id}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <h2><Translate content="order_state"/></h2>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        {parseInt(item.typeId) === 2 ?  <p>{item.statusId === 1 ? "Accepted" : item.statusId === 2 ? "kitchen" : item.statusId === 3 ? "Delivery" : "Done"}</p> :  <p>{item.statusId === 1 ? "Accepted" : item.statusId === 2 ? "kitchen" : item.statusId === 3 ? "kitchen" : "Done"}</p>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Link>
                </div>
            )
        })
            ) :

            (
                <Translate content="no_orders" component="p" className="no_item"/>

            );




        let progressOrders = this.state.inProgress.length ? (
            this.state.inProgress.map((item) => {
            return (
                <div className="col-lg-6 col-md-6" key={item.id}>
                    <Link to={{pathname: `/order/${item.id}`}}>
                        <div className="orderDiv">
                            <div className="orderHead">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        {translate.getLocale() === 'ar' ? <p>{item.branchArName}</p> :
                                            <p>{item.branchEnName}</p>}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="arialFont">{item.createdAt}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="orderBody">
                                {translate.getLocale() === 'ar' ?
                                    <h3>{parseInt(item.typeId) === 2 ? item.addressArAddress.substring(0,70) : this.state.defaultArAddress.substring(0,70)}</h3> :
                                    <h3>{parseInt(item.typeId) === 2 ? item.addressEnAddress.substring(0,70) : this.state.defaultEnAddress.substring(0,70)}</h3>}
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <h2><Translate content="order_id"/></h2>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p>{item.id}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <h2><Translate content="order_state"/></h2>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        {parseInt(item.typeId) === 2 ?  <p>{item.statusId === 1 ? "Accepted" : item.statusId === 2 ? "kitchen" : item.statusId === 3 ? "Delivery" : "Done"}</p> :  <p>{item.statusId === 1 ? "Accepted" : item.statusId === 2 ? "kitchen" : item.statusId === 3 ? "kitchen" : "Done"}</p>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Link>
                </div>
            )
        })
            ) :

            (
                <Translate content="no_orders" component="p" className="no_item"/>

            );


        let waitingOrder = this.state.waitingPayment.length ? (

            this.state.waitingPayment.map((item) => {
            return (
                <div className="col-lg-6 col-md-6" key={item.id}>
                    <Link to={{pathname: `/order/${item.id}`}}>
                        <div className="orderDiv">
                            <div className="orderHead">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        {translate.getLocale() === 'ar' ? <p>{item.branchArName}</p> :
                                            <p>{item.branchEnName}</p>}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p className="arialFont">{item.createdAt}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="orderBody">
                                {translate.getLocale() === 'ar' ?
                                    <h3>{parseInt(item.typeId) === 2 ? item.addressArAddress.substring(0,70) : this.state.defaultArAddress.substring(0,70)}</h3> :
                                    <h3>{parseInt(item.typeId) === 2 ? item.addressEnAddress.substring(0,70) : this.state.defaultEnAddress.substring(0,70)}</h3>}
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <h2><Translate content="order_id"/></h2>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <p>{item.id}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <h2><Translate content="order_state"/></h2>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        {parseInt(item.typeId) === 2 ?  <p>{item.statusId === 1 ? "Accepted" : item.statusId === 2 ? "kitchen" : item.statusId === 3 ? "Delivery" : item.statusId === 4 ? "Done" : "Waiting"}</p> :  <p>{item.statusId === 1 ? "Accepted" : item.statusId === 2 ? "kitchen" : item.statusId === 3 ? "kitchen" : item.statusId === 4 ? "Done" : "Waiting"}</p>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Link>
                </div>
            )
        })
            ) :

            (
                <Translate content="no_orders" component="p" className="no_item"/>

            );


        return (
            <div className="panelBody">
                <h1><Translate content="my_orders"/></h1>
                <div className="loadingSec">
                    <BallBeat
                        color={'#2C2C2C'}
                        loading={this.state.loading}
                    />
                </div>

                <div id="ordersBody" style={{display: "none"}}>
                    <Translate content="wait_orders" component="h2" className="orderTitle"/>
                    <div className="row">
                        {waitingOrder}
                    </div>
                    <hr/>

                    <Translate content="next_orders" component="h2" className="orderTitle"/>
                    <div className="row">
                        {progressOrders}
                    </div>
                    <hr/>
                    <Translate content="pre_orders" component="h2" className="orderTitle"/>
                    <div className="row">
                        {doneOrders}
                    </div>

                </div>

            </div>
        )
    }

}

export default Orders