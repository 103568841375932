import React from "react";
import {geolocated} from "react-geolocated";
import {setLocation} from "./actions/cartActions";
import connect from "react-redux/es/connect/connect";

class GeoLocated extends React.Component {

    render() {
        if(this.props.coords){
            this.props.setLocation(this.props.coords.latitude,this.props.coords.longitude)

        }

        return !this.props.isGeolocationAvailable ? (
            <div></div>
        ) : !this.props.isGeolocationEnabled ? (
            <div></div>
        ) : this.props.coords ? (
            <div></div>
            )

            : (
            <div></div>
            );
    }
}
const mapDispatchToProps = (dispatch) => {

    return {
        setLocation: (lat,lng) => {
            dispatch(setLocation(lat,lng))
        },
    }
};
export default connect(null, mapDispatchToProps) (geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    watchPosition: true,
    userDecisionTimeout: null,
})(GeoLocated));
