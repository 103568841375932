import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import {Link} from "react-router-dom";
import axios from "axios";
import {Redirect} from 'react-router-dom';
import {BallBeat} from "react-pure-loaders";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Modal, ModalBody} from "reactstrap";
import {authounticateUser} from "./actions/cartActions";
import connect from "react-redux/es/connect/connect";
let translate = require('counterpart');

class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        let loggedIn = false;
        this.state = {
            token: localStorage.getItem("token"),
            loading: false,
            phone: '',
            code: '',
            msgAr:"",
            msgEn:"",
            errorMsgModel: false,
            loggedIn
        };

    }
    errorTypeModel = () => {
        this.setState(prevState => ({
            errorMsgModel: !prevState.errorMsgModel,

        }));

    };
    handelChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };


    handelCode = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
        });


        let params = {
            type: "OTP",
        };
        let headers = {
            'Content-Type': 'application/json',
        };
        let data = {
            phone: this.state.phone,
            OTP: this.state.code
        };

        axios.post(window.$urlName + 'customers/get-token', data, {
            params,
            headers
        }).then((response) => {
            if (response.data.message === "success") {
                localStorage.setItem("token", response.data.data.access_token)
                axios.get(window.$urlName + 'customers/account-info', {
                    headers: {
                        'Authorization': 'Bearer ' + response.data.data.access_token,
                        'Accept': 'application/json'
                    }
                }).then((response) => {
                    localStorage.setItem("userName", response.data.data.name);
                    this.props.authounticateUser();
                    this.setState({
                        loggedIn: true,
                        loading: false
                    })
                })
            } else {
                this.setState({
                    loading: false,
                    msgAr:"الكود غير صحيح",
                    msgEn:"Invalid code",
                    errorMsgModel:true
                });

            }

        }).catch(function (error) {
            this.setState({
                loading: false,
                msgAr:"الكود غير صحيح",
                msgEn:"Invalid code",
                errorMsgModel:true
            });

        }.bind(this))
    };


    resend = () => {
        let params = {
            phone: this.state.phone,
        };
        let headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        axios.get(window.$urlName + 'customers/OTP-SMS-send', {params, headers}).then((response) => {
            this.setState({
                msgAr:"تم إرسال الكود",
                msgEn:"Your code has been send",
                errorMsgModel:true
            });

        }).catch(function (error) {
            // console.error(error);
        });
    };

    render() {
        if (this.state.loggedIn) {
            return <Redirect to={{
                pathname: '/change-password',
                state: {token: this.state.token}
            }}
            />
        }
        let phoneRegEx = /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;

        return (
            <Fragment>
                <div className="container">
                    <div className="signForm">
                        <Formik
                            initialValues={{
                                phone: '',
                            }}
                            validationSchema={Yup.object().shape({
                                phone: Yup.string()
                                    .matches(phoneRegEx, <Translate content="phone_not_valid"/>)
                                    .required(<Translate content="phone_required"/>),

                            })}
                            onSubmit={values => {
                                this.setState({
                                    loading: true,
                                    phone: values.phone,

                                });
                                let params = {
                                    phone: values.phone,
                                };
                                let headers = {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json'
                                };



                                axios.get(window.$urlName + 'customers/check-phone', {params, headers}).then((response) => {
                                    if (response.data.data.check === true) {
                                        document.getElementById("confirmCode").style.display = "block";
                                        document.getElementById("phoneNum").style.display = "none";
                                    } else {
                                        this.setState({
                                            msgAr:"رقم الهاتف غير صحيح",
                                            msgEn:"Wrong phone number",
                                            errorMsgModel:true
                                        });
                                    }
                                    this.setState({
                                        loading: false,
                                    })

                                }).catch(function (error) {
                                    // console.error(error);
                                });


                                axios.get(window.$urlName + 'customers/OTP-SMS-send', {params, headers}).then((response) => {
                                    // console.log(response.data);
                                }).catch(function (error) {
                                    // console.error(error);
                                });



                            }}

                            render={({errors, status, touched}) => (
                                <Form>
                                    <div id="phoneNum">
                                            <Translate content="signIn_register" component="h1"/>
                                            <div className="loadingSec">

                                                <BallBeat
                                                    color={'#2C2C2C'}
                                                    loading={this.state.loading}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="phone"><Translate content="enter_phone"/></label>
                                                <Field name="phone" id="phone" type="tel"
                                                       className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')} />
                                                <ErrorMessage name="phone" component="div" className="invalid-feedback"/>
                                            </div>

                                            {/*<div className="form-group">*/}
                                                {/*<label htmlFor="phone"><Translate content="enter_phone"/></label>*/}
                                                {/*<Translate component="input" type="text" id="phone" name="phone"*/}
                                                           {/*className="form-control" value={this.state.phone}*/}
                                                           {/*onChange={this.handelChange} attributes={{placeholder: 'phone'}}*/}
                                                           {/*required/>*/}

                                            {/*</div>*/}
                                            <button type="submit">
                                                <Translate content="confirm"/></button>
                                    </div>




                                </Form>
                            )}
                        />














                        <div id="confirmCode" style={{display: "none"}}>
                            <form onSubmit={this.handelCode}>
                                <div className="form-group">
                                    <label htmlFor="code"><Translate content="enter_code"/></label>
                                    <div className="loadingSec">
                                        <BallBeat
                                            color={'#2C2C2C'}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                    <Translate component="input" type="text" id="code" name="code"
                                               className="form-control" value={this.state.code}
                                               onChange={this.handelChange} attributes={{placeholder: 'code'}}
                                               required/>

                                </div>

                                <div className="resend"><Translate component="p" content="didnt_Arrive"/> <Translate
                                    content="resend" onClick={this.resend}/><span>/</span><Link
                                    to="/sign-up"> <Translate content="signUp"/></Link>

                                </div>
                                <button type="submit">
                                    <Translate content="confirm"/></button>
                            </form>
                        </div>


                    </div>

                </div>
                <Modal isOpen={this.state.errorMsgModel} toggle={this.errorTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="sorry" component="p"/>

                            {translate.getLocale() === 'ar' ? <p> {this.state.msgAr}</p> :
                                <p>{this.state.msgEn}</p>}
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authounticateUser: () => {
            dispatch(authounticateUser())
        },


    }
};
export default connect(null, mapDispatchToProps)(ForgetPassword)
