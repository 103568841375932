import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {Link} from "react-router-dom";
import Sticky from "react-sticky-el";
import {BallBeat} from "react-pure-loaders";

class MudiafShare extends Component {
    constructor(props) {
        super(props)
        let loggedIn = true;

        this.state = {
            token: localStorage.getItem("token"),
            loading:false,
            selectedFile: null,
            inquireSuccessMsg: localStorage.getItem("inquireSuccessMsg"),
            loggedIn
        }
    }

    componentDidMount() {
        if(localStorage.getItem("inquireSuccessMsg") === "true"){
            localStorage.setItem("inquireSuccessMsg", "false");
        }
    }

    uploadSubmit = (formData) => {
        this.setState({
            loading: true,
        })
        axios.post(window.$urlName + 'mdiaf/images', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.state.token,
            }
        }).then((response) => {
            localStorage.setItem("inquireSuccessMsg", "true");
            this.setState({
                redirect: true,
                loading: false,
            })
        }).catch(error => {
            if (error.response.status === 401) {
                this.setState({
                    loggedIn: false,
                })
            } else {
                this.getError(error.response.data.error)
            }
        });
    };

    uploadHandler = (e) => {
        e.preventDefault();
        if (this.state.token == null) {
            this.setState({
                loggedIn: false
            })
        }else {
            const formData = new FormData()
            formData.append(
                'image',
                this.state.selectedFile,
                this.state.selectedFile.name
            )
            this.uploadSubmit(formData);
        }
    };

    fileChangedHandler = event => {
      this.setState({ selectedFile: event.target.files[0] })
    }

    render() {
        if (this.state.loggedIn === false) {
            const location =
                {pathname: '/sign-in', state: {from: 'mudiafShare'}};
            this.props.history.push(location);
        }

        const {redirect} = this.state;

        if (redirect) {
            window.location.pathname = "/medhyaf-share"
        }

        return (
            <Fragment>
                <Sticky stickyClassName="stickyFilter">
                    <div className="mudiaf-bar">
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf`}}>
                                <Translate content="mudiaf_menus"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-services`}}>
                                <Translate content="mudiaf_services"/>
                            </Link>
                        </div>                        
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-inquires`}}>
                                <Translate content="mudiaf_inquires"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items mudiaf-items-active">
                            <Link to={{pathname: `/medhyaf-images`}}>
                                <Translate content="mudiaf_images"/>
                            </Link>
                        </div>
                    </div>
                </Sticky>

                <div className="container-fluid branchesSec">
                    <div className="loadingSec">
                        <BallBeat
                            color={'#2C2C2C'}
                            loading={this.state.loading}
                        />
                    </div>
                    <div className="container" style={{margin:"30px auto"}}>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Translate component="p" className="mudiaf-inquireP" content="sharePhotosaragraph"/>
                            </div>
                        </div>

                        {this.state.inquireSuccessMsg === "true" ? 
                            <div className="row">
                                <div className="mudiaf-inquire-success">
                                    <Translate component="p" content="imageSuccess"/>
                                </div>
                            </div>
                        : ""}

                        <div className="row">
                            <form onSubmit={this.uploadHandler} style={{width:"40%",minWidth:"320px",margin:"auto"}}>
                                <div className="mudiafCheckoutWhite" style={{padding:"30px",borderRadius:"10px"}}>

                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <Translate component="label" content="selectPhoto"/>
                                            <input type="file" style={{padding:"5px"}} onChange={this.fileChangedHandler}/>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="paymentButton" style={{margin:"15px auto auto"}}>
                                                <button type="submit"><Translate content="upload"/></button>
                                            </div>
                                        </div>
                                    </div>
                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default MudiafShare