import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import {Link} from 'react-router-dom';
import axios from 'axios';
let translate = require('counterpart');

class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            arDesc: "",
            enDesc: "",
        };
    }

    componentDidMount() {
        axios.get(window.$urlName + 'infos/terms').then((response) => {
            if (response.data.data !== null) {
             this.setState({
                 arDesc: response.data.data.arDescription,
                 enDesc: response.data.data.enDescription
             })
            }
        });
    }
    render(){
        return(
            <Fragment>
                <div className="container">
                    <div className="termsBody">
                        <Translate content="terms" component="h1"/>
                        <hr/>
                        {translate.getLocale() === 'ar' ?  <div dangerouslySetInnerHTML={{ __html: this.state.arDesc }} />
                                 :
                                <div dangerouslySetInnerHTML={{ __html: this.state.enDesc }} />}
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Terms
