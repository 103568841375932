import React, { Component } from 'react';
import { Modal, } from 'reactstrap'

export default function (ComposedComponent) {
    class NetworkDetector extends Component {
        state = {
            isDisconnected: false,
            loading:false
        }

        componentDidMount() {
            this.handleConnectionChange();
            window.addEventListener('online', this.handleConnectionChange);
            window.addEventListener('offline', this.handleConnectionChange);
        }

        componentWillUnmount() {
            window.removeEventListener('online', this.handleConnectionChange);
            window.removeEventListener('offline', this.handleConnectionChange);
        }


        handleConnectionChange = () => {
            const condition = navigator.onLine ? 'online' : 'offline';
            if (condition === 'online') {
                const webPing = setInterval(
                    () => {
                        fetch('//google.com', {
                            mode: 'no-cors',
                        })
                            .then(() => {
                                this.setState({ isDisconnected: false, loading:false }, () => {
                                    return clearInterval(webPing)
                                });
                            }).catch(() => this.setState({ isDisconnected: true, loading:true }) )
                    }, 2000);
                return;
            }

            return this.setState({ isDisconnected: true, loading:true });
        }

        render() {
            return (
                <div>
                    <ComposedComponent {...this.props} />
                    <Modal isOpen={this.state.loading} className='loading noInternetModel'>
                        <div className='internet'>
                            <i className="fa fa-spinner fa-spin" />
                            <h2>Internet connection is lost</h2>
                        </div>
                    </Modal>
                </div>
            );
        }
    }

    return NetworkDetector;
}
